import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import store from '@/store'
import { removetoken } from '@/utils/auth'
// import Cookies from 'js-cookie'
Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/saas/',
    component: Home,
    meta: { title: '首页' },
    props: { storeID: localStorage.getItem('storeID') }
  },
  {
    path: '/saas/searchGoods',
    component: () => import('../views/searchGoods'),
    meta: { title: '搜索' }
  },
  {
    path: '/saas/goods',
    component: () => import('../views/allProducts'),
    meta: { title: '全部商品' }
  },
  {
    path: '/saas/activityProducts',
    component: () => import('../views/allProducts/activityProducts'),
    meta: { title: '活动商品' }
  },
  {
    path: '/saas/searchFor',
    component: () => import('../views/searchFor'),
    meta: { title: '搜索' }
  },
  {
    path: '/saas/productDetails',
    component: () => import('../views/productDetails'),
    meta: { title: '商品详情' }
  },
  {
    path: '/saas/login',
    component: () => import('../views/login'),
    meta: { title: '登录' }
  },
  {
    path: '/saas/register',
    component: () => import('../views/login/register'),
    meta: { title: '注册' }
  },
  {
    path: '/saas/forgotPwd',
    component: () => import('../views/login/forgotPwd'),
    meta: { title: '忘记密码' }
  },
  {
    path: '/saas/shoppingCart',
    component: () => import('../views/shoppingCart/index'),
    meta: { title: '购物车' }
  },
  {
    path: '/saas/placeOrder',
    component: () => import('../views/placeOrder/index'),
    meta: { title: '提交订单' }
  },
  {
    path: '/saas/usermy/addressAdministration',
    component: () => import('../views/selectAddress/index'),
    meta: { title: '地址选择/展示' }
  },
  {
    path: '/saas/addLocation',
    component: () => import('../views/selectAddress/addLocation'),
    meta: { title: '地址新增/编辑' }
  },
  {
    path: '/saas/onlinePayment',
    component: () => import('../views/onlinePayment/index'),
    meta: { title: '支付选择' }
  },
  {
    path: '/saas/offlinePay',
    component: () => import('../views/onlinePayment/offlinePay'),
    meta: { title: '线下支付' }
  },
  {
    path: '/saas/paryMent',
    component: () => import('../views/onlinePayment/paryment'),
    meta: { title: '支付中转页' }
  },
  {
    path: '/saas/bindPhoneNumber',
    component: () => import('../views/login/bindPhoneNumber'),
    meta: { title: '绑定手机号' }

  },
  {
    path: '/saas/pointsMall',
    component: () => import('../views/pointsMall/pointsMall'),
    meta: { title: '积分商城' }
  },
  {
    path: '/saas/integralRule',
    component: () => import('../views/pointsMall/integralRule'),
    meta: { title: '积分规则' }
  },
  {
    path: '/saas/integralRecord',
    component: () => import('../views/pointsMall/integralRecord'),
    meta: { title: '积分明细' }
  },
  {
    path: '/saas/integralDetails',
    component: () => import('../views/pointsMall/details'),
    meta: { title: '积分商品详情' }
  },
  {
    path: '/saas/usermy/application',
    component: () => import('../views/distributionCenter/application'),
    meta: { title: '分销' }
  },
  {
    path: '/saas/usermy/distribution',
    component: () => import('../views/distributionCenter/distribution'),
    meta: { title: '分销中心' }
  },
  {
    path: '/saas/usermy/withdrawal',
    component: () => import('../views/distributionCenter/withdrawal'),
    meta: { title: '提现' }
  },
  {
    path: '/saas/usermy/commissionRecords',
    component: () => import('../views/distributionCenter/commissionRecords'),
    meta: { title: '佣金明细' }
  },
  {
    path: '/saas/usermy/commissionDetails',
    component: () => import('../views/distributionCenter/commissionDetails'),
    meta: { title: '佣金明细' }
  },
  {
    path: '/saas/usermy/withdrawalRecords',
    component: () => import('../views/distributionCenter/withdrawalRecords'),
    meta: { title: '佣金提现明细' }
  },
  {
    path: '/saas/usermy/withdrawalDetails',
    component: () => import('../views/distributionCenter/withdrawalDetails'),
    meta: { title: '佣金提现详情' }
  },
  {
    path: '/saas/usermy/mySubordinates',
    component: () => import('../views/distributionCenter/mySubordinates'),
    meta: { title: '我的下级' }
  },
  {
    path: '/saas/usermy/secondLevelTeam',
    component: () => import('../views/distributionCenter/secondLevelTeam'),
    meta: { title: '二级团队' }
  },
  {
    path: '/saas/usermy/distributionOrders',
    component: () => import('../views/distributionCenter/distributionOrders'),
    meta: { title: '分销订单' }
  },
  {
    path: '/saas/usermy/promoteStore',
    component: () => import('../views/distributionCenter/promoteStore'),
    meta: { title: '推广店铺' }
  },
  {
    path: '/saas/usermy/promoteProducts',
    component: () => import('../views/distributionCenter/promoteProducts'),
    meta: { title: '推广商品' }
  },
  {
    path: '/saas/myRedemption',
    component: () => import('../views/pointsMall/myRedemption'),
    meta: { title: '我的兑换' }
  },

  {
    path: '/saas/usermy',
    component: () => import('../views/myUser'),
    meta: { title: '个人中心' },
    // redirect: '/usermy/personalUser',
    props: { storeID: localStorage.getItem('storeID') },
    children: [
      // {
      //   path:"personalUser",
      //   component:()=>import("../views/myUser/personalUser"),
      //   meta: { title: "个人信息" },
      // },
      // {
      //   path:"myOorder",
      //   component:()=>import("../views/myUser/myOorder"),
      //   meta: { title: "我的订单" },
      // },
      // {
      //   path:"afterSale",
      //   component:()=>import("../views/myUser/afterSale"),
      //   meta: { title: "售后服务" },
      // },
      // {
      //   path:"addressAdministration",
      //   component:()=>import("../views/myUser/addressAdministration"),
      //   meta: { title: "地址管理" },
      // },
      // {
      //   path:"leaveMessage",
      //   component:()=>import("../views/myUser/leaveMessage"),
      //   meta: { title: "留言管理" },
      // },
      // {
      //   path:"messageUser",
      //   component:()=>import("../views/myUser/messageUser"),
      //   meta: { title: "消息通知" },
      // }
    ]
  },
  {
    path: '/saas/usermy/afterSale',
    component: () => import('../views/myUser/afterSale'),
    meta: { title: '售后服务' }
  },
  {
    path: '/saas/usermy/messageUser',
    component: () => import('../views/messageUser/index'),
    meta: { title: '消息通知' }
  },
  {
    path: '/saas/personalUser',
    component: () => import('../views/myUser/personalUser'),
    meta: { title: '个人信息' }
  },
  {
    path: '/saas/amendPass',
    component: () => import('../views/myUser/amendPass'),
    meta: { title: '修改密码' }
  },
  {
    path: '/saas/amendEmil',
    component: () => import('../views/myUser/amendEmil'),
    meta: { title: '修改邮箱' }
  },
  {
    path: '/saas/usermy/leaveMessage',
    component: () => import('../views/leaveMessage/index'),
    meta: { title: '留言管理' }
  },
  {
    path: '/saas/AfterParticulars',
    component: () => import('../views/myUser/AfterParticulars'),
    meta: { title: '售后详情' }
  },
  {
    path: '/saas/ExternalWord',
    component: () => import('@/views/ExternalWord'),
    meta: { title: '更多' }
  },
  // {
  //   path:"/search",
  //   component:()=>import("../views/searchList"),
  //   meta:{title:"搜索"}
  // },
  {
    path: '/saas/AfterApplication',
    component: () => import('../views/myUser/AfterApplication'),
    meta: { title: '售后申请' }
  },
  {
    path: '/saas/submitAfterapp',
    component: () => import('../views/myUser/submitAfterapp'),
    meta: { title: '售后提交成功' }
  },
  {
    path: '/saas/mesageBox',
    component: () => import('../views/messageUser/mesageBox'),
    meta: { title: '消息详情' }
  },
  {
    path: '/saas/lavex',
    component: () => import('../views/leaveMessage/lavex'),
    meta: { title: '留言详情' }
  },
  // {
  //   path:"/AboutUs",
  //   component:()=>import("../views/AboutUs"),
  //   meta:{title:"关于我们"}
  // },
  // {
  //   path:"/contactWay",
  //   component:()=>import("../views/contactWay"),
  //   meta:{title:"联系我们"}
  // },
  // {
  //   path:"/addLocation",
  //   component:()=>import("../views/myUser/addressAdministration/addLocation"),
  //   meta:{title:"添加地址"}
  // },
  // {
  //   path:"/selectAddress",
  //   component:()=>import("../views/selectAddress"),
  //   meta:{title:"选择地址"}
  // },
  // {
  //   path:"/Login",
  //   component:()=>import("../views/login"),
  //   meta: { title: "登录" },
  // },
  // {
  //   path:"/register",
  //   component:()=>import("../views/login/register"),
  //   meta:{title:"注册"}
  // },
  // {
  //   path:"/404",
  //   component:()=>import("../views/myUser404.vue"),
  //   meta:{title:"404"}
  // },
  // {
  //   path:"/modifIcation",
  //   component:()=>import("../views/modifIcation"),
  //   meta:{title:"修改密码"}
  // },
  // {
  //   path: '/saas/goods'  ,
  //   component: () => import("../views/Allgoods"),
  //   meta:{title:"全部商品"}
  // },
  {
    path: '/saas/leaveWangt',
    component: () => import('../views/leaveMessage/leaveWangt'),
    meta: { title: '我要留言' }
  },

  // {
  //   path: "/productDetails",
  //   component: () => import("../views/productDetails"),
  //   meta: { title: "产品详情" },

  // },
  // {
  //   path: "/ShoppingCart",
  //   component: () => import("../views/ShoppingCart"),
  //   meta: { title: "购物车" },
  // },
  // {
  //   path:'/placeOrder',
  //   component: () => import("../views/placeOrder"),
  //   meta: { title: "下单" },
  // },
  // {
  //   path:'/onlinePayment',
  //   component: () => import("../views/onlinePayment"),
  //   meta: { title: "在线支付或货到付款" },
  // },
  // {
  //   path:'/paryMent',
  //   component: () => import("../views/onlinePayment/paryment"),
  //   meta: { title: "二维码支付" },
  // },
  {
    path: '/saas/Successhtml',
    component: () => import('../views/onlinePayment/Successhtml'),
    meta: { title: '支付失败与成功' }
  },
  {
    path: '/saas/usermy/myOorder',
    component: () => import('../views/myOrder/index'),
    meta: { title: '我的订单' }
  },
  {
    path: '/saas/orderDetails',
    component: () => import('../views/myOrder/orderDetails'),
    meta: { title: '订单详情' }
  },
  {
    path: '/saas/activityDetails',
    component: () => import('../views/myOrder/activityDetails'),
    meta: { title: '拼团详情' }
  },
  {
    path: '/saas/bcel',
    component: () => import('../views/onlinePayment/bcel'),
    meta: { title: '是否成功支付' }
  },
  {
    path: '/saas/AgreementMassage',
    component: () => import('@/views/AgreementMassage'),
    meta: { title: '协议详情' }
  },
  {
    path: '/saas/usermy/wallet',
    component: () => import('@/views/wallet'),
    meta: { title: '我的钱包' }
  },
  {
    path: '/saas/usermy/myCoupon',
    component: () => import('@/views/myCoupon'),
    meta: { title: '我的优惠券' }
  },
  {
    path: '/saas/historicalCoupons',
    component: () => import('@/views/myCoupon/historicalCoupons'),
    meta: { title: '历史记录' }
  },
  {
    path: '/saas/withdrawal',
    component: () => import('@/views/wallet/withdrawal'),
    meta: { title: '提现' }
  },
  {
    path: '/saas/balanceRecord',
    component: () => import('@/views/wallet/balanceRecord'),
    meta: { title: '余额记录' }
  },
  {
    path: '/saas/withdrawalRecords',
    component: () => import('@/views/wallet/withdrawalRecords'),
    meta: { title: '提现记录' }
  },
  {
    path: '/saas/withdrawalDetails',
    component: () => import('@/views/wallet/withdrawalDetails'),
    meta: { title: '提现详情' }
  },
  {
    path: '/saas/collectionManagement',
    component: () => import('@/views/wallet/collectionManagement'),
    meta: { title: '收款管理' }
  },

  {
    path: '/saas/paymentPassword',
    component: () => import('@/views/wallet/paymentPassword'),
    meta: { title: '支付密码' }
  },
  {
    path: '/saas/changePayPaw',
    component: () => import('@/views/wallet/changePayPaw'),
    meta: { title: '修改支付密码' }
  },
  {
    path: '/saas/addBankCard',
    component: () => import('@/views/wallet/addBankCard'),
    meta: { title: '添加银行卡' }
  },
  {
    path: '/saas/addPaymentCode',
    component: () => import('@/views/wallet/addPaymentCode'),
    meta: { title: '添加收款码' }
  },
  {
    path: '/404',
    component: () => import('@/views/myUser404'),
    meta: { title: '404' }
  },{
    path: '/saas/bluetooth',
    component: () => import('@/views/bluetooth/index'),
    meta: { title: '404' }
  },
  {
    path: '/saas/bluetoothGoods',
    component: () => import('@/views/bluetooth/goods'),
    meta: { title: '售卖机商品' }
  },
  {
    path: '/saas/bluetoothDetails',
    component: () => import('@/views/bluetooth/details'),
    meta: { title: '商品详情' }
  }

]
let needLogin = [
  '/saas/orderDetails',
  '/saas/lavex',
  '/saas/mesageBox',
  '/saas/submitAfterapp',
  '/saas/AfterApplication',
  '/saas/AfterParticulars',
  '/saas/personalUser',
  '/saas/usermy/myOorder',
  '/saas/usermy/afterSale',
  '/saas/usermy/addressAdministration',
  '/saas/usermy/leaveMessage',
  '/saas/usermy/messageUser'
]

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes: constantRoutes,
  mode: 'history'
})

// 页面栈
const pageStack = []
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  store.commit('shoppingcar/SET_PREVIOUS_PATH', {path:from.path,query:from.query})
  console.log(window.history.length,'长度')
  // console.log('这里拦截了么', localStorage.getItem('storeID'))
  // 如果当前路径已经是404页面，则不再执行后续逻辑
  if (to.path === '/404') {
    return next()
  }

  if (!to.query.storeId&&!localStorage.getItem('userData')) {
    // 如果storeID不存在，直接跳转到404页面
    router.push('/404')
    return
  }
  if(to.query.storeSellId){
    localStorage.setItem('storeSell', JSON.stringify({ storeSellId: to.query.storeSellId, time: new Date().getTime(),storeId:to.query.storeId }));
    if(to.query.vmNumber){
      localStorage.setItem('vmNumber',to.query.vmNumber)
      router.push({
        path:'/saas/bluetoothGoods',
        query:{vmNumber:to.query.vmNumber,storeId:to.query.storeId,storeSellId2:to.query.storeSellId}
      })
    }
  }

  if (to.query.storeId) {
    if(to.query.storeId !=localStorage.getItem('storeID')){
      localStorage.removeItem('parentUserId')
    }
    // 如果storeID为空则设置一个默认值
    if (
      localStorage.getItem('userData') &&
      JSON.parse(localStorage.getItem('userData')).storeId != to.query.storeId
    ) {
      removetoken()
      localStorage.removeItem('userData')
      localStorage.removeItem('parentUserId')
      store.dispatch('user/removetoken')
      store.dispatch('order/Seaddress', null)
      store.commit('locshoppingcar/lookloacshoppingcarnum')
    }
    localStorage.setItem('storeID', to.query.storeId)
  } else {
    // 如果storeID为空，则设置一个默认值
    to.query.storeId = localStorage.getItem('storeID')
  }

  const routeChangeInfo = {
    from: {
      path: from.path,
      query: from.query,
      params: from.params
    },
    to: {
      path: to.path,
      query: to.query,
      params: to.params
    },
    timestamp: Date.now()
  }
  sessionStorage.setItem('lastRoute', JSON.stringify(routeChangeInfo))

  if (from.path === '/saas/login') {
    needLogin.forEach(item => {
      if (item === to.path) {
        next('/saas/')
      } else {
        next()
      }
    })
  } else {
    next()
  }


})
// 获取上一页
function getPrevPage() {
  return pageStack.pop();
}

export default router
