// 泰国
export default {
    BCEL支付: 'BCEL',
    KBZ支付: 'KBZpay',
    OnePay支付: 'OnePay',
    OnePay支付二维码: 'รหัส QR ของ OnePay',
    保存: 'บันทึก',
    保密: 'รักษาความลับ',
    备注说明: 'หมายเหตุ',
    编辑地址: 'แก้ไขที่อยู่',
    标记已读: 'ทำเครื่องหมายว่าอ่านแล้ว',
    不可修改最多: 'ไม่สามารถแก้ไขได้ มากที่สุด',
    部分订单取消: 'คำสั่งซื้อบางส่วนถูกยกเลิก',
    部分发货: 'จัดส่งบางส่วน',
    部分商品正在退款中: 'สินค้าบางรายการอยู่ระหว่างการดำเนินการคืนเงิน',
    部分收货: 'ใบเสร็จรับเงินบางส่วน',
    部分退款: 'คืนเงินบางส่วน',
    部分退款完成: 'การคืนเงินบางส่วนเสร็จสมบูรณ์',
    查看订单: 'ตรวจสอบรายการสั่งซื้อ',
    查看全部: 'ดูทั้งหมด',
    查看全部xx条回复: 'ดูการตอบกลับทั้งหมด{num}รายการ',
    查看详情: 'ตรวจสอบรายละเอียด',
    查看支付是否成功: 'ตรวจสอบว่าการชำระเงินสำเร็จหรือไม่>>>',
    查询中: 'สอบถามรายละเอียดเพิ่มเติม',
    城市: 'เมือง',
    创建时间: 'เวลาสร้าง',
    此宝贝已下架: 'สินค้านี้ถูกนำออกจากชั้นวางแล้ว',
    待发货: 'รอการจัดส่ง',
    待付款: 'รอชำระเงิน',
    待收货: 'รอรับสินค้า',
    当前安全手机号: 'หมายเลขโทรศัพท์มือถือที่มีความปลอดภัย',
    地址管理: 'จัดการที่อยู่',
    登录: 'เข้าสู่ระบบ',
    等待店铺审核: 'รอร้านค้าตรวจสอบ',
    点击选择城市: 'คลิกเพื่อเลือกเมือง',
    点击选择国家: 'คลิกเพื่อเลือกประเทศ',
    点击选择退货类型: 'คลิกเพื่อเลือกประเภทการคืนสินค้า',
    点击选择退货原因: 'คลิกเพื่อเลือกเหตุผลในการคืนสินค้า',
    店: 'S',
    x件: '{num} ชิ้น',
    店铺: 'ร้านค้า',
    店铺处理: 'ร้านค้ากำลังดำเนินการ',
    店铺拒绝您的申请: 'ร้านค้าได้ปฏิเสธคำขอของคุณ',
    店铺退款: 'ร้านค้ากำลังดำเนินการคืนเงิน',
    订单编号: 'หมายเลขการสั่งซื้อ',
    订单号: 'หมายเลขการสั่งซื้อ',
    订单详情: 'รายละเอียดคำสั่งซื้อ',
    订单已超时: 'หมดเวลาการสั่งซื้อ',
    短信验证码: 'รหัสยืนยันทาง SMS',
    发表留言: 'ฝากข้อความ',
    发货: 'จัดส่งสินค้า',
    发货件数: 'จำนวนสินค้าที่จัดส่ง',
    发货时间: 'เวลาจัดส่ง',
    发货状态: 'สถานะการจัดส่ง',
    发送成功: 'ส่งเรียบร้อยแล้ว',
    发送验证码: 'ส่งรหัสยืนยัน',
    法律声明: 'ประกาศทางกฎหมาย',
    返回: 'ย้อนกลับ',
    返回首页: 'กลับไปยังหน้าแรก',
    分: 'นาที',
    分类: 'การจัดหมวดหมู่',
    付款时间: 'เวลาชำระเงิน',
    复制: 'คัดลอก',
    复制成功: 'คัดลอกสำเร็จ',
    该订单已取消: 'คำสั่งซื้อนี้ถูกยกเลิก',
    个人信息: 'ข้อมูลส่วนบุคคล',
    个字以内: 'ภายใน x ตัวอักษร',
    恭喜您邮箱地址验证成功: 'ยินดีด้วย! การยืนยันที่อยู่อีเมลสำเร็จ',
    购买商品: 'ซื้อสินค้า',
    购买数量: 'จํานวนสั่งซื้อ',
    购买信息: 'ข้อมูลการสั่งซื้อ',
    购物车: 'ตะกร้าสินค้า',
    国家区域: 'ประเทศ / ภูมิภาค',
    含发货邮费: 'รวมค่าจัดส่งแล้ว',
    欢迎登录: 'ยินดีต้อนรับสู่ระบบ',
    活动优惠: 'ส่วนลดจากโปรโมชั่น',
    货币: 'สกุลเงิน',
    继续逛逛: 'ช้อปปิ้งต่อ',
    加入购物车: 'เพิ่มสินค้าในรถเข็น',
    加载中: 'กำลังโหลด',
    价格高低: 'ราคาสูงต่ำ',
    价格最低: 'ราคาต่ำสุด',
    价格最高: 'ราคาสูงสุด',
    件商品: 'สินค้าx รายการ',
    交易异常: 'การซื้อขายผิดปกติ',
    看不清换一张: 'ไม่สามารถมองเห็นได้อย่างชัดเจน เปลี่ยนรูปใหม่',
    客: 'C',
    客服: 'บริการลูกค้า',
    客服电话: 'เบอร์โทรศัพท์ฝ่ายบริการลูกค้า',
    客服邮箱: 'อีเมลฝ่ายบริการลูกค้า',
    客户: 'ลูกค้า',
    客户服务: 'บริการลูกค้า',
    客户取消售后: 'ลูกค้ายกเลิกบริการหลังการขาย',
    客户已付款等待店铺发货: 'ลูกค้าชำระเงินแล้ว รอร้านค้าจัดส่งสินค้า',
    历史搜索: 'ประวัติการค้นหา',
    立即登录: 'เข้าสู่ระบบทันที',
    立即购买: 'ซื้อทันที',
    立即留言: 'ฝากข้อความทันที',
    立即提交: 'ส่งทันที',
    立即注册: 'สมัครตอนนี้เลย',
    联系电话: 'เบอร์ติดต่อ',
    联系方式: 'ข้อมูลติดต่อ',
    联系客服: 'ติดต่อฝ่ายบริการลูกค้า',
    联系人: 'ผู้ติดต่อ',
    两次输入密码不一致: 'รหัสผ่านที่กรอกทั้งสองครั้งไม่ตรงกัน',
    留言成功: 'ฝากข้อความสำเร็จ',
    留言管理: 'การจัดการข้อความ',
    留言回复: 'ข้อความตอบกลับ',
    留言回复长度: 'ข้อความตอบกลับ, ความยาว 2-100 ตัวอักษร',
    留言回复长度xxx个字符: 'ข้อความตอบกลับ, ความยาว 2-100 ตัวอักษร',
    留言内容: 'เนื้อหาข้อความ',
    留言详情: 'รายละเอียดข้อตวาม',
    没有更多了: 'ไม่มีแล้ว',
    每张图片大小不超过3M: 'ขนาดของแต่ละภาพไม่ควรเกิน 3M',
    密码: 'รหัสผ่าน',
    密码登录: 'เข้าสู่ระบบด้วยรหัสผ่าน',
    密码最少8位最多16位: 'รหัสผ่านต้องมีอักขระอย่างน้อย 8 ตัวและไม่เกิน 16 ตัวอักษร',
    秒: 'วินาที',
    秒后重新发送: 'ส่งอีกครั้งใน {seconds} วินาที',
    名: 'ชื่อ',
    默认地址: 'ที่อยู่ตัวเลือกอัตโนมัติ',
    男: 'ชาย',
    昵称: 'ชื่อเล่น',
    昵称不能为空: 'ชื่อเล่นต้องไม่ว่างเปล่า',
    您当前是线下支付请耐心等待店铺确认收款若x后店铺未确认收款订单将自动取消: 'คุณกำลังชำระเงินแบบออฟไลน์ โปรดรออย่างอดทนเพื่อให้ร้านค้ายืนยันการรับ! หากร้านค้าไม่ยืนยันการรับหลังจาก <span style="color: red;">{num}</span> คำสั่งซื้อจะถูกยกเลิกโดยอัตโนมัติ!',
    您的购物车还没有商品哟: 'ไม่มีสินค้าในตะกร้าของคุณ~',
    您的收货地址为空点此添加收货地址: 'ที่อยู่รับสินค้าของคุณว่างเปล่า คลิกที่นี่เพื่อเพิ่มที่อยู่รับสินค้า',
    您可以参考如下线下付款方式: 'คุณสามารถใช้วิธีการชำระเงินแบบออฟไลน์ดังต่อไปนี้',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料: 'คุณสามารถฝากข้อความถึงลูกค้าได้ที่นี่ เพื่อปกป้องสิทธิและผลประโยชน์ของคุณ กรุณากรอกข้อมูลและอัปโหลดเอกสารให้ละเอียดที่สุด',
    您未支付请重新支付: 'คุณยังไม่ได้ชำระเงิน กรุณาชำระเงินอีกครั้ง',
    女: 'ผู้หญิง',
    评论成功: 'แสดงความคิดเห็นสำเร็จ',
    切换语言和货币: 'สลับภาษาและสกุลเงิน',
    请勾选删除商品: 'กรุณาเลือกรายการสินค้าที่จะลบ',
    请勾选商品: 'กรุณาเลือกสินค้า',
    请勾选信息: 'กรุณาเลือกข้อมูล',
    请上传图片: 'กรุณาอัพโหลดภาพ',
    请输入短信验证码: 'กรุณากรอกรหัสยืนยันที่ได้รับทาง SMS',
    请输入反馈内容: 'กรุณากรอกเนื้อหาคอมเม้นต์',
    请输入留言内容: 'กรุณากรอกข้อความ',
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名: '请输入留言内容，若您使用BCEL或KBZPay支付，请在此输入用来接收退款的银行卡号及户名',
    请输入密码: 'กรุณากรอกใส่รหัสผ่าน',
    请输入内容: 'กรุณากรอกรายละเอียด',
    请输入手机号: 'กรุณากรอกหมายเลขโทรศัพท์มือถือ',
    请输入搜索关键词: 'กรุณากรอกคำค้นหา',
    请输入下方图形验证码: 'กรุณากรอกรหัสยืนยันในรูปภาพด้านล่าง',
    请输入详细地址信息如道路门牌号小区楼层号等: 'กรุณากรอกรายละเอียดที่อยู่ เช่น ถนน บ้านเลขที่ ตำบล เลขที่ ชั้น เป็นต้น',
    请输入新密码: 'กรุณากรอกรหัสผ่านใหม่',
    请输入验证码: 'กรุณากรอกรหัสยืนยัน',
    请输入用户名: 'กรุณากรอกชื่อผู้ใช้',
    请输入邮箱: 'กรุณากรอกอีเมล',
    请输入邮箱手机号: 'กรุณากรอกอีเมล/เบอร์มือถือ',
    请输入原密码: 'กรุณากรอกรหัสผ่านเดิม',
    请输入正确的密码格式必须包含两种字符: 'กรุณากรอกรหัสผ่านที่ถูกต้อง จำเป็นต้องประกอบด้วยอักขระสองประเภท',
    请输入正确的邮箱: 'กรุณากรอกอีเมลที่ถูกต้อง',
    请填写联系电话: 'กรุณากรอกหมายเลขโทรศัพท์ ',
    请填写联系人: 'กรุณากรอกชื่อผู้ติดต่อ',
    请填写留言内容: 'กรุณากรอกข้อความ',
    请填写名: 'กรุณากรอกชื่อ',
    请填写收货地址: 'กรุณากรอกที่อยู่รับสินค้า',
    请填写完整必填项: 'กรุณากรอกข้อมูลให้ครบถ้วน',
    请填写详细地址: 'กรุณากรอกที่อยู่โดยละเอียด',
    请填写姓: 'กรุณากรอกนามสกุล',
    请填写邮政编码: 'กรุณากรอกรหัสไปรษณีย์',
    请选择: 'กรุณาเลือก',
    请选择城市: 'กรุณาเลือกเมือง',
    请选择付款方式: 'กรุณาเลือกวิธีการชำระเงิน',
    请选择规格: 'กรุณาเลือกข้อมูลรายละเอียดของสินค้า',
    请选择国家: 'กรุณาเลือกประเทศ',
    请选择删除项需已读才能删除: 'กรุณาเลือกลบรายการ จำเป็นต้องอ่านถึงจะลบได้',
    请再次输入密码: 'กรุณากรอกรหัสผ่านอีกครั้ง',
    请在x内完成支付否则订单会被自动取消: 'กรุณาชำระเงินภายใน <span style="color: red;">{num}</span> มิฉะนั้นคำสั่งซื้ออาจถูกยกเลิกโดยอัตโนมัติ',
    请在下次使用邮箱地址进行登录: 'โปรดใช้ที่อยู่อีเมลของคุณเพื่อเข้าสู่ระบบในครั้งถัดไป',
    取消: 'ยกเลิก',
    取消成功: 'ยกเลิกสำเร็จ',
    取消订单: 'การยกเลิกคำสั่งซื้อ',
    取消订单成功: "ยกเลิกรายการสั่งซื้อสำเร็จ",
    取消时间: 'เวลายกเลิก',
    取消售后: 'ยกเลิกบริการหลังการขาย',
    去付款: 'ไปชำระเงิน',
    去支付: 'ไปชำระเงิน',
    全部: 'ทั้งหมด',
    全部分类: 'ทุกหมวดหมู่',
    全部消息: 'ข้อความทั้งหมด',
    全选: 'เลือกทั้งหมด',
    确定: 'ยืนยัน',
    确定放弃支付吗: 'คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการชำระเงิน？',
    确定删除选中的商品吗: 'ยืนยันต้องการลบสินค้าที่เลือกไว้?',
    确定提交: 'ยืนยันส่ง',
    确定要清空历史记录吗: 'ยืนยันการล้างประวัติหรือไม่',
    确定要取消支付这笔订单吗: 'ยืนยันต้องการยกเลิกการชำระเงินสำหรับรายการสั่งซื้อนี้หรือไม่?',
    确定已输入接收退款的银行卡号和户名: 'ตรวจสอบให้แน่ใจว่าคุณได้ป้อนหมายเลขบัตรธนาคารและชื่อบัญชีเพื่อรับเงินคืน',
    确定支付: 'ยืนยันชำระเงิน',
    确认订单: 'ยืนยันรายการสั่งซื้อ',
    确认密码: 'ยืนยันรหัสผ่าน',
    确认收货: 'ยืนยันการรับสินค้า',
    确认收货成功: 'ยืนยันการรับสินค้าสำเร็จ',
    确认已付款: 'ยืนยันการชำระเงิน',
    如果您当前的手机号已无法使用请联系客服: 'หากหมายเลขโทรศัพท์มือถือปัจจุบันของคุณไม่สามารถใช้งานได้  กรุณาติดต่อฝ่ายบริการลูกค้า',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持jpg和png格式文件图片不大于3M: 'หากคุณชำระเงินตามวิธีการข้างต้น กรุณาอัพโหลดเอกสารการชำระที่เกี่ยวข้อง เช่น ภาพหน้าจอการโอนเงิน ฯลฯ รองรับรูปแบบ jpg, png และรูปภาพไม่เกิน 3M',
    扫码或者点击去支付: 'สแกนรหัสหรือคลิก "ชำระเงิน"',
    删除: 'ลบ',
    删除成功: 'ลบสำเร็จ',
    删除订单: 'ลบคำสั่งซื้อ',
    删除订单成功: 'ลบรายการสั่งซื้อสำเร็จ',
    删除失效商品: 'ลบรายการสินค้าที่หมดอายุ',
    商品: 'สินค้า',
    商品单价: 'ราคาต่อหน่วย',
    商品分类: 'หมวดหมู่สินค้า',
    商品合计: 'ยอดรวม',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询: 'ที่อยู่การส่งสินค้าคืนจะแจ้งให้ทราบผ่านSMSหลังจากคำขอได้รับตรวจสอบและอนุมัติ หรือสามารถตรวจสอบจากการบันทึกประวัติของคำขอ',
    商品详情: 'รายละเอียดสินค้า',
    商品总额: 'จำนวนสินค้าทั้งหมด',
    商品总价: 'ราคาสินค้าทั้งหมด',
    上传成功: 'อัปโหลดสำเร็จ',
    上传凭证: 'อัปโหลดหลักฐาน',
    上传头像图片大小不能超过2MB: 'อัปโหลดรูปภาพโปรไฟล์ขนาดไม่เกิน 2MB!',
    上传头像图片只能是JPG格式: 'การอัปโหลดรูปโปรไฟล์ต้องเป็นรูปแบบ JPG เท่านั้น',
    上传中: 'กำลังอัปโหลด...',
    设为默认: 'ตั้งค่าเป็นเริ่มต้น',
    设为默认收货地址: 'ตั้งค่าเป็นที่อยู่เริ่มต้นในการรับสินค้า',
    申请时间: 'เวลาส่งคำขอ',
    申请信息: 'ข้อมูลการยื่นคำขอ',
    剩余X件: 'เหลือ {num} ชิ้น',
    请再次输入新密码: 'กรุณากรอกรหัสผ่านใหม่อีกครั้ง',
    失效宝贝: 'ข้อมูลสินค้าหมดอายุ',
    时: 'เวลา',
    实付金额: 'จำนวนเงินที่ชำระจริง',
    实付款: 'จำนวนเงินที่ต้องชำระ ',
    是否删除地址: 'ต้องการลบที่อยู่หรือไม่',
    是否选择该地址: 'ต้องการเลือกที่อยู่นี้ใช่หรือไม่',
    收货地址: 'ที่อยู่สําหรับรับของ',
    收货人: 'ผู้รับสินค้า',
    手机号: 'หมายเลขโทรศัพท์',
    手机号码: 'หมายเลขโทรศัพท์มือถือ',
    手机验证: 'ยืนยันผ่านเบอร์โทรศัพท์',
    手机验证码: 'รับรหัสยืนยันผ่านโทรศัพท์มือถือ',
    首页: 'หน้าแรก',
    售后单号: 'หมายเลขคำสั่งซื้อบริการหลังการขาย',
    售后方式: 'รูปแบบบริการหลังการขาย',
    售后服务: 'บริการหลังการขาย',
    售后商品: 'สินค้าที่ได้รับบริการหลังการขาย',
    售后申请: 'ขอบริการหลังการขาย',
    售后申请成功: 'ขอบริการหลังการขายสำเร็จ',
    售后申请提交成功: 'ส่งคำขอบริการหลังการขายสำเร็จ',
    售后完成: 'เสร็จสิ้นการให้บริการหลังการขาย',
    售后详情: 'รายละเอียดบริการหลังการขาย',
    售后信息: 'ข้อมูลบริการหลังการขาย',
    售后专员可能与您电话沟通请保持手机畅通: 'เจ้าหน้าที่บริการหลังการขายจะติดต่อผ่านทางโทรศัพท์ กรุณาเปิดโทรศัพท์ไว้',
    搜索: 'ค้นหา',
    提交: 'ส่ง',
    提交订单: 'ส่งคำสั่งซื้อ',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通: 'หลังจากส่งคำสั่งการบริการแล้ว เจ้าหน้าที่บริการหลังการขายจะติดต่อผ่านทางโทรศัพท์ กรุณาเปิดโทรศัพท์ไว้',
    提交申请: 'ส่งคำขอ',
    提示: 'แจ้งเตือน',
    天: 'วัน',
    添加超时: 'เพิ่มรายการเกินเวลา',
    添加成功: 'เพิ่มรายการสำเร็จ',
    添加失败库存不足: 'เพิ่มสินค้าไม่สเร็จ สินค้าคงคลังไม่เพียงพอ',
    添加新地址: 'เพิ่มที่อยู่ใหม่',
    条回复: '*รายการตอบกลับ',
    通过BCEL银行完成支付后务必返回当前页面确认支付是否成功: 'หลังจากชำระเงินผ่านธนาคาร BCEL เรียบร้อยแล้ว โปรดกลับไปที่หน้าปัจจุบันเพื่อยืนยันว่าการชำระเงินสำเร็จหรือไม่',
    头像: 'รูปโปรไฟล์',
    图片上传成功: 'อัปโหลดรูปภาพสำเร็จ',
    图片上传失败请重试: 'อัปโหลดรูปภาพไม่สำเร็จ กรุณาลองอีกครั้ง',
    退出登录: 'ออกจากระบบ',
    退货处理成功后退款金额将原路返回到您的支持账户中: 'หลังจากคำขอคืนสินค้าถูกอนุมัติแล้วยอดเงินคืนจะถูกส่งคืนไปยังบัญชีเดิมของคุณ',
    退货金额: 'จำนวนเงินคืน',
    退货类型: 'ประเภทการคืนสินค้า',
    退货数量: 'จำนวนการส่งสินค้าคืน',
    退货原因: 'เหตุผลในการคืนสินค้า',
    退款成功: 'คืนเงินสำเร็จ',
    退款金额: 'จำนวนเงินคืน',
    退款失败: 'คืนเงินไม่สำเร็จ',
    退款说明: 'คำอธิบายการคืนเงิน',
    退款完成: 'คืนเงินสำเร็จ',
    退款原因: 'เหตุผลการคืนเงิน',
    退款中: 'กําลังดำเนินการคืนเงิน',
    完成: 'เสร็จสมบูรณ์',
    忘记密码: 'ลืมรหัสผ่าน',
    微信: 'วีแชท',
    为了帮助您更好的解决问题请上传图片: 'เพื่อช่วยคุณแก้ปัญหาได้ดียิ่งขึ้น กรุณาอัปโหลดรูปภาพ',
    为确认身份我们需验证您的安全手机: ' เพื่อยืนยันตัวตน เราจำเป็นต้องยืนยันผ่านโทรศัพท์ที่มีความปลอดภัย',
    未登录: 'ไม่ได้เข้าสู่ระบบ',
    未读消息: 'ข้อความที่ยังไม่ได้อ่าน',
    未发货: 'ยังไม่ได้จัดส่ง',
    未添加收货地址: 'ไม่มีการเพิ่มที่อยู่รับสินค้า',
    未选择地址或没有地址: 'ยังไม่มีการเลือกที่อยู่หรือไม่มีที่อยู่',
    温馨提示: 'คำแจ้งเตือน',
    文件过大: 'ไฟล์มีขนาดใหญ่เกินไป',
    我的: 'ของฉัน',
    我的订单: 'รายการสั่งซื้อของฉัน',
    我是新用户: 'ฉันเป็นผู้ใช้ใหม่',
    我要留言: 'ฉันต้องการฝากข้อความ',
    我要退货退款: 'ฉันต้องการคืนสินค้าและคืนเงิน',
    我要退款: 'ฉันต้องการคืนเงิน',
    物流单号: 'หมายเลขการขนส่ง：',
    下单结算: 'ชำระรายการสั่งซื้อ',
    下一步: 'ขั้นตอนต่อไป',
    线下支付: 'ชำระเงินแบบออฟไลน์',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货: 'การชำระเงินแบบออฟไลน์ไม่สนับสนุนบริการหลังการขายแบบออนไลน์ หากต้องการบริการหลังการขาย กรุณาติดต่อร้านค้าสำหรับการ หากชำระเงินแล้วกรุณารอให้ร้านค้ายืนยันการรับชำระเงินและจัดส่ง!',
    详细地址: 'ที่อยู่โดยละเอียด',
    消息通知: 'การแจ้งเตือนข้อความ',
    消息详情: 'รายละเอียดของข้อความ',
    销量高低: 'ยอดขายสูงต่ำ',
    销量最低: 'ยอดขายต่ำสุด',
    销量最高: 'ยอดขายสูงสุด',
    新密码: 'รหัสผ่านใหม่',
    新密码不能和旧密码相同: 'รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเก่า',
    新增地址: 'เพิ่มที่อยู่',
    性别: 'เพศ',
    姓: 'นามสกุล',
    修改: 'แก้ไข',
    修改成功: 'แก้ไขสำเร็จ',
    修改密码: 'แก้ไขรหัสผ่าน',
    修改邮箱: 'แก้ไขอีเมล',
    需勾选协议: 'จำเป็นต้องเลือกข้อตกลง',
    选择: 'เลือก',
    选择成功: 'เลือกเรียบร้อยแล้ว',
    选择新地址: 'เลือกที่อยู่ใหม่',
    选中地址: 'เลือกที่อยู่',
    验证: 'ตรวจสอบ',
    验证码: 'รหัสยืนยัน',
    验证码登录: 'เข้าสู่ระบบด้วยรหัสยืนยัน',
    已读消息: 'ข้อความที่อ่านแล้ว',
    已发货: 'จัดส่งแล้ว',
    已关闭: 'ปิดแล้ว',
    已取消: 'ยกเลิกแล้ว',
    已取消删除: 'ยกเลิกการลบ',
    已完成: 'เสร็จสมบูรณ์',
    已选择: 'เลือกแล้ว',
    已阅读并同意以下协议: 'อ่านและยอมรับข้อตกลงดังต่อไปนี้',
    隐私政策: 'นโยบายความเป็นส่วนตัว',
    应付总额: 'ยอดรวมที่ต้องชำระ',
    用户服务协议: 'ข้อตกลงการบริการผู้ใช้',
    用户服务协议及法律声明: 'ข้อตกลงการบริการผู้ใช้และคำชี้แจงทางกฎหมาย',
    优惠金额: 'จำนวนเงินที่ได้รับส่วนลด',
    优惠卷抵扣: 'คูปองส่วนลด',
    邮箱: 'อีเมล',
    邮箱手机号: 'อีเมล์/หมายเลขโทรศัพท์มือถือ',
    邮箱修改: 'แก้ไขอีเมล',
    邮箱验证: 'ยืนยันผ่านอีเมล',
    邮政编码: 'รหัสไปรษณีย์',
    语言: 'ภาษา',
    原密码: 'รหัสผ่านเดิม',
    运费: 'ค่าขนส่ง',
    暂无订单信息: 'ไม่มีข้อมูลการสั่งซื้อในขณะนี้',
    暂无商品: 'ไม่มีสินค้าในขณะนี้',
    暂无售后信息: 'ไม่มีข้อมูลบริการหลังการขายในขณะนี้',
    长度在1到8个字符: 'ความยาว 1 ถึง 8 ตัวอักษร',
    支付宝: 'อาลีเพย์',
    支付成功: 'ชำระเงินสำเร็จ',
    支付订单: 'ชำระเงินสำหรับรายการสั่งซื้อ',
    支付方式: 'วิธีการชำระเงิน',
    支付金额: 'จํานวนเงินที่ต้องชําระ',
    支付凭证: 'หลักฐานการชําระเงิน',
    支付失败: 'ชำระเงินไม่สำเร็จ',
    重新发送验证码: 'ส่งรหัสอีกครั้ง',
    重新申请: 'ส่งคำขอใหม่',
    注册: 'ลงทะเบียน',
    注册成功: 'ลงทะเบียนสำเร็จ',
    综合排序: 'การเรียงลำดับที่ครอบคลุม',
    总价: 'ราคารวม',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件: 'สามารถอัพโหลดรูปภาพได้สูงสุด 5 รูป ขนาดต่อรูปไม่เกิน 5M  รองรับไฟล์รูปแบบ bmp, gif, jpg, png, jpeg',
    最多上传五张: 'อัปโหลดได้สูงสุด 5 รูป',
    推荐商品: 'สินค้าแนะนำ',
    共x件商品已选择x件: 'สินค้าทั้งหมดมี {num} รายการ เลือกแล้ว {num2} รายการ',
    共x件商品: 'รวม {num} รายการ',
    绑定: 'ผูก',
    绑定邮箱: 'ผูกกล่องจดหมาย',
    分享店铺: 'แบ่งปันร้านค้า',
    分享至: 'แชร์ไปที่',
    海报: 'โปสเตอร์',
    分享: 'แบ่งปัน',
    推荐店铺给你: 'ห้างสรรพสินค้าแนะนำสำหรับคุณ',
    链接: 'ลิงค์',

    至: 'ถึง',
    币种: 'สกุลเงิน',
    变动理由: 'เหตุผลในการเปลี่ยนแปลง',
    不记得: 'ไม่จำ',
    充值: 'เติม',
    打款凭证: 'หลักฐานการโอนเงิน',
    打款说明: 'คำอธิบายการชำระเงิน',
    打款信息: 'ข้อมูลการชำระเงิน',
    打款状态: 'สถานะการชำระเงิน',
    待打款: 'รอการชำระเงิน',
    待审核: 'รอตรวจสอบ',
    到账方式: 'วิธีการชำระเงิน',
    到账银行卡号: 'บัตรธนาคารรับเงิน',
    冻结中: 'กำลังถูกอายัด',
    国家: 'ประเทศ',
    获取验证码: 'รับรหัสยืนยัน',
    记得: 'จดจำ',
    记录时间: 'เข้าสู่ระบบเวลา',
    拒绝打款: 'ปฏิเสธที่จะจ่ายเงิน',
    开户人: 'ผู้ถือบัญชี',
    开户支行: 'สาขาธนาคารที่เปิดบัญชี',
    可提现: 'สามารถถอนออกได้',
    可提现金额: 'จำนวนเงินที่ถอนได้',
    立即提现: 'ถอนเงินทันที',
    流水号: 'หมายเลขซีเรียล',
    没有适合此币种的银行卡请先添加: 'ไม่มีบัตรธนาคารที่เหมาะกับสกุลเงินนี้ โปรดเพิ่มก่อน',
    没有收款码请先添加: 'ไม่มีรหัสการชำระเงิน กรุณาเพิ่มก่อน',
    每天最多提现x次单次最高提现金额x: 'ถอนสูงสุดต่อวัน*, จำนวนเงินถอนสูงสุด*',
    您是否记得账号当前使用的支付密码: 'คุณจำรหัสผ่านการชำระเงินที่บัญชีของคุณใช้อยู่ในปัจจุบันหรือไม่?',
    您未设置支付密码请先设置: 'คุณยังไม่ได้ตั้งรหัสผ่านการชำระเงิน กรุณาตั้งก่อน',
    钱包余额: 'ยอดคงเหลือในกระเป๋าสตางค์',
    请输入开户人: 'กรุณากรอกชื่อเจ้าของบัญชี',
    请输入开户支行: 'กรุณากรอกสาขาธนาคารที่เปิดบัญชี',
    请输入收款名称: 'กรุณากรอกชื่อการชำระเงิน',
    请输入提现金额: 'กรุณากรอกจำนวนเงินที่ต้องการถอน',
    请输入新支付密码6位数字: 'กรุณากรอกรหัสผ่านการชำระเงินใหม่ 6 หลัก',
    请输入新支付密码: 'กรุณากรอกรหัสผ่านการชำระเงินใหม่',
    请输入银行卡号: 'กรุณากรอกหมายเลขบัตรธนาคาร',
    请输入原支付密码: 'กรุณากรอกรหัสผ่านการชำระเงินเดิม',
    请输入支付密码6位数字: 'กรุณากรอกรหัสผ่านการชำระเงิน 6 หลัก',
    请输入支付密码: 'กรุณากรอกรหัสผ่านการชำระเงิน',
    请选择币种: 'กรุณาเลือกสกุลเงิน',
    请选择大洲: 'กรุณาเลือกทวีป',
    请选择结束时间: 'โปรดเลือกวันที่สิ้นสุด',
    请选择开始时间: 'โปรดเลือกวันที่เริ่มต้น',
    请选择收款码类型: 'กรุณาเลือกประเภทรหัสการชำระเงิน',
    请选择银行: 'กรุณาเลือกธนาคาร',
    请选择银行名称: 'กรุณาเลือกชื่อธนาคาร',
    请再次输入新支付密码: 'กรุณากรอกรหัสผ่านการชำระเงินใหม่อีกครั้ง',
    请再次输入支付密码: 'กรุณากรอกรหัสผ่านการชำระเงินอีกครั้ง',
    去设置: 'ไปที่การตั้งค่า',
    全部提现: 'ถอนเงินออกทั้งหมด',
    确定删除收款码吗: 'คุณแน่ใจหรือไม่ว่าต้องการลบรหัสการชำระเงิน',
    确定删除银行卡吗: 'คุณแน่ใจหรือไม่ว่าต้องการลบบัตรธนาคาร',
    确定提现: 'ยืนยันการถอนเงิน',
    确认打款: 'ยืนยันการโอนเงิน',
    确认新密码: 'ยืนยันรหัสผ่านใหม่',
    确认支付密码: 'ยืนยันรหัสผ่านการชำระเงิน',
    上传的收款二维码需要清晰无遮挡: 'รหัส QR การชำระเงินที่อัปโหลดจะต้องชัดเจนและไม่มีสิ่งกีดขวาง',
    审核备注: 'หมายเหตุการตรวจสอบ',
    审核不通过: 'การตรวจสอบไม่ผ่าน',
    审核结果: 'ผลการตรวจสอบ',
    审核通过: 'ผ่านการตรวจสอบ',
    审核信息: 'ตรวจสอบข้อมูล',
    时间: 'เวลา',
    实际到账金额: 'จำนวนเงินที่ได้รับจริง',
    收款方式: 'วิธีการชำระเงิน',
    收款管理: 'การจัดการคอลเลกชัน',
    收款码: 'รหัสการชำระเงิน',
    收款码管理: 'การจัดการรหัสการชำระเงิน',
    收款码类型: 'ประเภทรหัสการชำระเงิน',
    收款名称: 'ชื่อผู้รับเงิน',
    收款账号: 'หมายเลขบัญชีการชำระเงิน',
    手续费: 'ค่าธรรมเนียมการถอนเงิน',
    提现: 'ถอนเงิน',
    提现币种: 'สกุลเงินที่ถอนออก',
    提现成功: 'ถอนเงินสำเร็จ',
    提现记录: 'ประวัติการถอนเงิน',
    提现金额: 'จำนวนเงินที่ถอนออก',
    提现失败: 'ถอนเงินไม่สำเร็จ',
    提现时间: 'เวลาถอนเงิน',
    提现手续费: 'ค่าธรรมเนียมการถอนเงิน',
    提现详情: 'รายละเอียดการถอนเงิน',
    提现中: 'กำลังถอนเงิน',
    提现状态: 'สถานะการถอนเงิน',
    添加收款码: 'เพิ่มรหัสการชำระเงิน',
    添加银行卡: 'เพิ่มบัตรธนาคารใหม่',
    退款: 'การคืนเงิน',
    忘记支付密码: 'ลืมรหัสผ่านการชำระเงินของคุณ?',
    尾号: 'หมายเลขหาง',
    我的钱包: 'กระเป๋าเงินของฉัน',
    我的余额: 'จำนวนเงินของฉัน',
    详情: 'รายละเอียด',
    消费: 'รายจ่าย',
    新支付密码: 'รหัสผ่านการชำระเงินใหม่',
    修改支付密码: 'เปลี่ยนรหัสผ่านการชำระเงิน',
    选择收款方式: 'เลือกวิธีการชำระเงิน',
    银行卡: 'บัตรธนาคาร',
    银行卡管理: 'การจัดการบัตรธนาคาร',
    银行卡号: 'หมายเลขบัตรธนาคาร',
    银行名称: 'ชื่อธนาคาร',
    余额记录: 'บันทึกยอดคงเหลือ',
    余额数据: 'ข้อมูลยอดคงเหลือ',
    原支付密码: 'รหัสผ่านการชำระเงินเดิม',
    暂无数据: 'ยังไม่มีข้อมูล',
    支持币种: 'สกุลเงินที่รองรับ',
    支付密码: 'รหัสผ่านการชำระเงิน',
    只可上传一张图片图片大小不超过5M支持jpgpngjpeg格式文件: 'สามารถอัพโหลดได้เพียงภาพเดียว ขนาดภาพไม่เกิน 5M รองรับไฟล์รูปแบบ jpg, PNG, JPEG',
    账户余额: 'ยอดเงินในบัญชี',
    钱包服务: 'บริการกระเป๋าเงิน',
    每天最多提现x次单次最高提现金额xx最低提现金额xx: 'จำนวนถอนสูงสุดต่อวันคือ {num} เท่า จำนวนถอนสูงสุดต่อครั้งคือ {icon}{money} และจำนวนถอนขั้นต่ำคือ {icon}{money2}',
    确认: 'ยืนยัน',
    输入金额超过可提现余额: 'จำนวนเงินที่ป้อนเกินยอดคงเหลือที่ถอนได้',
    日期选择: 'การเลือกวันที่',
    备注: 'ข้อสังเกต',
    当前没有余额记录: 'ขณะนี้ไม่มีบันทึกยอดเงินคงเหลือ!',
    当前没有提现记录: 'ขณะนี้ไม่มีบันทึกการถอนเงิน!',
    还没有绑定银行卡: 'ยังไม่มีการผูกบัตรธนาคาร!',
    还没有添加收款码: 'ยังไม่มีการเพิ่มรหัสการชำระเงิน!',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡: 'เคล็ดลับ: สามารถอัปโหลดได้เพียงภาพเดียวเท่านั้น ขนาดของภาพไม่ควรเกิน 5M และรองรับไฟล์รูปแบบ jpg, PNG และ JPEG รหัส QR การชำระเงินที่อัปโหลดจะต้องชัดเจนและไม่มีสิ่งกีดขวาง',
    请上传收款码: 'กรุณาอัพโหลดรหัสการชำระเงิน',
    请输入收款码名称: 'กรุณากรอกชื่อรหัสการชำระเงิน',
    暂无银行卡: 'ยังไม่มีบัตรธนาคาร',
    余额: 'สมดุล',
    重置: 'รีเซ็ต',
    暂无收款码: 'ยังไม่มีรหัสการชำระเงิน',
    保障支付安全: 'มั่นใจในความปลอดภัยในการชำระเงิน',
    打款成功: 'ชำระเงินสำเร็จ',
    请选择收款方式: 'กรุณาเลือกวิธีการชำระเงิน',
    收款类型: 'ประเภทการชำระเงิน',
    编辑银行卡: 'แก้ไขบัตรธนาคาร',
    编辑收款码: 'แก้ไขรหัสการชำระเงิน',
    收款码名称: 'ชื่อรหัสการชำระเงิน',
    订单ID: 'รหัสคำสั่งซื้อ',
    如果您已支付成功请点击确认支付按钮: 'หากการชำระเงินของคุณสำเร็จ โปรดคลิกปุ่ม "ยืนยันการชำระเงิน"',
    确认支付: 'ยืนยันการชำระเงิน',
    支付遇到问题: 'ประสบปัญหา',
    对不起您访问的店铺可能已被删除名称被更改或不存在: 'ขออภัย ร้านค้าที่คุณเยี่ยมชมอาจถูกลบไปแล้ว เปลี่ยนชื่อแล้ว หรืออาจไม่มีอยู่...',
    卢比支付: 'การชำระเงิน IDR',
    比索支付: 'การชำระเงิน PHP',
    泰铢支付: 'การชำระเงิน THB',
    越南盾支付: 'การชำระเงิน VND',
    银行卡编码: 'รหัสธนาคาร',
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0": "{currencyUnit}{money}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1": "น้ำหนักเริ่มต้น{num}kg{currencyUnit}{money}，น้ำหนักทิ่เกินจากน้ำหนักเริ่มต้น{num2}kg{currencyUnit}{money2}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2": "สินค้ารายการแรก{num3}ชิ้น{currencyUnit}{money3}，สินค้ารายการถัดไป{num4}ชิ้น{currencyUnit}{money4}",
    暂无配送地区: 'ยังไม่มีพื้นที่จัดส่ง',
    该商品在当前地区暂不支持配送: 'ขณะนี้ผลิตภัณฑ์นี้ไม่รองรับการจัดส่งในภูมิภาคปัจจุบัน',
    当前商品仅支持以下地区发货如有疑问请联系店铺:
        'สินค้าปัจจุบันรองรับการจัดส่งเฉพาะพื้นที่ต่อไปนี้เท่านั้น หากมีข้อสงสัย กรุณาติดต่อทางร้าน',
    运费计价说明: 'คำแนะนำการกำหนดราคาค่าขนส่ง',
    发货地: 'สถานที่จัดส่ง',
    配送至: 'ส่งถึง',
    打款中: 'อยู่ระหว่างดำเนินการชำระเงิน',
    未添加地址请先添加: 'ไม่ได้เพิ่มที่อยู่ กรุณาเพิ่มก่อน',
    请输入大于1的正整数: 'โปรดป้อนจำนวนเต็มบวกที่มากกว่า 1',
    设置密码: 'ตั้งรหัสผ่าน',
    登录密码: 'รหัสผ่านการเข้าใช้งาน',
    长度在8到16个字符: 'ความยาว 8 ถึง 16 ตัวอักษร',
    请输入旧密码: 'กรุณากรอกรหัสผ่านเก่า',
    必须包含两种字符: 'ต้องมีอักขระสองตัว',
    当前仅支持xxx的金额: 'ขณะนี้รองรับเฉพาะจำนวนเงินตั้งแต่ {currency} {min}-{currency} {max}',
    查看运费: 'ดูค่าขนส่ง',
    运费方式: 'วิธีการจัดส่งสินค้า',
    价格: 'ราคา',
    请选择配送方式: 'กรุณาเลือกวิธีการจัดส่ง',
    配送方式: 'วิธีการจัดส่ง',
    配送费说明: 'คำอธิบายค่าธรรมเนียมการจัดส่ง',
    我明白了: 'ฉันเห็น',
    若多个商品选用同一个运费模板则运费是按同一个运费方式的规则进行计算: 'หากสินค้าหลายรายการใช้เทมเพลตการขนส่งสินค้าเดียวกัน ค่าขนส่งจะถูกคำนวณตามกฎของวิธีการขนส่งสินค้าเดียวกัน',
    若多个商品不是同一个运费模板则运费是多个模板的价格之和: 'หากสินค้าหลายรายการไม่มีเทมเพลตค่าธรรมเนียมการจัดส่งที่เหมือนกัน ค่าธรรมเนียมการจัดส่งจะเป็นผลรวมของราคาของเทมเพลตหลายรายการ',
    快捷登录登录即表示同意协议: 'การเข้าสู่ระบบแสดงว่าคุณยอมรับข้อตกลง',
    促销广告: 'โฆษณาส่งเสริมการขาย',
    最近新品: 'สินค้าใหม่',
    更多: 'มากกว่า',
    设置成功: 'การติดตั้งที่ประสบความสำเร็จ',
    更多商品:'สินค้าเพิ่มเติม',

    //     1.6
    我的优惠券:'คูปองส่วนลดของฉัน',
    历史记录:'บันทึกประวัติศาสตร์',
    已使用:'ใช้แล้ว',
    已过期:'หมดอายุแล้ว',
    优惠券:'คูปอง',
    领取:'รับ',
    已领取:'ได้รับ',
    有效期:'ระยะเวลามีผล：',
    活动优惠详情:'รายละเอียดส่วนลดกิจกรรม',
    可用商品:'รายการที่มีอยู่',
    单独购买:'ราคาเดิม',
    发起拼团:'ราคากลุ่ม',
    拼团价:'ราคากลุ่ม',
    可参与的拼团:'การซื้อแบบกลุ่มที่มีอยู่',
    '再邀X人即可拼单成功':'แชร์ให้อีก {num} คนเพื่อความสำเร็จในการสั่งซื้อ>',
    拼单详情:'รายละเอียดการซื้อแบบกลุ่ม',
    待分享:'รอที่จะแบ่งปัน',
    '若拼团失败，货款将原路退回':'หากการซื้อแบบกลุ่มล้มเหลว การชำระเงินจะได้รับคืน',
    促销价:'ลดราคา',
    距离结束时间:'End in',
    满XX减XX:"ใช้จ่าย {currencyUnit}{priceStart} ได้รับลด {currencyUnit}{reducePrice}{couponType==2?'%':''}",
    满XX减X:'ใช้จ่าย {currencyUnit}{priceStart} ได้รับลด {reducePrice}%',
    分享好友X人成团立省X:'ซื้อเป็นกลุ่มสำหรับ<span style="color: #8D4BBB;">{num}</span>คน ประหยัด<span style="color: #8D4BBB;">{currencyUnit}{groupPrice}</span>',
    分享好友:'แบ่งปันกับเพื่อน ๆ',
    '这些人正在拼，立即参与 ':'อยู่ระหว่างการซื้อแบบกลุ่ม เข้าร่วมทันที ',
    满x可用:'สามารถสั่งซื้อได้ตั้งแต่ {currencyUnit}{priceStart} ขึ้นไป',
    这些人正在拼立即参与:'อยู่ระหว่างการซื้อแบบกลุ่ม เข้าร่วมทันที',
    限领x张:'{receiveCount} คูปองต่อจำนวนผู้ใช้',
    满XX送赠品:'ฟรีของสมนาคุณเมื่อสั่งซื้อเกิน {currencyUnit}{priceStart}',
    去使用:'ใช้',
    销量:'ฝ่ายขาย',
    赠品:'ให้ออกไป',
    限时优惠:'ข้อเสนอเวลาจำกัด',
    距结束:'End in',
    拼团好物:'การซื้อรวมกลุ่ม',
    当前优惠券可以购买以下商品:'คูปองปัจจุบันสามารถซื้อผลิตภัณฑ์ต่อไปนี้ได้:',
    以下商品:'สินค้าต่อไปนี้',
    满XX减XX可用:"ใช้จ่าย {currencyUnit}{priceStart} ได้รับลด {currencyUnit}{reducePrice}",
    满XX减X可用:'ใช้จ่าย {currencyUnit}{priceStart} ได้รับลด {reducePrice}%',
    再买x可用:'ยังคงต้องซื้อ {currencyUnit}{difPrice}',
    合计:'ทั้งหมด',
    优惠:'การลดราคา',
    去购物车:'ตะกร้าสินค้า',
    拼团:'การซื้อรวมกลุ่ม',
    满XX可用:'สามารถสั่งซื้อได้ตั้งแต่ {currencyUnit}{priceStart} ขึ้นไป',
    满减满赠:'ส่วนลดเต็ม/ของสมนาคุณเต็ม',
    成团发货:'รอจัดส่งครับ',
    还差xx人成团:'ยังต้องการคน<span style="color: #FF4545;">{num}</span>คนจึงจะสั่งซื้อได้สำเร็จ',
    拼团结束:'การซื้อแบบกลุ่มสิ้นสุดลง',
    拼团成功:'การซื้อแบบกลุ่มสำเร็จ',
    有成员未付款请等待付款成团:'มีคนยังไม่ได้ชำระเงิน，กรุณารอการชำระเงิน',
    我正在参与的拼团:'มีส่วนร่วมในการซื้อแบบกลุ่ม',
    满减:'ส่วนลดเต็ม',
    满赠:'ของสมนาคุณเต็ม',
    凑单:'เพิ่มรายการเพื่อครบยอด',
    切换其他优惠:'เลือกข้อเสนออื่น',
    超过最大购买数:'เกินจำนวนการซื้อสูงสุด',
    商品限购x件:'จำกัดจำนวน {num} ชิ้น',
    参与拼团:'เข้าร่วม',
    结束:'结束',
    您还没有优惠券:'คุณไม่มีคูปอง',
    优惠券历史记录:'บันทึกประวัติศาสตร์',
    拼单失败:'การซื้อแบบกลุ่มล้มเหลว',
    免费:'ฟรี',
    已赠完:'สินค้าหมด',
    此拼团已满请选择其他拼团:'การซื้อกลุ่มนี้เต็มแล้ว โปรดเลือกการซื้อกลุ่มอื่น',
    以下商品正在限时优惠当中:'ข้อเสนอมีเวลาจำกัดสำหรับผลิตภัณฑ์ต่อไปนี้',
    以下商品正在限时拼团当中:'การซื้อผลิตภัณฑ์ต่อไปนี้เป็นกลุ่มในระยะเวลาจำกัด',
    再买X可减X:'ซื้ออีก {currencyUnit}{difPrice} และรับส่วนลด {currencyUnit}{reducePrice}',
    已领完:"หมดเกลี้ยง",
    活动未开始:'กิจกรรมยังไม่ได้เริ่ม',

    已满X可减X:'รับส่วนลด {currencyUnit}{reducePrice} เมื่อคุณใช้จ่ายตั้งแต่ {currencyUnit}{difPrice} ขึ้นไป',
    已满X可减XX:'รับส่วนลด {reducePrice}% เมื่อคุณใช้จ่ายตั้งแต่ {currencyUnit}{difPrice} ขึ้นไป',

    再买X可减XX:'ซื้ออีก {currencyUnit}{difPrice} และรับส่วนลด {reducePrice}%',
    再买X送赠品:'ซื้ออีก {currencyUnit}{difPrice} และรับของขวัญฟรี',
    已满X送赠品:'ได้มอบของขวัญแล้ว',
    缺少库存: 'ขาดสต๊อก',
    限X件:'จำกัดการซื้อ {num} ชิ้น',
    房间号已失效请联系工作人员:'หมายเลขห้องหมดอายุแล้วกรุณาติดต่อเจ้าหน้าที่!',


    退货积分:'จุดกลับ',
    积分:'บูรณาการ',
    用户注册:'การลงทะเบียนผู้ใช้',
    手动增加:'เพิ่มขึ้นด้วยตนเอง',
    积分兑换退款:'การคืนคะแนน',
    下单购物:'สั่งซื้อเพื่อชอปปิ้ง',
    过期积分:'คะแนนหมดอายุ',
    兑换商品:'แลกสินค้า',
    手动减少:'การลดขนาดด้วยตนเอง',
    订单退款:'การคืนเงินคำสั่งซื้อ',
    积分明细:'รายละเอียดคะแนน',
    已兑:'แลกแล้ว',
    积分商城:'พอยท์มอลล์',
    规则:'กฎ',
    可用积分:'คะแนนที่มีอยู่',
    冻结积分:'จุดเยือกแข็ง',
    我的兑换:'การแลกเปลี่ยนของฉัน',
    售后:'หลังการขาย',
    分销中心:'ศูนย์กระจายสินค้า',
    立即兑换:'แลกเลย',
    已兑完:'แลกแล้ว',
    "积分不足，无法兑换":'คะแนนไม่เพียงพอไม่สามารถแลกได้',
    兑换数量:'ปริมาณการแลกเปลี่ยน',
'限兑*件':'สินค้าแลกรับจำนวนจำกัด{num}',
    积分抵扣:'การหักคะแนน',
'共*分':'รวม {num} คะแนน',
    全部订单:'ทุกออเดอร์',

兑换须知:'คำแนะนำในการไถ่ถอน',
    已达兑换上限:'ถึงขีดจำกัดการไถ่ถอนแล้ว',
    我的积分:'คะแนนของฉัน',
    积分规则:'กฎของคะแนน',
    兑换时间:'เวลาไถ่ถอน',
    我的佣金:'ค่าคอมมิชชั่นของฉัน',
    佣金数据:'ข้อมูลคอมมิชชั่น',
    分销申请协议:'ข้อตกลงการสมัครการจัดจำหน่าย',
    申请成为分销员:'สมัครเป็นตัวแทนจำหน่าย',
    "已申请，请等待审核":'สมัครแล้ว โปรดรอการตรวจสอบ',
    "审核拒绝，继续申请":'ถูกปฏิเสธโดยการตรวจสอบ สมัครต่อไป',
    可提佣金:'ค่าคอมมิชชั่นการถอนเงิน',
    冻结佣金:'ระงับค่าคอมมิชชั่น',
    分销订单:'คำสั่งการจำหน่าย',
    佣金明细:'รายละเอียดค่าคอมมิชชั่น',
    我的下级:'ผู้ใต้บังคับบัญชาของฉัน',
    推广商品:'ส่งเสริมผลิตภัณฑ์',
    推广店铺:'โปรโมทร้าน',
    暂无记录:'ไม่มีบันทึก',
    一级佣金收入:'รายได้ค่าคอมมิชชันระดับแรก',
    一级佣金退款:'การคืนเงินค่าคอมมิชชันระดับแรก',
    二级佣金收入:'รายได้ค่าคอมมิชชั่นรอง',
    二级佣金退款:'การคืนเงินค่าคอมมิชชันระดับที่สอง',
    下单用户:'ผู้ใช้ที่สั่งซื้อ',
    订单总价:'ราคารวมสั่ง',
    分销利润层级:'ระดับการกระจายสินค้า',
    结算状态:'สถานะการตั้งถิ่นฐาน',
    佣金:'คณะกรรมการ',
    一级:'ระดับ 1',
    二级:'ระดับ 2',
    已结算:'ตัดสินแล้ว',
    请输入用户昵称或手机号:'กรุณากรอกชื่อเล่นหรือหมายเลขโทรศัพท์มือถือ',
    二级团队:'ทีมระดับสอง',
    分享链接:'แบ่งปันลิงค์',
    可推广至:'สามารถขยายออกไปได้',
    分享最高可赚x:'รับสูงถึง {icon}{num} ด้วยการแบ่งปัน',
    分享推广:'แบ่งปันและส่งเสริม',
    用户昵称或手机号:'ชื่อเล่นของผู้ใช้หรือหมายเลขโทรศัพท์มือถือ',
    商品名称:'ชื่อผลิตภัณฑ์',
    最高可得:'สูงสุดที่มีอยู่',
    可获得积分:'คะแนนที่ได้รับ',
    总获佣金:'ค่าคอมมิชชั่นทั้งหมดที่ได้รับ',

    佣金详情:'รายละเอียดค่าคอมมิชชั่น',
    已退款:'คืนเงินแล้ว',
    请输入商品名称:'กรุณากรอกชื่อผลิตภัณฑ์',
    请选择银行卡: 'โปรดเลือกบัตรธนาคาร',
    请选择收款码: 'กรุณาเลือกรหัสชำระเงิน',
    不能超过最小提现额度:'จำนวนเงินถอนขั้นต่ำต้องไม่เกิน!',


    货柜商品:'สินค้าคอนเทนเนอร์',
    该浏览器不支持蓝牙:'เบราว์เซอร์นี้ไม่รองรับ Bluetooth โปรดคัดลอกลิงก์ไปที่ Chrome อินเทอร์เน็ตเบราว์เซอร์ Samsung เพื่อเปิด',
    iOS系统暂不支持售货机取货请使用Android系统扫码购物:'โปรดใช้ระบบ Android เพื่อสแกนรหัส QR เพื่อซื้อสินค้า สำหรับระบบ iOS โปรดคลิก "ไปที่หน้าแรกของห้างสรรพสินค้า" แล้วเจ้าหน้าที่จะจัดส่งไปที่ประตูบ้านของคุณ ขอขอบคุณสำหรับความเข้าใจของคุณ!',
    去商城首页:'ไปที่หน้าแรกของห้างสรรพสินค้า',
    已售完:'ขายหมดแล้ว',
    蓝牙未连接:'ไม่ได้เชื่อมต่อบลูทูธ',
    蓝牙已连接:'เชื่อมต่อบลูทูธแล้ว',
    重试:'ลองอีกครั้ง',
    蓝牙未授权请打开蓝牙后重试:'บลูทูธไม่ได้รับอนุญาต โปรดเปิดบลูทูธแล้วลองอีกครั้ง!',
    蓝牙未连接请选择蓝牙并配对:'ไม่ได้เชื่อมต่อบลูทูธ โปรดเลือกบลูทูธและจับคู่!',
    连接:'เชื่อมต่อ',
    蓝牙已断开请重新连接:'บลูทูธถูกตัดการเชื่อมต่อ โปรดเชื่อมต่อใหม่',
    蓝牙连接中:'การเชื่อมต่อบลูทูธ...',
    购买成功请取出商品若柜门未打开请尝试再次打开:'การซื้อสำเร็จ กรุณานำผลิตภัณฑ์ออก! หากประตูตู้ไม่เปิด โปรดลองเปิดอีกครั้ง!',
    再次打开:'เปิดอีกครั้ง',
    普通订单:'สั่งธรรมดา',
    售货机:'เครื่องจำหน่ายสินค้าอัตโนมัติ',
    售货机已无货无法支付:'ตู้จำหน่ายสินค้าอัตโนมัติหมดและไม่สามารถชำระเงินได้',
    订单来源:'แหล่งสั่งซื้อ',
    连接失败请重新连接:'การเชื่อมต่อล้มเหลว กรุณาเชื่อมต่อใหม่',
    售罄:'ขายหมดแล้ว',
    '房间号/桌号':'หมายเลขห้อง/หมายเลขโต๊ะ',
    "柜门开启中···": "ประตูตู้กำลังเปิดออก...",
    "微信不支持蓝牙，请点击":'เบราว์เซอร์ WeChat ไม่รองรับ Bluetooth กรุณาคลิก',
    "选择在Google或Samsung Internet浏览器中打开":'เลือกเปิดในอินเทอร์เน็ตเบราว์เซอร์ Google<img src="https://saasimage.bawanli.com/users/2024-11-01/485b6a31-fe3e-4b07-b42a-71f3e2462ce2.png" style="width: 30px;height: 30px;margin:0 4px;"> หรือ Samsung<img src="https://saasimage.bawanli.com/users/2024-11-01/805ed567-6a5b-47fc-a053-e1c2f50b67f6.png" style="width: 30px;height: 30px;margin:0 4px;">，<span style="color: red;">หากคุณไม่มีเบราว์เซอร์ข้างต้น โปรดคลิกลิงก์ด้านล่างเพื่อดาวน์โหลด: <a href="https://saasimage.bawanli.com/users/2024-11-01/Chrome.apk">【Chrome】</a><a href="https://saasimage.bawanli.com/users/2024-11-04/Samsung.apk">【Samsung Internet】</a></span>',
    "复制链接":'คัดลอกลิงก์',
    "通过在线商城购买的订单，哇呗小店将配送上门，配送时间：06:00-23:00":'คำสั่งซื้อที่ซื้อผ่านห้างสรรพสินค้าออนไลน์จะจัดส่งถึงหน้าประตูบ้านคุณโดยร้าน Wabei เวลาจัดส่ง: 06:00-23:00 น',
    "我已知晓":'ฉันรู้แล้ว'
}
// 泰国
