// 埃塞俄比亚
export default {
    BCEL支付: 'BCEL',
    KBZ支付: 'KBZpay',
    OnePay支付: 'OnePay',
    OnePay支付二维码: 'OnePay QR ኮድ',
    保存: 'አስቀምጥ',
    保密: 'የግል',
    备注说明: 'አስተያየት',
    编辑地址: 'አድራሻ አርትዕ',
    标记已读: 'እንደተነበበ ምልክት ተደርጎበታል።',
    不可修改最多: 'የማይቀየር፣ እስከ',
    部分订单取消: 'ከፊል ትዕዛዝ መሰረዝ',
    部分发货: 'በከፊል ተልኳል።',
    部分商品正在退款中: 'አንዳንድ ዕቃዎች ገንዘብ ተመላሽ እየተደረገ ነው።',
    部分收货: 'ከፊል ደረሰኝ',
    部分退款: 'ከፊል ተመላሽ ገንዘብ',
    部分退款完成: 'ከፊል ተመላሽ ገንዘብ ተጠናቅቋል',
    查看订单: 'ትዕዛዞችን ይመልከቱ',
    查看全部: 'ሁሉንም ይመልከቱ',
    查看全部xx条回复: 'ሁሉንም {num} ምላሾች ይመልከቱ',
    查看详情: 'ዝርዝሮችን ይመልከቱ',
    查看支付是否成功: 'ክፍያው የተሳካ መሆኑን ያረጋግጡ>>>',
    查询中: 'ጥያቄ',
    城市: 'ከተማ',
    创建时间: 'የፍጥረት ጊዜ',
    此宝贝已下架: 'ምርቱ ተወግዷል.',
    待发货: 'አልተላከም።',
    待付款: 'ያልተከፈለ',
    待收货: 'ያልደረሰ',
    当前安全手机号: 'የእርስዎ ስልክ ቁጥር',
    地址管理: 'የአድራሻ አስተዳደር',
    登录: 'ስግን እን',
    等待店铺审核: 'የሻጩን ግምገማ በመጠበቅ ላይ',
    点击选择城市: 'ከተማውን ለመምረጥ ጠቅ ያድርጉ',
    点击选择国家: 'አገር ለመምረጥ ጠቅ ያድርጉ',
    点击选择退货类型: 'የመመለሻ አይነትን ለመምረጥ ጠቅ ያድርጉ',
    点击选择退货原因: 'የሚመለሱበትን ምክንያት ለመምረጥ ጠቅ ያድርጉ',
    店: 'S',
    店铺: 'ሻጭ',
    店铺处理: 'በሂደት ላይ',
    店铺拒绝您的申请: 'መደብሩ ማመልከቻህን ውድቅ አደረገው።',
    店铺退款: 'ገንዘብ ተመላሽ ማድረግ',
    订单编号: 'ትዕዛዝ ቁጥር.',
    订单号: 'ትዕዛዝ ቁጥር.',
    订单详情: 'የትዕዛዝ ዝርዝሮች',
    订单已超时: 'ትዕዛዙ ጊዜው አልፎበታል።',
    短信验证码: 'የኤስኤምኤስ ማረጋገጫ ኮድ',
    发表留言: 'መልዕክትዎን ይተዉ',
    发货: 'ተልኳል።',
    发货件数: 'የተላኩ ቁርጥራጮች',
    发货时间: 'የማጓጓዣ ጊዜ',
    发货状态: 'የመላኪያ ሁኔታ',
    发送成功: 'በተሳካ ሁኔታ ተልኳል።',
    发送验证码: 'የማረጋገጫ ኮድ ላክ',
    法律声明: 'የህግ ማሳሰቢያዎች',
    返回: 'ተመለስ',
    返回首页: 'ወደ ቤት መመለስ',
    分: 'ደቂቃ',
    分类: 'ምደባ',
    付款时间: 'የክፍያ ጊዜ',
    复制: 'ቅዳ',
    复制成功: 'በተሳካ ሁኔታ ተቀድቷል።',
    该订单已取消: 'ይህ ትዕዛዝ ተሰርዟል።',
    个人信息: 'የግል መረጃ',
    个字以内: 'በ {num} ቃላት ውስጥ',
    恭喜您邮箱地址验证成功: 'እንኳን ደስ አላችሁ! ኢሜል በተሳካ ሁኔታ ተረጋግጧል።',
    购买商品: 'ምርቶችን ይግዙ',
    购买数量: 'ብዛት ይግዙ።',
    购买信息: 'የግዢ መረጃ',
    购物车: 'የግዢ ጋሪ',
    国家区域: 'ሀገር / ክልል',
    含发货邮费: 'የማጓጓዣ ወጪ ተካትቷል።',
    欢迎登录: 'ይግቡ',
    活动优惠: 'ቅናሽ',
    货币: 'ምንዛሪ',
    继续逛逛: 'ለተጨማሪ ይሂዱ',
    加入购物车: 'ወደ ግዢው ቅርጫት ጨምር',
    加载中: 'በመጫን ላይ',
    价格高低: 'በዋጋ ደርድር',
    价格最低: 'ዝቅተኛው ዋጋ',
    价格最高: 'ከፍተኛ ዋጋ',
    件商品: '{num} ንጥሎች',
    交易异常: 'የግብይት ልዩነት',
    看不清换一张: 'አንዱን ቀይር',
    客: 'C',
    客服: 'የደንበኞች ግልጋሎት',
    客服电话: 'የስልክ መስመር',
    客服邮箱: 'ኢሜይል',
    客户: 'ደንበኛው',
    客户服务: 'የደንበኞች ግልጋሎት',
    客户取消售后: 'ደንበኛው ከሽያጭ በኋላ ያለውን አገልግሎት ሰርዟል።',
    客户已付款等待店铺发货: 'ቀድሞውኑ ተከፍሏል፣ እና ጭነቱን እየጠበቅኩ ነው።',
    历史搜索: 'ታሪክ ፍለጋ',
    立即登录: 'አሁን ይግቡ',
    立即购买: 'አሁን ግዛ',
    立即留言: 'አሁን መልእክት ይተው',
    立即提交: 'አሁን አስገባ',
    立即注册: 'አሁን ይመዝገቡ',
    联系电话: 'የእውቂያ ቁጥር',
    联系方式: 'ተገናኝ',
    联系客服: 'የደንበኞች ግልጋሎት',
    联系人: 'ተገናኝ',
    两次输入密码不一致: 'የገቡት ሁለቱ የይለፍ ቃሎች ወጥነት የሌላቸው ናቸው',
    留言成功: 'በተሳካ ሁኔታ መልእክት ተላልፏል',
    留言管理: 'የመልዕክት አስተዳደር',
    留言回复: 'መልስ',
    留言回复长度: 'ከ2-100 ቃላት ምላሽ ይስጡ',
    留言回复长度XX字符: 'ከ2-100 ቃላት ምላሽ ይስጡ',
    留言内容: 'የመልእክት ይዘት',
    留言详情: 'የመልዕክት ዝርዝሮች',
    没有更多了: 'በቃ.',
    每张图片大小不超过3M: 'የእያንዳንዱ ስዕል መጠን ከ 3M አይበልጥም',
    密码: 'የይለፍ ቃል',
    密码登录: 'በይለፍ ቃል ይግቡ',
    密码最少8位最多16位: 'የይለፍ ቃል በ8-16 ቁምፊዎች ውስጥ',
    秒: 'ሁለተኛ',
    秒后重新发送: 'በ {seconds} ሰከንዶች ውስጥ እንደገና ይላኩ።',
    名: 'የተሰጠ ስም',
    默认地址: 'ነባሪ አድራሻ',
    男: 'ወንድ',
    昵称: 'ተለዋጭ ስም',
    昵称不能为空: 'ባዶ ተለዋጭ ስም የለም።',
    您当前是线下支付请耐心等待店铺确认收款若x后店铺未确认收款订单将自动取消: 'በአሁኑ ጊዜ ከመስመር ውጭ እየከፈሉ ነው፣ እባክዎ ደረሰኝ እስኪያረጋግጥ ድረስ ማከማቻው በትዕግስት ይጠብቁ! መደብሩ ከ<span style="color: red;">{num}</span> በኋላ መቀበሉን ካላረጋገጠ ትዕዛዙ በራስ ሰር ይሰረዛል!',
    您的购物车还没有商品哟: 'በጋሪዎ ውስጥ እስካሁን ምንም ምርቶች የሉም።~',
    您的收货地址为空点此添加收货地址: 'የመላኪያ አድራሻዎ ባዶ ነው; የመላኪያ አድራሻ ለመጨመር እዚህ ጠቅ ያድርጉ',
    您可以参考如下线下付款方式: 'ከዚህ በታች የሚመረጡት የክፍያ መንገዶች',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料: 'እዚህ ለሻጩ መልዕክት መተው ይችላሉ። መብቶችዎን እና ፍላጎቶችዎን ለመጠበቅ እባክዎ በተቻለ መጠን ዝርዝር መረጃ ይስቀሉ።',
    您未支付请重新支付: '您未支付，请重新支付',
    女: 'ሴት',
    评论成功: 'በተሳካ ሁኔታ አስተያየት ሰጥቷል',
    切换语言和货币: 'ቋንቋ እና ምንዛሬ ቀይር',
    请勾选删除商品: 'እባክዎ መሰረዝ ያለባቸውን እቃዎች ላይ ምልክት ያድርጉ',
    请勾选商品: 'እባክህ ንጥሉን ምልክት አድርግበት።',
    请勾选信息: 'እባክዎ መልዕክቶችን ምልክት ያድርጉ',
    请上传图片: 'እባክዎን ምስል ይስቀሉ።',
    请输入短信验证码: 'እባክዎ የኤስኤምኤስ ማረጋገጫ ኮድ ያስገቡ',
    请输入反馈内容: 'እባክዎን አስተያየትዎን ያስገቡ',
    请输入留言内容: 'እባክህ መልእክትህን አስገባ',
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名: '请输入留言内容，若您使用BCEL或KBZPay支付，请在此输入用来接收退款的银行卡号及户名',
    请输入密码: 'እባክህ የይለፍ ቃል አስገባ',
    请输入内容: 'እባክዎ ዝርዝሩን ያስገቡ',
    请输入手机号: 'እባክህ ስልክ ቁጥር አስገባ',
    请输入搜索关键词: 'እባክህ የፍለጋ ቃላትን አስገባ',
    请输入下方图形验证码: 'እባክዎ ከታች ያለውን ግራፊክ ማረጋገጫ ኮድ ያስገቡ',
    请输入详细地址信息如道路门牌号小区楼层号等: 'እባክዎን እንደ መንገድ፣ የቤት ቁጥር፣ ማህበረሰብ፣ ወለል ቁጥር፣ ወዘተ ያሉ ዝርዝር የአድራሻ መረጃዎችን ያስገቡ።',
    请输入新密码: 'እባክዎ አዲስ የይለፍ ቃል ያስገቡ',
    请输入验证码: 'እባክዎ የማረጋገጫ ኮድ ያስገቡ',
    请输入用户名: 'እባክዎ የተጠቃሚ ስም ያስገቡ',
    请输入邮箱: 'እባክህ የኢሜል አድራሻህን አስገባ',
    请输入邮箱手机号: 'እባክዎ ኢሜልዎን/ስልክ ቁጥርዎን ያስገቡ',
    请输入原密码: 'እባክህ ዋናውን የይለፍ ቃል አስገባ',
    请输入正确的密码格式必须包含两种字符: 'እባክዎን ሁለት የቁምፊ ዓይነቶችን የያዘ ትክክለኛውን የይለፍ ቃል ቅርጸት ያስገቡ',
    请输入正确的邮箱: 'እባክዎ የሚሰራ ኢሜይልዎን ያስገቡ',
    请填写联系电话: 'እባክዎን ስልክ ቁጥሩን ያስገቡ',
    请填写联系人: 'እባክዎ የእውቂያ ስም ያስገቡ',
    请填写留言内容: 'እባክህ መልእክትህን አስገባ',
    请填写名: 'እባክዎን የተሰጠውን ስም ይሙሉ',
    请填写收货地址: 'እባክዎ የመላኪያ አድራሻውን ይሙሉ',
    请填写完整必填项: 'እባክዎ ሁሉንም የግዴታ መስኮች ይሙሉ',
    请填写详细地址: 'እባክዎን ዝርዝር አድራሻውን ይሙሉ',
    请填写姓: 'እባክዎን የአያት ስም ይሙሉ።',
    请填写邮政编码: 'እባክህ የፖስታ ኮድ አስገባ',
    请选择: 'ይምረጡ',
    请选择城市: 'እባክዎ ከተማዋን ይምረጡ',
    请选择付款方式: 'እባክዎ የመክፈያ ዘዴ ይምረጡ',
    请选择规格: 'እባክዎ ዝርዝር መግለጫዎችን ይምረጡ',
    请选择国家: 'እባክህ አገር ምረጥ',
    请选择删除项需已读才能删除: 'እባክዎ የሚሰርዙትን ንጥል ይምረጡ። የተነበቡ ንጥሎች ብቻ ሊሰረዙ ይችላሉ።',
    请再次输入密码: 'እባክዎ አዲሱን የይለፍ ቃል እንደገና ያስገቡ',
    请在x内完成支付否则订单会被自动取消: 'እባክዎን ክፍያውን በ <span style="color: red;">{num}</span>; አለበለዚያ ትዕዛዙ በራስ-ሰር ይሰረዛል.',
    请在下次使用邮箱地址进行登录: 'እባክዎ በሚቀጥለው ጊዜ በኢሜል ይግቡ',
    取消: 'ሰርዝ',
    取消成功: 'በተሳካ ሁኔታ ተሰርዟል።',
    取消订单: 'ትእዛዝ ሰርዝ',
    取消订单成功: "ትዕዛዝ በተሳካ ሁኔታ ተሰርዟል።",
    取消时间: 'የስረዛ ጊዜ',
    取消售后: 'ከሽያጭ በኋላ ሰርዝ',
    去付款: 'ይክፈሉ።',
    去支付: 'ይክፈሉ።',
    全部: 'ሁሉም',
    全部分类: 'ሁሉም ምድቦች',
    全部消息: 'ሁሉም መልዕክቶች',
    全选: 'ሁሉንም ምረጥ',
    确定: 'አረጋግጥ',
    确定放弃支付吗: 'እርግጠኛ ነዎት ክፍያውን መተው ይፈልጋሉ?',
    确定删除选中的商品吗: 'የተመረጠውን ንጥል ለመሰረዝ ያረጋግጡ።',
    确定提交: 'ማስረከብ አረጋግጥ',
    确定要清空历史记录吗: 'ታሪክን ማጽዳት ይረጋገጥ?',
    确定要取消支付这笔订单吗: 'እርግጠኛ ነዎት ለዚህ ትዕዛዝ ክፍያ መሰረዝ ይፈልጋሉ?',
    确定已输入接收退款的银行卡号和户名: 'ተመላሽ ገንዘቡን ለመቀበል የባንክ ካርድ ቁጥር እና የመለያ ስም ማስገባትዎን ያረጋግጡ',
    确定支付: 'ክፍያ ያረጋግጡ',
    确认订单: 'ትዕዛዝን አረጋግጥ',
    确认密码: 'የይለፍ ቃል አረጋግጥ',
    确认收货: 'መላክን ያረጋግጡ',
    确认收货成功: 'በተሳካ ሁኔታ ቀርቧል',
    确认已付款: 'አስቀድሞ ተከፍሏል።',
    如果您当前的手机号已无法使用请联系客服: 'ስልክ ቁጥርዎ የማይገኝ ከሆነ፣ እባክዎን በጊዜው የደንበኞችን አገልግሎት ያግኙ።',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持jpg和png格式文件图片不大于3M: 'ከላይ ባሉት መንገዶች የሚከፈል ከሆነ፣ እባክዎ ተዛማጅ የሆኑ ቫውቸሮችን ይስቀሉ፣ ለምሳሌ ቅጽበታዊ ገጽ እይታዎችን ያስተላልፉ (jpg ፣ png የሚደገፍ ፣ እያንዳንዳቸው ከ 3M ያልበለጠ)።',
    扫码或者点击去支付: 'የQR ኮድን ይቃኙ ወይም "ወደ ክፍያ ይሂዱ" ን ጠቅ ያድርጉ',
    删除: 'ሰርዝ',
    删除成功: 'በተሳካ ሁኔታ ተሰርዟል',
    删除订单: 'ትዕዛዝ ሰርዝ',
    删除订单成功: 'ትዕዛዝ በተሳካ ሁኔታ ተሰርዟል።',
    删除失效商品: 'ልክ ያልሆኑ ንጥሎችን ሰርዝ',
    商品: 'ምርት',
    商品单价: 'ነጠላ ዋጋ',
    商品分类: 'የምርት ምድብ',
    商品合计: 'ጠቅላላ',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询: 'የመመለሻ አድራሻው ጥያቄው ተቀባይነት ካገኘ በኋላ በጽሑፍ መልእክት ይገለጻል ወይም በማመልከቻው መዝገቦች ውስጥ ማረጋገጥ ይችላሉ።',
    商品详情: 'የምርት ዝርዝሮች',
    商品总额: 'አጠቃላይ ድምር',
    商品总价: 'አጠቃላይ ድምር',
    上传成功: 'በተሳካ ሁኔታ ተጭኗል',
    上传凭证: 'ቫውቸሮችን ስቀል',
    上传头像图片大小不能超过2MB: 'የተሰቀለው የአቫታር ምስል መጠን ከ2ሜባ መብለጥ አይችልም!',
    上传头像图片只能是JPG格式: 'የጄፒጂ አምሳያ ምስሎች ብቻ ይፈቀዳሉ።',
    上传中: 'በመስቀል ላይ...',
    设为默认: 'እንደ ነባሪ አዘጋጅ',
    设为默认收货地址: 'እንደ ነባሪ የመላኪያ አድራሻ አዘጋጅ',
    申请时间: 'የመተግበሪያ ጊዜ',
    申请信息: 'የመተግበሪያ መረጃ',
    剩余X件: 'የቀሩ {num} ቁርጥራጮች',
    失效宝贝: 'ጊዜ ያለፈባቸው እቃዎች',
    时: 'ሰአት',
    实付金额: 'የተከፈለበት መጠን',
    实付款: 'በትክክል ተከፍሏል።',
    是否删除地址: 'አድራሻውን ለመሰረዝ ያረጋግጡ።',
    是否选择该地址: 'አድራሻውን መምረጥዎን ያረጋግጡ',
    收货地址: 'የመላኪያ አድራሻ',
    收货人: 'ተቀባዩ',
    手机号: 'ስልክ ቁጥር',
    手机号码: 'ስልክ ቁጥር',
    手机验证: 'ስልክ ቁጥር ያረጋግጡ',
    手机验证码: 'የኤስኤምኤስ ማረጋገጫ ኮድ',
    首页: 'ቤት',
    售后单号: 'ከሽያጭ በኋላ ትእዛዝ ቁጥር',
    售后方式: 'ከሽያጭ በኋላ ዘዴ',
    售后服务: 'ከሽያጭ በኋላ አገልግሎት',
    售后商品: 'ከሽያጭ በኋላ ምርት',
    售后申请: 'ከሽያጭ በኋላ ጥያቄ',
    售后申请成功: 'ከሽያጭ በኋላ ማመልከቻው የተሳካ ነው።',
    售后申请提交成功: 'ከሽያጭ በኋላ በተሳካ ሁኔታ ተጠይቋል',
    售后完成: 'ከሽያጭ በኋላ ተጠናቅቋል',
    售后详情: 'ከሽያጭ በኋላ ዝርዝሮች',
    售后信息: 'ከሽያጭ በኋላ መረጃ',
    售后专员可能与您电话沟通请保持手机畅通: 'ከሽያጭ በኋላ ስፔሻሊስት በስልክ ሊያነጋግርዎት ይችላል። እባክዎን መስመሮችዎን ክፍት ያድርጉት።',
    搜索: 'ፈልግ',
    提交: 'አስገባ',
    提交订单: 'ትእዛዝ አስገባ',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通: 'ጥያቄውን ካቀረቡ በኋላ፣ ከሽያጭ በኋላ ስፔሻሊስት በስልክ ሊያነጋግርዎት ይችላል። እባክዎን መስመሮችዎን ክፍት ያድርጉት።',
    提交申请: 'ጥያቄ አስገባ',
    提示: 'አስታዋሽ',
    天: 'ቀን',
    添加超时: 'መጨመር ጊዜው አልፎበታል።',
    添加成功: 'በተሳካ ሁኔታ ታክሏል።',
    添加失败库存不足: 'ማከል አልተሳካም ፣ በቂ ያልሆነ ክምችት',
    添加新地址: 'አዲስ አድራሻ ያክሉ',
    条回复: '* መልስ',
    通过BCEL银行完成支付后务必返回当前页面确认支付是否成功: 'ክፍያውን በ BCEL ባንክ በኩል ካጠናቀቁ በኋላ ክፍያው የተሳካ መሆኑን ለማረጋገጥ ወደ የአሁኑ ገጽ መመለስዎን ያረጋግጡ።',
    头像: 'አምሳያ',
    图片上传成功: 'ምስል በተሳካ ሁኔታ ተሰቅሏል።',
    图片上传失败请重试: 'ምስል መጫን አልተሳካም፣ እባክዎ እንደገና ይሞክሩ',
    退出登录: 'ዛግተ ውጣ',
    退货处理成功后退款金额将原路返回到您的支持账户中: 'ተመላሽ ገንዘቡ በተሳካ ሁኔታ ከተሰራ ወደተከፈለበት መለያዎ ይደርሳል።',
    退货金额: 'የተመላሽ ገንዘብ መጠን',
    退货类型: 'የመመለሻ አይነት',
    退货数量: 'የመመለሻ ብዛት',
    退货原因: 'የመመለሻ ምክንያቶች',
    退款成功: 'በተሳካ ሁኔታ ተመላሽ ተደርጓል',
    退款金额: 'የተመላሽ ገንዘብ መጠን',
    退款失败: 'ገንዘብ መመለስ አልተሳካም።',
    退款说明: 'የተመላሽ ገንዘብ መመሪያዎች',
    退款完成: 'ተመላሽ ገንዘብ ተጠናቅቋል',
    退款原因: 'የተመላሽ ገንዘብ ምክንያት',
    退款中: 'ገንዘብ ተመላሽ ማድረግ',
    完成: 'ጨርስ',
    忘记密码: 'የይለፍ ቃል እርሳ',
    微信: 'WeChat',
    为了帮助您更好的解决问题请上传图片: 'ችግሩን በተሻለ ሁኔታ እንዲፈቱ ለማገዝ እባክዎን ስዕሎችን ይስቀሉ ።',
    为确认身份我们需验证您的安全手机: 'የእርስዎን ማንነት ለማረጋገጥ፣ የእርስዎን ስልክ ቁጥር ማረጋገጥ አለብን።',
    未登录: 'አልገባም።',
    未读消息: 'ያልተነበበ',
    未发货: 'አልተላከም።',
    未添加收货地址: 'የመላኪያ አድራሻ አልታከለም።',
    未选择地址或没有地址: 'አድራሻ አልተመረጠም ወይም አድራሻ የለም።',
    温馨提示: 'ሞቅ ያለ ምክሮች',
    文件过大: 'ፋይሉ በጣም ትልቅ ነው።',
    我的: 'የእኔ',
    我的订单: 'የእኔ ትዕዛዝ',
    我是新用户: 'አዲስ ተጠቃሚ ነኝ',
    我要留言: 'መልእክት መተው እፈልጋለሁ።',
    我要退货退款: 'ተመላሽ እና ገንዘብ ተመላሽ እፈልጋለሁ',
    我要退款: 'ተመላሽ እፈልጋለሁ',
    物流单号: 'ፈጣን ቁጥር：',
    下单结算: 'ሰፈራ',
    下一步: 'ቀጥሎ',
    线下支付: 'ከመስመር ውጭ ክፍያ',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货: 'ከመስመር ውጭ የሚከፈል ትዕዛዝ የመስመር ላይ ከሽያጭ በኋላ አገልግሎትን አይደግፍም። ከፈለጉ እባክዎን ከሻጩ ጋር ይገናኙ። ለሚከፈልባቸው ትዕዛዞች ክፍያው ለሻጩ እና ለጭነት እስኪደርስ ድረስ እባክዎ በትዕግስት ይጠብቁ!',
    详细地址: 'ዝርዝር አድራሻ',
    消息通知: 'ማሳወቂያዎች',
    消息详情: 'የመልእክት ዝርዝሮች',
    销量高低: 'በሽያጭ ደርድር',
    销量最低: 'ዝቅተኛው ሽያጭ',
    销量最高: 'ከፍተኛ ሽያጭ',
    新密码: 'አዲስ የይለፍ ቃል',
    新密码不能和旧密码相同: 'አዲሱ የይለፍ ቃል ከቀድሞው የይለፍ ቃል ጋር አንድ አይነት ሊሆን አይችልም።',
    新增地址: 'አዲስ አድራሻ ጨምር',
    性别: 'ጾታ',
    姓: 'የአያት ስም',
    修改: 'አስተካክል።',
    修改成功: 'በተሳካ ሁኔታ ተለውጧል',
    修改密码: 'የሚስጥር ቁልፍ ይቀይሩ',
    修改邮箱: 'ኢሜል ቀይር',
    需勾选协议: 'ስምምነቱ ላይ ምልክት ያድርጉ',
    选择: 'ይምረጡ',
    选择成功: 'በተሳካ ሁኔታ ተመርጧል',
    选择新地址: 'አዲስ አድራሻ ይምረጡ',
    选中地址: 'አድራሻ ተመርጧል',
    验证: 'ማረጋገጥ',
    验证码: 'የማረጋገጫ የሚስጥር ቁጥር',
    验证码登录: 'በማረጋገጫ ኮድ ይግቡ',
    已读消息: 'አንብብ',
    已发货: 'ተልኳል።',
    已关闭: 'ዝግ',
    已取消: 'ተሰርዟል።',
    已取消删除: 'ያልተሰረዘ',
    已完成: 'ተጠናቀቀ',
    已选择: 'ተመርጧል',
    已阅读并同意以下协议: 'የሚከተለውን ስምምነት አንብበው ተስማምተዋል።',
    隐私政策: 'የግላዊነት መመሪያ',
    应付总额: 'የሚከፈለው መጠን',
    用户服务协议: 'የተጠቃሚ አገልግሎቶች ስምምነት',
    用户服务协议及法律声明: 'የተጠቃሚ አገልግሎት ስምምነት እና የህግ መግለጫ',
    优惠金额: 'የቅናሽ መጠን',
    优惠卷抵扣: 'ቅነሳ',
    邮箱: 'ኢሜይል',
    邮箱手机号: 'ኢሜል / የሞባይል ስልክ ቁጥር',
    邮箱修改: 'የኢሜል አድራሻ ቀይር',
    邮箱验证: 'ኢሜል ያረጋግጡ',
    邮政编码: 'አካባቢያዊ መለያ ቁጥር',
    语言: 'ቋንቋ',
    原密码: 'ኦሪጅናል የይለፍ ቃል',
    运费: 'የማጓጓዣ ዋጋ',
    暂无订单信息: 'እስካሁን ምንም ትዕዛዞች የሉም።',
    暂无商品: 'ምንም ምርቶች የሉም።',
    暂无售后信息: 'ከሽያጭ በኋላ ምንም ትዕዛዞች የሉም።',
    长度在1到8个字符: 'ከ 1 እስከ 8 ቁምፊዎች ርዝመት',
    支付宝: 'አሊፓይ',
    支付成功: 'በተሳካ ሁኔታ ተከፍሏል።',
    支付订单: 'ለትዕዛዙ ይክፈሉ',
    支付方式: 'የክፍያ መንገድ',
    支付金额: 'የክፍያ መጠን',
    支付凭证: 'የክፍያ ቫውቸር',
    支付失败: 'ክፍያ አልተሳካም።',
    重新发送验证码: 'የማረጋገጫ ኮድ እንደገና ላክ',
    重新申请: 'እንደገና ያመልክቱ',
    注册: 'ተመዝገቢ',
    注册成功: 'በተሳካ ሁኔታ ይመዝገቡ',
    综合排序: 'አጠቃላይ ደረጃ',
    总价: 'ጠቅላላ ዋጋ',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件: 'ቢበዛ 5 ስዕሎችን ይስቀሉ (bmp፣ gif፣ jpg፣ png ወይም jpeg፣ እያንዳንዳቸው በ5M ውስጥ)።',
    最多上传五张: 'እስከ 5 ምስሎችን ይስቀሉ።',
    推荐商品: 'ምክሮች',
    共x件商品已选择x件: 'ጠቅላላ {num} ንጥሎች፣ {num2} ንጥሎች ተመርጠዋል',
    共x件商品: 'ጠቅላላ {num} ንጥሎች',
    x件: '{num}ቁርጥራጮች',
    绑定: 'ማሰር',
    绑定邮箱: 'ኢሜል ማሰር',
    分享店铺: 'አጋራ መደብር',
    分享至: 'ተካፈል',
    请再次输入新密码: 'እባክዎ አዲሱን የይለፍ ቃል እንደገና ያስገቡ',
    海报: 'ፖስተር',
    分享: 'አጋራ',
    推荐店铺给你: 'የገበያ ማዕከሉን ለእርስዎ ይምከሩ',
    链接: 'አገናኝ',


    至: 'ወደ',
    币种: 'ምንዛሪ',
    变动理由: 'የለውጥ ምክንያት',
    不记得: 'አታስታውስ',
    充值: 'መሙላት',
    打款凭证: 'የክፍያ ቫውቸር',
    打款说明: 'የክፍያ መመሪያዎች',
    打款信息: 'የክፍያ መረጃ',
    打款状态: 'የክፍያ ሁኔታ',
    待打款: 'መውጣት በመጠባበቅ ላይ',
    待审核: 'ያልተገመገመ',
    到账方式: 'የተቀማጭ ገንዘብ ዘዴ',
    到账银行卡号: 'ባንክ መቀበያ',
    冻结中: 'ማቀዝቀዝ',
    国家: 'ብሄር',
    获取验证码: 'የማረጋገጫ ኮድ አግኝ',
    记得: 'አስታውስ',
    记录时间: 'የቀረጻ ጊዜ',
    拒绝打款: 'ለመክፈል እምቢ ማለት',
    开户人: 'መለያ ያዥ',
    开户支行: 'የባንክ ቅርንጫፍ መስጠት',
    可提现: 'ማስወጣት አለ',
    可提现金额: 'ሊወጣ የሚችል መጠን',
    立即提现: 'ወዲያውኑ ውጣ',
    流水号: 'መለያ ቁጥር.',
    没有适合此币种的银行卡请先添加: 'ለዚህ ገንዘብ የሚመች የባንክ ካርድ የለም፣ እባክዎን መጀመሪያ ያክሉት',
    没有收款码请先添加: 'የክፍያ ኮድ የለም፣ እባክዎ መጀመሪያ ያክሉት',
    每天最多提现x次单次最高提现金额x: 'በቀን ከፍተኛው የመውጣት ብዛት፣ በቀን ከፍተኛው የማውጣት መጠን*',
    您是否记得账号当前使用的支付密码: 'በአሁኑ ጊዜ በመለያህ ጥቅም ላይ የዋለውን የክፍያ ይለፍ ቃል ታስታውሳለህ?',
    您未设置支付密码请先设置: 'የክፍያ ይለፍ ቃል አላዘጋጁም፣ እባክዎ መጀመሪያ ያዘጋጁት',
    钱包余额: 'Wallet Balance',
    请输入开户人: 'እባክህ መለያውን አስገባ',
    请输入开户支行: 'እባክህ ሰጪ የባንክ ቅርንጫፍ አስገባ',
    请输入收款名称: 'እባክዎ የክፍያውን ስም ያስገቡ',
    请输入提现金额: 'እባክዎ የማስወጫ መጠን ያስገቡ',
    请输入新支付密码6位数字: 'እባክዎ አዲስ የክፍያ ይለፍ ቃል፣ 6 አሃዝ ያስገቡ',
    请输入新支付密码: 'እባክዎ አዲስ የክፍያ ይለፍ ቃል ያስገቡ',
    请输入银行卡号: 'እባክዎ የባንክ ካርድ ቁጥሩን ያስገቡ',
    请输入原支付密码: 'እባክዎ የመጀመሪያውን የክፍያ ይለፍ ቃል ያስገቡ',
    请输入支付密码6位数字: 'እባክዎ የክፍያ ይለፍ ቃል፣ 6 አሃዝ ያስገቡ',
    请输入支付密码: 'እባክዎ የክፍያ ይለፍ ቃል ያስገቡ',
    请选择币种: 'እባክዎ ምንዛሬ ይምረጡ',
    请选择大洲: 'እባክህ አህጉር ምረጥ',
    请选择结束时间: 'እባክዎ የማለቂያ ቀን ይምረጡ',
    请选择开始时间: 'እባክዎ የመጀመሪያ ቀን ይምረጡ',
    请选择收款码类型: 'እባክዎ የክፍያ ኮድ አይነት ይምረጡ',
    请选择银行: 'እባክህ ባንክ ምረጥ',
    请选择银行名称: 'እባክህ የባንክ ስም ምረጥ',
    请再次输入新支付密码: 'እባክዎ አዲሱን የክፍያ ይለፍ ቃል እንደገና ያስገቡ',
    请再次输入支付密码: 'እባክዎ የክፍያ ይለፍ ቃል እንደገና ያስገቡ',
    去设置: 'ወደ ቅንብሮች ሂድ',
    全部提现: 'ሁሉንም አስወጣ',
    确定删除收款码吗: 'እርግጠኛ ነዎት የክፍያ ኮድ እየሰረዙት ነው?',
    确定删除银行卡吗: 'ባንክ ካርዱን መሰረዝህን እርግጠኛ ነህ',
    确定提现: 'ማስወጣቱን ያረጋግጡ',
    确认打款: 'ክፍያ ያረጋግጡ',
    确认新密码: 'እባክዎ አዲስ የክፍያ ይለፍ ቃል እንደገና ያስገቡ',
    确认支付密码: 'የክፍያ ይለፍ ቃል አረጋግጥ',
    上传的收款二维码需要清晰无遮挡: 'የተሰቀለው የክፍያ QR ኮድ ግልጽ እና ያልተደናቀፈ መሆን አለበት',
    审核备注: 'አስተያየቶችን ይገምግሙ',
    审核不通过: 'ግምገማውን ማለፍ አልተሳካም',
    审核结果: 'የግምገማ ውጤት',
    审核通过: 'ግምገማ አልፏል',
    审核信息: 'የኦዲት መረጃ',
    时间: 'ጊዜ',
    实际到账金额: 'የተቀበለው ትክክለኛ መጠን',
    收款方式: 'የመክፈያ ዘዴ',
    收款管理: 'የደረሰኝ አስተዳደር',
    收款码: 'የደረሰኝ ኮድ',
    收款码管理: 'የደረሰኝ ኮድ አስተዳደር',
    收款码类型: 'የደረሰኝ ኮድ አይነት',
    收款名称: 'የደረሰኝ ስም',
    收款账号: 'የደረሰኝ መለያ',
    手续费: 'የማስወጣት ክፍያ',
    提现: 'ማውጣት',
    提现币种: 'የማውጣት ገንዘብ',
    提现成功: 'የተሳካ መውጣት',
    提现记录: 'የመውጣት መዝገብ',
    提现金额: 'የመውጣት መጠን',
    提现失败: 'ማውጣት አልተሳካም።',
    提现时间: 'የምስወጣበት ጊዜ',
    提现手续费: 'የማስወጣት ክፍያ',
    提现详情: 'የመውጣት ዝርዝሮች',
    提现中: 'ማውጣት',
    提现状态: 'የመውጣት ሁኔታ',
    添加收款码: 'የክፍያ ኮድ አክል',
    添加银行卡: 'የባንክ ካርድ ያክሉ',
    退款: 'ተመላሽ ገንዘብ',
    忘记支付密码: 'የክፍያ የይለፍ ቃልህን ረሳኸው?',
    尾号: 'የጅራት ቁጥር',
    我的钱包: 'የእኔ ቦርሳ',
    我的余额: 'ሚዛኔ',
    详情: 'ዝርዝሮች',
    消费: 'ፍጆታ',
    新支付密码: 'አዲስ የክፍያ ይለፍ ቃል',
    修改支付密码: 'የክፍያ ይለፍ ቃል ቀይር',
    选择收款方式: 'የመክፈያ ዘዴን ይምረጡ',
    银行卡: 'የባንክ ካርድ',
    银行卡管理: 'የባንክ ካርድ አስተዳደር',
    银行卡号: 'የካርድ ቁጥር.',
    银行名称: 'የባንክ ስም',
    余额记录: 'የሒሳብ መዝገብ',
    余额数据: 'የሂሳብ ሚዛን',
    原支付密码: 'የመጀመሪያው የክፍያ ይለፍ ቃል',
    暂无数据: 'ምንም ውሂብ የለም',
    支持币种: 'የሚደገፉ ምንዛሬዎች',
    支付密码: 'የክፍያ ይለፍ ቃል',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡: 'ጠቃሚ ምክር፡ አንድ ምስል ብቻ መጫን ይቻላል፣ የስዕሉ መጠን ከ5M መብለጥ የለበትም፣ እና jpg፣ png እና jpeg ቅርጸቶች ይደገፋሉ። የተሰቀለው የክፍያ QR ኮድ ግልጽ እና ያልተደናቀፈ መሆን አለበት።',
    账户余额: 'የሂሳብ ቀሪ ሒሳብ',
    钱包服务: 'የኪስ ቦርሳ አገልግሎት',
    每天最多提现x次单次最高提现金额xx最低提现金额xx: 'በቀን ከፍተኛው የማውጣት መጠን {num} ጊዜ ነው፣ ከፍተኛው የማውጣት መጠን በአንድ ጊዜ {icon}{money} ነው፣ እና ዝቅተኛው የማውጣት መጠን {icon}{money2} ነው።',
    确认: 'ማረጋገጥ',
    输入金额超过可提现余额: 'የግብአት መጠኑ ሊወጣ ከሚችለው ቀሪ ሂሳብ ይበልጣል',
    日期选择: 'የቀን ምርጫ',
    备注: 'አስተያየት',
    当前没有余额记录: 'በአሁኑ ጊዜ ምንም የሂሳብ መዝገብ የለም!',
    当前没有提现记录: 'በአሁኑ ጊዜ የመውጣት መዝገብ የለም!',
    还没有绑定银行卡: 'እስካሁን ምንም የባንክ ካርድ አልተያዘም!',
    还没有添加收款码: 'እስካሁን ምንም የክፍያ ኮድ አልታከለም!',
    请上传收款码: 'እባክዎ የክፍያ ኮዱን ይስቀሉ።',
    请输入收款码名称: 'እባክዎ የክፍያ ኮድ ስም ያስገቡ',
    暂无银行卡: 'እስካሁን ምንም የባንክ ካርድ የለም።',
    余额: 'ሚዛን',
    重置: 'ዳግም አስጀምር',
    暂无收款码: 'እስካሁን ምንም የክፍያ ኮድ የለም።',
    保障支付安全: 'የክፍያ ደህንነት ያረጋግጡ',
    打款成功: 'ክፍያ ተሳክቷል።',
    请选择收款方式: 'እባክዎ የመክፈያ ዘዴን ይምረጡ',
    收款类型: 'የክፍያ ዓይነት',
    编辑银行卡: 'የባንክ ካርድ ያርትዑ',
    编辑收款码: 'የክፍያ ኮድ ያርትዑ',
    收款码名称: 'የክፍያ ኮድ ስም',
    订单ID: 'የትእዛዝ ቁጥር',
    如果您已支付成功请点击确认支付按钮: 'ክፍያዎ የተሳካ ከሆነ እባክዎ "ክፍያን ያረጋግጡ" የሚለውን ቁልፍ ይጫኑ',
    确认支付: 'ክፍያን ያረጋግጡ',
    支付遇到问题: 'ችግሮች ያጋጥሙ',
    对不起您访问的店铺可能已被删除名称被更改或不存在: 'ይቅርታ፣ የጎበኘኸው መደብር ተሰርዞ ሊሆን ይችላል፣ ስሙ ተቀይሯል ወይም ላይኖር ይችላል...',
    卢比支付: 'IDR ክፍያ',
    比索支付: 'PHP ክፍያ',
    泰铢支付: 'THB ክፍያ',
    越南盾支付: 'VND ክፍያ',
    银行卡编码: 'የባንክ ኮድ',
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0": "{currencyUnit}{money}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1": "የመጀመሪያው መደበኛ ክብደት{num}kg{currencyUnit}{money}，ተጨማሪ ክብደት{num2}kg{currencyUnit}{money2}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2": "የመጀመሪያ ፓርሴል{num3}ቁርጥራጮች{currencyUnit}{money3}，ተጨማሪ እሽጎች{num4}ቁርጥራጮች{currencyUnit}{money4}",
    暂无配送地区: 'እስካሁን ምንም የመላኪያ ቦታ የለም።',
    该商品在当前地区暂不支持配送: 'ይህ ምርት አሁን ባለው ክልል ውስጥ መላክን አይደግፍም።',
    当前商品仅支持以下地区发货如有疑问请联系店铺:
        'አሁን ያሉት ምርቶች ለሚከተሉት አካባቢዎች ማድረስ ብቻ ነው የሚደግፉት ማንኛውም አይነት ጥያቄ ካሎት እባክዎን መደብሩን ያግኙ',
    运费计价说明: 'የጭነት ዋጋ መመሪያዎች',
    发货地: 'Shipping Place',
    配送至: 'ማድረስ',
    打款中: 'ክፍያ በሂደት ላይ ነው።',
    未添加地址请先添加: 'ምንም አድራሻ አልተጨመረም እባክህ መጀመሪያ ጨምረው',
    请输入大于1的正整数: 'እባክዎን ከ1 በላይ የሆነ አወንታዊ ኢንቲጀር ያስገቡ',
    设置密码: 'የይለፍ ቃል አዘጋጅ',
    登录密码: 'የመግቢያ የይለፍ ቃል',
    长度在8到16个字符: 'ከ 8 እስከ 16 ቁምፊዎች ርዝመት',
    请输入旧密码: 'እባክህ የይለፍ ቃሉን አስገባ',
    必须包含两种字符: 'ሁለት ቁምፊዎችን መያዝ አለበት',
    当前仅支持xxx的金额: 'በአሁኑ ጊዜ ከ {currency} {min}-{currency} {max} የገንዘብ መጠን ብቻ ይደግፋል',
    查看运费: 'የማጓጓዣ ወጪዎችን ይመልከቱ',
    运费方式: 'የማጓጓዣ ዘዴ',
    价格: 'ዋጋ',
    请选择配送方式: 'እባክዎ የመላኪያ ዘዴ ይምረጡ',
    配送方式: 'የማስረከቢያ ዘዴ',
    配送费说明: 'የመላኪያ ክፍያ መግለጫ',
    我明白了: 'ገባኝ',
    若多个商品选用同一个运费模板则运费是按同一个运费方式的规则进行计算: 'ብዙ ምርቶች አንድ አይነት የእቃ ማጓጓዣ አብነት የሚጠቀሙ ከሆነ, ጭነቱ በተመሳሳዩ የጭነት ዘዴ ደንቦች መሰረት ይሰላል.',
    若多个商品不是同一个运费模板则运费是多个模板的价格之和: 'ብዙ ምርቶች ተመሳሳይ የመላኪያ ክፍያ አብነት ከሌላቸው፣ የመላኪያ ክፍያው የበርካታ አብነቶች ዋጋ ድምር ነው።',
    快捷登录登录即表示同意协议: 'በመግባት በስምምነቱ ተስማምተዋል።',
    促销广告: 'የማስተዋወቂያ ማስታወቂያ',
    最近新品: 'አዲስ ምርቶች',
    更多: 'ተጨማሪ',
    设置成功: 'ማዋቀር ተሳክቷል።',
    更多商品:'ተጨማሪ ምርቶች',


    //     1.6
    我的优惠券:'የእኔ ቅናሽ ኩፖን',
    历史记录:'የታሪክ መዝገብ',
    已使用:'ጥቅም ላይ የዋለ',
    已过期:'ጊዜው አልፎበታል።',
    优惠券:'ኩፖን',
    领取:'ተቀበል',
    已领取:'ተቀብሏል',
    有效期:'የማረጋገጫ ጊዜ：',
    活动优惠详情:'የክስተት ቅናሽ ዝርዝሮች',
    可用商品:'የሚገኙ እቃዎች',
    单独购买:'የመጀመሪያ ዋጋ',
    发起拼团:'የቡድን ዋጋ',
    拼团价:'የቡድን ዋጋ',
    可参与的拼团:'የሚገኙ የቡድን ግዢዎች',
    '再邀X人即可拼单成功':'ለተሳካ ትዕዛዝ ከ {num} ተጨማሪ ሰዎች ጋር ያካፍሉ። >',
    拼单详情:'የቡድን ግዢ ዝርዝሮች',
    待分享:'ለማጋራት በመጠባበቅ ላይ',
    '若拼团失败，货款将原路退回':'የቡድን ግዢ ካልተሳካ, ክፍያው ይመለሳል.',
    促销价:'የሽያጭ ዋጋ',
    距离结束时间:'End in ',
    满XX减XX:"በ {currencyUnit}{priceStart} እርግዝና {currencyUnit}{reducePrice}{couponType==2?'%':''} እወራርድ",
    满XX减X:'በ {currencyUnit}{priceStart} እርግዝና {reducePrice}% እወራርድ',
    分享好友X人成团立省X:'የቡድን ግዢ ለ<span style="color: #8D4BBB;">{num}</span>ሰዎች፣ ቁጠባ<span style="color: #8D4BBB;">{currencyUnit}{groupPrice}</span>',
    分享好友:'ለጓደኞችዎ ያካፍሉ',
    '这些人正在拼，立即参与 ':'የቡድን ግዢ በሂደት ላይ ነው፣ አሁን ይቀላቀሉ ',
    满x可用:'ከ {currencyUnit}{priceStart} በላይ ለሆኑ ግዢዎች ይገኛል።',
    这些人正在拼立即参与:'የቡድን ግዢ በሂደት ላይ ነው፣ አሁን ይቀላቀሉ ',
    限领x张:'በተጠቃሚ ገደብ {receiveCount} ኩፖኖች',
    满XX送赠品:'ከ {currencyUnit}{priceStart} በላይ ለሆኑ ትዕዛዞች ነፃ ስጦታ',
    去使用:'ለመጠቀም',
    销量:'ሽያጭ',
    赠品:'ተስፋ መቁረጥ',
    限时优惠:'የተወሰነ ጊዜ አቅርቦት',
    距结束:'End in ',
    拼团好物:'ቦታዎችን ይጠቀሙ',
    当前优惠券可以购买以下商品:'የአሁኑ ኩፖን የሚከተሉትን ምርቶች መግዛት ይችላል:',
    以下商品:'የሚከተሉትን አቀማመጥ',
    满XX减XX可用:"በ {currencyUnit}{priceStart} እርግዝና {currencyUnit}{reducePrice}እወራርድ",
    满XX减X可用:'በ {currencyUnit}{priceStart} እርግዝና {reducePrice}% እወራርድ',
    再买x可用:'አሁንም መግዛት ያስፈልጋል {currencyUnit}{difPrice}',
    合计:'ጠቅላላ',
    优惠:'ቅናሽ',
    去购物车:'የግዢ ጋሪ',
    拼团:'ቦታዎችን ይጠቀሙ',
    满XX可用:'ከ {currencyUnit}{priceStart} በላይ ለሆኑ ግዢዎች ይገኛል።',
    满减满赠:'ሙሉ ቅናሽ/ሙሉ ስጦታ',
    成团发货:'ለማድረስ ይጠብቁ',
    还差xx人成团:'አሁንም በተሳካ ሁኔታ ትእዛዝ ለመስጠት <span style="color: #FF4545;">{num}</span>ሰዎች ያስፈልጋሉ።',
    拼团结束:'የቡድን ግዢ ያበቃል',
    拼团成功:'የቡድን ግዢ ተሳክቷል።',
    有成员未付款请等待付款成团:'አንድ ሰው አልከፈለም እባክህ ክፍያ ጠብቅ',
    我正在参与的拼团:'በቡድን ግዢ ውስጥ መሳተፍ',
    满减:'ሙሉ ቅናሽ',
    满赠:'ሙሉ ስጦታ',
    凑单:'አንድ ላይ ትዕዛዝ ይስጡ',
    切换其他优惠:'ሌላ ቅናሽ ይምረጡ',
    超过最大购买数:'ከፍተኛ የግዢዎች ብዛት አልፏል',
    商品限购x件:'{num} ቁርጥራጮችን ይገድቡ',
    参与拼团:'መቀላቀል',
    结束:'结束',
    您还没有优惠券:'ኩፖን የለዎትም።',
    优惠券历史记录:'የታሪክ መዝገብ',
    拼单失败:'የቡድን ግዢ አልተሳካም።',
    免费:'ፍርይ',
    已赠完:'ከመጋዘን ተጠናቀቀ',
    此拼团已满请选择其他拼团:'ይህ የቡድን ግዢ ሞልቷል፣ እባክዎ ሌላ የቡድን ግዢ ይምረጡ',
    以下商品正在限时优惠当中:'በሚከተሉት ምርቶች ላይ የተወሰነ ጊዜ ቅናሽ',
    以下商品正在限时拼团当中:'የሚከተሉትን ምርቶች ለተወሰነ ጊዜ የቡድን ግዢ',
    再买X可减X:'ሌላ {currencyUnit}{difPrice} ይግዙ እና {currencyUnit}{reducePrice} ቅናሽ ያግኙ',
    已领完:"ጥቅም ላይ የዋለ",
    活动未开始:'ዝግጅቱ አልተጀመረም።',

    已满X可减X:'{currencyUnit}{difPrice} ወይም ከዚያ በላይ ሲያወጡ {currencyUnit}{reducePrice} ቅናሽ ያግኙ',
    已满X可减XX:'{currencyUnit}{difPrice} ወይም ከዚያ በላይ ሲያወጡ {reducePrice}% ቅናሽ ያግኙ',

    再买X可减XX:'ሌላ {currencyUnit}{difPrice} ይግዙ እና {reducePrice}% ቅናሽ ያግኙ',
    再买X送赠品:'ሌላ {currencyUnit}{difPrice} ይግዙ እና ነፃ ስጦታ ያግኙ',
    已满X送赠品:'ስጦታዎች ተሰጥተዋል።',
    缺少库存: 'የአክሲዮን እጥረት',
    限X件:'የግዢ ገደብ {num} ቁርጥራጮች',


    退货积分:'ነጥቦችን መመለስ',
    积分:'የተዋሃደ',
    用户注册:'የተጠቃሚ ምዝገባ',
    手动增加:'በእጅ መጨመር',
    积分兑换退款:'ነጥቦች ተመላሽ ገንዘብ',
    下单购物:'ለግዢ ትዕዛዝ ያስቀምጡ',
    过期积分:'ጊዜያቸው ያለፈባቸው ነጥቦች',
    兑换商品:'ዕቃዎችን ማስመለስ',
    手动减少:'በእጅ መቀነስ',
    订单退款:'ተመላሽ ገንዘብ ያዝዙ',
    积分明细:'ነጥቦች ዝርዝሮች',
    已兑:'አስቀድሞ ተወስዷል',
    积分商城:'ነጥቦች Mall',
    规则:'ደንብ',
    可用积分:'ነጥቦች ይገኛሉ',
    冻结积分:'የማቀዝቀዝ ነጥቦች',
    我的兑换:'የእኔ ልውውጥ',
    售后:'ከሽያጭ በኋላ',
    分销中心:'ማከፋፈያ ማዕከል',
    立即兑换:'አሁን ያስመልሱ',
    已兑完:'አስቀድሞ ተወስዷል',
    "积分不足，无法兑换":'በቂ ያልሆኑ ነጥቦችን ማስመለስ አይቻልም',
    兑换数量:'የልውውጥ መጠን',
'限兑*件':'የተገደበ መቤዠት{num} ንጥሎች',
    积分抵扣:'የነጥብ ቅነሳ',
'共*分':'ጠቅላላ {num} ነጥቦች',
    全部订单:'ሁሉም ትዕዛዞች',

兑换须知:'የመቤዠት መመሪያዎች',
    已达兑换上限:'የመቤዠት ገደቡ ላይ ደርሷል',
    我的积分:'የእኔ ውጤቶች',
    积分规则:'ነጥቦች ደንቦች',
    兑换时间:'የመቤዠት ጊዜ',
    我的佣金:'የእኔ ኮሚሽን',
    佣金数据:'የኮሚሽኑ መረጃ',
    分销申请协议:'የስርጭት ማመልከቻ ስምምነት',
    申请成为分销员:'አከፋፋይ ለመሆን ያመልክቱ',
    "已申请，请等待审核":'አስቀድሞ ተተግብሯል፣ እባክዎ ለግምገማ ይጠብቁ',
    "审核拒绝，继续申请":'በግምገማ ውድቅ ተደርጓል፣ ማመልከትዎን ይቀጥሉ',
    可提佣金:'የማስወጣት ኮሚሽን',
    冻结佣金:'እግድ ኮሚሽን',
    分销订单:'የስርጭት ቅደም ተከተል',
    佣金明细:'የኮሚሽኑ ዝርዝሮች',
    我的下级:'የበታቾቼ',
    推广商品:'ምርቶችን ያስተዋውቁ',
    推广店铺:'ሱቅ ያስተዋውቁ',
    暂无记录:'ምንም መዝገቦች የሉም',
    一级佣金收入:'የመጀመሪያ ደረጃ የኮሚሽኑ ገቢ',
    一级佣金退款:'የመጀመሪያ ደረጃ ኮሚሽን ገንዘብ ተመላሽ ማድረግ',
    二级佣金收入:'የሁለተኛ ደረጃ ኮሚሽን ገቢ',
    二级佣金退款:'የሁለተኛ ደረጃ ኮሚሽን ተመላሽ ገንዘብ',
    下单用户:'ተጠቃሚን ማዘዝ',
    订单总价:'አጠቃላይ ዋጋን ይዘዙ',
    分销利润层级:'የስርጭት ደረጃ',
    结算状态:'የሰፈራ ሁኔታ',
    佣金:'ኮሚሽን',
    一级:'ደረጃ 1',
    二级:'ደረጃ 2',
    已结算:'ተቀምጧል',
    请输入用户昵称或手机号:'እባክዎ የተጠቃሚ ቅጽል ስም ወይም የሞባይል ስልክ ቁጥር ያስገቡ',
    二级团队:'ሁለተኛ ደረጃ ቡድን',
    分享链接:'አገናኝ አጋራ',
    可推广至:'ወደ ሊራዘም ይችላል።',
    分享最高可赚x:'ሼር በማድረግ እስከ {icon}{num} ገቢ ማግኘት ይችላሉ።',
    分享推广:'ሼር በማድረግ ያስተዋውቁ',
    用户昵称或手机号:'የተጠቃሚ ቅጽል ስም ወይም የሞባይል ስልክ ቁጥር',
    商品名称:'የምርት ስም',
    最高可得:'ከፍተኛው ይገኛል።',
    可获得积分:'የተገኙ ነጥቦች',
    总获佣金:'የተገኘው ጠቅላላ ኮሚሽን',

    佣金详情:'የኮሚሽኑ ዝርዝሮች',
    已退款:'ተመላሽ ተደርጓል',
    请输入商品名称:'እባክዎ የምርት ስም ያስገቡ',
    请选择银行卡: 'እባክዎ የባንክ ካርድ ይምረጡ',
    请选择收款码: 'እባክዎ የክፍያ ኮድ ይምረጡ',
    不能超过最小提现额度:'ዝቅተኛው የማውጣት መጠን ሊበልጥ አይችልም!',
    房间号已失效请联系工作人员:'የክፍል ቁጥሩ ጊዜው አልፎበታል፣ እባክዎን ሰራተኞቹን ያግኙ!',

    货柜商品:'የእቃ መያዢያ እቃዎች',
    该浏览器不支持蓝牙:'ይህ አሳሽ ብሉቱዝ አይደግፍም, እባክዎን ለመክፈት አገናኙን ወደ Chrome, Samsung በይነመረብ አሳሽ ይቅዱ',
    iOS系统暂不支持售货机取货请使用Android系统扫码购物:'እባክዎን አንድሮይድ ሲስተሙን ለመገበያየት የQR ኮድን ይቃኙ፣እባክዎ ለመገበያየት "ወደ የገበያ ማዕከሉ ይሂዱ" የሚለውን ይጫኑ፣ እና አንድ የተወሰነ ሰው ወደ ደጃፍዎ ያደርሰዋል።',
    去商城首页:'ወደ የገበያ ማዕከሉ መነሻ ገጽ ይሂዱ',
    已售完:'ተሽጧል',
    蓝牙未连接:'ብሉቱዝ አልተገናኘም።',
    蓝牙已连接:'ብሉቱዝ ተገናኝቷል።',
    重试:'እንደገና ይሞክሩ',
    蓝牙未授权请打开蓝牙后重试:'ብሉቱዝ አልተፈቀደለትም፣ እባክዎ ብሉቱዝን ያብሩ እና እንደገና ይሞክሩ!',
    蓝牙未连接请选择蓝牙并配对:'ብሉቱዝ አልተገናኘም፣ እባክዎን ብሉቱዝን ይምረጡ እና ያጣምሩ!',
    连接:'መገናኘት',
    蓝牙已断开请重新连接:'ብሉቱዝ ተቋርጧል፣ እባክዎ እንደገና ያገናኙ',
    蓝牙连接中:'የብሉቱዝ ግንኙነት...',
    购买成功请取出商品若柜门未打开请尝试再次打开:'ግዢው የተሳካ ነው፣ እባክዎን ምርቱን ይውሰዱ! የካቢኔው በር ካልተከፈተ እባክዎ እንደገና ለመክፈት ይሞክሩ!',
    再次打开:'እንደገና ክፈት',
    普通订单:'መደበኛ ቅደም ተከተል',
    售货机:'የሽያጭ ማሽን',
    售货机已无货无法支付:'የሽያጭ ማሽኑ አልቆበታል እና ክፍያ መፈጸም አይቻልም.',
    订单来源:'የትዕዛዝ ምንጭ',
    连接失败请重新连接:'ግንኙነቱ አልተሳካም፣ እባክዎ እንደገና ያገናኙ',
    售罄:'ተሽጧል',
    '房间号/桌号':'የክፍል ቁጥር / የሰንጠረዥ ቁጥር',
    "柜门开启中···": "የካቢኔው በር ይከፈታል...",
    "微信不支持蓝牙，请点击":'WeChat አሳሽ ብሉቱዝን አይደግፍም፣ እባክዎን ጠቅ ያድርጉ',
    "选择在Google或Samsung Internet浏览器中打开":'በ Google<img src="https://saasimage.bawanli.com/users/2024-11-01/485b6a31-fe3e-4b07-b42a-71f3e2462ce2.png" style="width: 30px;height: 30px;margin:0 4px;"> ወይም ሳምሰንግ<img src="https://saasimage.bawanli.com/users/2024-11-01/805ed567-6a5b-47fc-a053-e1c2f50b67f6.png" style="width: 30px;height: 30px;margin:0 4px;"> ኢንተርኔት ማሰሻ ውስጥ ለመክፈት ይምረጡ,<span style="color: red">ከላይ ያለው አሳሽ ከሌልዎት፣ እባክዎ ለማውረድ ከታች ያለውን ሊንክ ይጫኑ፡ <a href="https://saasimage.bawanli.com/users/2024-11-01/Chrome.apk">【Chrome】</a><a href="https://saasimage.bawanli.com/users/2024-11-04/Samsung.apk">【Samsung Internet】</a></span>',
    "复制链接":'አገናኝ ቅዳ',
    "通过在线商城购买的订单，哇呗小店将配送上门，配送时间：06:00-23:00":'በኦንላይን የገበያ ማእከል በኩል የተገዙት ትዕዛዞች በዋቤይ ሱቅ በርዎ ላይ ይደርሳሉ፡ 06፡00-23፡00',
    "我已知晓":'አስቀድሜ አውቃለሁ'
}
//埃塞俄比亚
