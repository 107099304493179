import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import {Locale} from 'vant';
import 'vant/lib/index.css';
var jm = require("@/utils/public.js");
import router from './router/index'
import {Loading} from 'element-ui';


const apiDisposition = require('@/utils/env')
let apiNow = apiDisposition.environments.apiNow
// 引入英文语言包
import enUS2 from 'vant/es/locale/lang/en-US';

Locale.use('en-US', enUS2);
import store from './store/index'
import cos from 'cos-js-sdk-v5';
import {ImagePreview} from 'vant';
// import VueSocialSharing from 'vue-social-sharing';
import VueI18n from 'vue-i18n'
import zhCN from './lang/zh-CN.js'
import enUS from './lang/en-US.js'
import loLO from './lang/lo-Lo.js'
import viVN from './lang/vi-VN.js'
import thTH from './lang/th-TH.js'
import myMM from './lang/my-MM.js'
import msMY from './lang/ms-MY.js'
import kmKH from './lang/km-KH.js'
import amET from './lang/am-ET.js'
import EN from './lang/YN.js'
import fa from "element-ui/src/locale/lang/fa";

Vue.use(VueI18n)

const messages = {
    'zh_CN': zhCN,
    'en_US': enUS,
    'lo_LA': loLO,
    'vi_VN': viVN,
    'th_TH': thTH,
    'my_MM': myMM,
    'ms_MY': msMY,
    'km_KH': kmKH,
    'am_ET': amET,
    'en_ID': EN
}

const i18n = new VueI18n({
    locale: navigator.language || 'zh_CN',
    fallbackLocale: 'zh_CN',
    messages,
    interpolate: true,
    missing: (key) => {

        return key;
    },
    // 分隔符自定义
    // 自定义分隔符为 $$
    delimiters: ['{{', '}}'], // 检查分隔符选项
    silentTranslationWarn: true,
    formatFallbackMessages: true,
})
Vue.prototype.$changeLanguage = function (lang) {

    this.$i18n.locale = lang // 更新当前的语言环境为 lang
}


// 将i18n注入到全局变量中
window.$i18n = i18n

// 初始化 COS 对象
const cosClient = new cos({
    SecretId: 'AKIDNrBQVULeppjU4bz96rPktx08D6B2tcAE',
    SecretKey: 'b675Vt6bzIZkvvhDfzEPPK8qMjx3vY4O'
});

Vue.prototype.$getImageUrl = function (url, width, height, format) {
    if (typeof (url) == 'string' && url.indexOf('platform-lookaside.fbsbx.com') != -1) {
        return url
    }
    if (!cosClient) {
        console.error('COS 对象未初始化');
        return url;
    }
    // 获取图片后缀名
    // const extName = url.substr(url.lastIndexOf('.') + 1);

    // 根据图片格式参数设置压缩格式
    let compressFormat = '';
    if (format === 'webp') {
        compressFormat = '/format/webp';
    } else if (format === 'jpeg') {
        compressFormat = '/format/jpg';
    }

    // 根据图片尺寸参数设置缩放大小
    let resizeParam = '';
    if (width && height) {
        resizeParam = `?imageMogr2/thumbnail/${width}x${height}!/quality/90`;
    } else if (width) {
        resizeParam = `?imageMogr2/thumbnail/${width}!/quality/90`;
    } else if (height) {
        resizeParam = `?imageMogr2/thumbnail/${height}!/quality/90`;
    }

    // 拼接处理后的图片 URL
    const processedUrl = `${url}${resizeParam}${compressFormat}`;
    // 返回经过处理的图片 URL
    return processedUrl;
};

// 蓝牙服务
Vue.prototype.$bluetooth={
    device:null,//设备
    server:null,//服务
    isItConnect:false,//蓝牙状态判断
    connectingInProgress:false,//蓝牙状态判断
    doYouWantToRefresh:false,//刷新当前页面
    bluetoothInterval:null,//监听
    keyRandomCode:'',//密钥随机码
    loadingInstance: null,
    exampleTip:false,//展示示例图
}

// 厂商B-001 蓝牙握手
Vue.prototype.$sendHandshake=function (characteristic){
    var hexArray = [0x8e, 0xfb, 0x06, 0x17, 0x0c, 0xa9, 0x68, 0x23, 0x7b, 0x4c, 0xd2, 0x2d, 0xdf, 0xfa,
        0x66, 0x5b, 0x4f, 0xe8
    ]
    // 创建一个 Uint8Array 实例
    const uint8Array = new Uint8Array(hexArray);
    // 获取 ArrayBuffer
    const arrayBuffer = uint8Array.buffer;
    characteristic.writeValue(arrayBuffer).then(() => {
        // 写入成功
        console.log('Successfully 握手成功');
    })
        .catch(error => {

            console.log('Error reading characteristic:', error);
            console.log('写入失败')
            // 写入特征时出错
        });

};
//数字转16进制字符串
Vue.prototype.numToStr=function(arr) {
    var arr = arr.toUpperCase();
    var sss = '';
    for (var i = 0; i < arr.length; i++) {
        if (sss == '') {
            sss = ('00' + arr[i].charCodeAt().toString(16)).slice(-2);
        } else {
            sss += ('00' + arr[i].charCodeAt().toString(16)).slice(-2);
        }
    }
    return sss;
}
Vue.prototype.Str2Bytes= function(str) {
    var pos = 0;
    var len = str.length;
    if (len % 2 != 0) {
        return null;
    }
    len /= 2;
    var hexA = new Array();
    for (var i = 0; i < len; i++) {
        var s = str.substr(pos, 2);
        var v = parseInt(s, 16);
        hexA.push(v);
        pos += 2;
    }
    return hexA;
}

// 厂商C-001 指令加密
Vue.prototype.$montageKey=function (cargoWay){

    console.log(Vue.prototype.$bluetooth.keyRandomCode,'-----随机码',localStorage.getItem('manufacturerKey'))
    var key = Vue.prototype.$bluetooth.keyRandomCode.substring(0, 6) + Vue.prototype.numToStr(localStorage.getItem('vmNumber').substring(7)) + Vue.prototype.$bluetooth.keyRandomCode.substring(6) +
        localStorage.getItem('manufacturerKey');

    var sendArr = Vue.prototype.numToStr(localStorage.getItem('vmNumber')) + Vue.prototype.numToStr(cargoWay);
    console.log('加密前数据===' + localStorage.getItem('vmNumber') + '--' + cargoWay);
    console.log('加密前数据===' + sendArr);
    console.log('加密前key===' + key);

    var data = jm.encryptionData(sendArr, key);
    console.log('加密后数据===', data);
    var yesdata = jm.decryptData(data, key);
    console.log('解密密后数据===', yesdata);
    var dataArr = Vue.prototype.Str2Bytes(data);
    return dataArr
}

// 开始监听蓝牙连接状态
Vue.prototype.$startBluetoothMonitoring=function (){
    Vue.prototype.$bluetooth.bluetoothInterval = setInterval(Vue.prototype.$checkBluetoothConnection, 5000); // 每5秒检查一次
}
// 停止监听蓝牙连接状态
Vue.prototype.$stopBluetoothMonitoring=function() {
    if (Vue.prototype.$bluetooth.bluetoothInterval) {
        clearInterval(Vue.prototype.$bluetooth.bluetoothInterval);
        Vue.prototype.$bluetooth.bluetoothInterval=null
    }
}
    // 检查蓝牙连接状态
Vue.prototype.$checkBluetoothConnection=function () {
    // console.log(Vue.prototype.$bluetooth.device,this)
    if (!Vue.prototype.$bluetooth.device) {
        console.log('No device connected.');
        return;
    }
// 假设我们已经知道要读取的特征值
    let  serviceUuid = '';
    let  characteristicUuid = "";
    if(localStorage.getItem('manufacturerId')=='A-001'){
        serviceUuid = '0000fff0-0000-1000-8000-00805f9b34fb';
        characteristicUuid = "0000fff2-0000-1000-8000-00805f9b34fb";
        this.$store.dispatch("bluetooth/set_isItConnect",true);
        Vue.prototype.$bluetooth.isItConnect=true
        Vue.prototype.$bluetooth.server.getPrimaryService(serviceUuid)
            .then(service => service.getCharacteristic(characteristicUuid))
            .then(characteristic => {
                console.log(characteristic,'characteristic','服务')
                // 获取到特征
                let value='AT+GETNAME#'
                var buffer = new ArrayBuffer(value.length);
                var dataView = new Uint8Array(buffer);
                for (var i = 0; i < value.length; i++) {
                    dataView[i] = value.charCodeAt(i);
                }
                console.log(buffer,'执行')
                characteristic.writeValue(buffer).then(() => {
                    // 写入成功
                    console.log('Successfully read characteristic, device is connected.');
                }).catch(error => {
                    console.log('Error reading characteristic:', error);
                    Vue.prototype.$disconnectConnection()
                    console.log('写入失败')
                    // 写入特征时出错
                });
            })
    }
    if(localStorage.getItem('manufacturerId')=='B-001'){
        console.log('B-001 第二类型')
        Vue.prototype.$bluetooth.server.getPrimaryServices().then(services=>{
            let uuid= services.filter(el=>{return el.uuid.indexOf(
                '0000ffc0') != -1})
            if(uuid){
                Vue.prototype.$bluetooth.server.getPrimaryService(uuid[0].uuid).then(service => {
                    service.getCharacteristics().then(characteristics=>{
                        console.log(characteristics,'所有特征')
                        service.getCharacteristic(characteristics[0].uuid).then(characteristic => {
                            var hexArray = [0x66, 0xf1, 0x3b, 0x77];
                            // 创建一个 Uint8Array 实例
                            const uint8Array = new Uint8Array(hexArray);
                            // 获取 ArrayBuffer
                            const arrayBuffer = uint8Array.buffer;
                            characteristic.writeValue(arrayBuffer).then(() => {
                                // 写入成功
                            }).catch(error => {
                                console.log('Error reading characteristic:', error);
                                Vue.prototype.$disconnectConnection()
                                console.log('写入失败')
                                // 写入特征时出错
                            });

                        })
                    })
                })
            }


        })



    }
    if(localStorage.getItem('manufacturerId')=='C-001'){
        serviceUuid = '';
        characteristicUuid = "";
    }
}
// 断开蓝牙
Vue.prototype.$disconnectConnection=function (){
    Vue.prototype.$bluetooth.device.gatt.disconnect()
},
// 处理设备断开连接的情况
Vue.prototype.$onDisconnected=function (event) {
    let device=''
    if(event){
        device=event.target
    }
    console.log(device,'设备断开')
    Vue.prototype.$bluetooth.isItConnect=false
    Vue.prototype.$stopBluetoothMonitoring()
    Vue.prototype.$bluetooth.device=null
    if(!Vue.prototype.$bluetooth.doYouWantToRefresh){
        this.$dialog.alert({
            title: window.$i18n.t('提示'),
            message:  window.$i18n.t('蓝牙已断开请重新连接'),
            confirmButtonText:window.$i18n.t('确定'),
            confirmButtonColor:'#4800E0'
        }).then(() => {
            location.reload()
            // this.getLanya()
            // on close
        });
    }
    console.log('通知用户设备断开连接');
}

    /**
     * 蓝牙连接A厂商 B厂商 C厂商
     * vmNumber 蓝牙名称
     * manufacturerId 厂商id
     * source 来源  good 商品列表页， goodDetails 商品详情 ，
     * numIn 第几个格子开门  来源为订单可用
     * **/
Vue.prototype.$bluetoothConnectivity=function (vmNumber,manufacturerId,source,numIn){
    this.$store.dispatch("bluetooth/setExampleTip",true);
    console.log(vmNumber,manufacturerId)
    let optionalServices=[]
    if(manufacturerId=='A-001'){
        optionalServices=['0000fff0-0000-1000-8000-00805f9b34fb','00001800-0000-1000-8000-00805f9b34fb','00001801-0000-1000-8000-00805f9b34fb']
    }
    if(manufacturerId=='B-001'){
        optionalServices=['0000ffc0-0000-1000-8000-00805f9b34fb','0000ff00-0000-1000-8000-00805f9b34fb','00001800-0000-1000-8000-00805f9b34fb','00001801-0000-1000-8000-00805f9b34fb']
    }
    if(manufacturerId=='C-001'){
        optionalServices=['0000180a-0000-1000-8000-00805f9b34fb','0000fff0-0000-1000-8000-00805f9b34fb','00001800-0000-1000-8000-00805f9b34fb','00001801-0000-1000-8000-00805f9b34fb']
    }
    console.log(vmNumber,manufacturerId)
    navigator.bluetooth.requestDevice({
        filters: [{
            name: vmNumber
        }],
        optionalServices: optionalServices
    }).then(device => {
        this.$store.dispatch("bluetooth/setExampleTip",false);
        Vue.prototype.$bluetooth.device=device
        Vue.prototype.$bluetooth.connectingInProgress=true
        this.$store.dispatch("bluetooth/setIsItConnect",true);
        // 设备已被发现
        device.gatt.connect().then(server => {
            Vue.prototype.$bluetooth.server=server
            // 蓝牙监听
            device.addEventListener('gattserverdisconnected', Vue.prototype.$onDisconnected);
            this.$store.dispatch("bluetooth/setIsItConnect",true);
            this.$store.dispatch("bluetooth/setConnectingInProgress",false);
            Vue.prototype.$bluetooth.isItConnect=true
            Vue.prototype.$bluetooth.connectingInProgress=false
            if(manufacturerId=='A-001'&&source=='orderDoorOpen'){

                Vue.prototype.$bluetoothDoorOpen(numIn)
            }
            if(manufacturerId=='B-001'){
                server.getPrimaryServices().then(services=>{
                    console.log(services,'services')
                    let uuid= services.filter(el=>{return el.uuid.indexOf(
                        '0000ffc0') != -1})
                    console.log(uuid)
                    if(uuid[0].uuid){
                        server.getPrimaryService(uuid[0].uuid)
                            .then(service => {
                                service.getCharacteristics().then(characteristics=>{
                                    let arr= characteristics.filter(el=>{return el.properties.write==true&&el.uuid.indexOf(
                                        '0000ffc1') != -1})
                                    service.getCharacteristic(arr[0].uuid).then(characteristic => {
                                        console.log('去握手了吗')
                                        Vue.prototype.$sendHandshake(characteristic)
                                        if(!Vue.prototype.$bluetooth.bluetoothInterval&&Vue.prototype.$bluetooth.device){
                                            setTimeout(()=>{
                                                Vue.prototype.$startBluetoothMonitoring();
                                            },5000)
                                        }
                                        if(source=='orderDoorOpen'){
                                            setTimeout(()=>{
                                            Vue.prototype.$bluetoothDoorOpen(numIn)
                                            },100)
                                        }

                                    })
                                })
                            })
                    }



                })
            }

            if(manufacturerId=='C-001'){
                server.getPrimaryServices().then(services=> {
                    let uuid= services.filter(el=>{return el.uuid.indexOf(
                        '0000fff0') != -1})
                    if(uuid[0].uuid){
                        server.getPrimaryService(uuid[0].uuid)
                            .then(service => {
                                service.getCharacteristics().then(characteristics=>{
                                    let arr= characteristics.filter(el=>{return el.properties.read==true&&el.uuid.indexOf(
                                        '0000fff6') != -1})
                                    service.getCharacteristic(arr[0].uuid).then(characteristic => {
                                        characteristic.readValue().then(() => {
                                            // 写入成功
                                            console.log('Successfully 获取密钥');
                                        })
                                        characteristic.addEventListener(
                                            'characteristicvaluechanged', e => {
                                                const buffer = e.target.value.buffer || e.target.value;
                                                const uint8View = new Uint8Array(buffer);
                                                // 将 Uint8Array 转换为十六进制数组
                                                const hexArray = Array.from(uint8View, byte =>
                                                    `${byte.toString(16).padStart(2, '0')}`);
                                                Vue.prototype.$bluetooth.keyRandomCode=hexArray.join('')
                                                if(source=='orderDoorOpen'){
                                                    Vue.prototype.$bluetoothDoorOpen(numIn)
                                                }
                                                console.log(hexArray.join(''), '但具体值取决于arrayBuffer的内容');
                                            }
                                        );
                                        characteristic.startNotifications()

                                    })
                                })
                            })
                    }
                })
            }

            // if(!Vue.prototype.$bluetooth.bluetoothInterval&&Vue.prototype.$bluetooth.device&&manufacturerId!='B-001'){
            //     Vue.prototype.$startBluetoothMonitoring();
            // }
            // 连接已建立
        })
            .catch(error => {
                Vue.prototype.$bluetooth.isItConnect=false
                Vue.prototype.$bluetooth.connectingInProgress=false
                Vue.prototype.$bluetooth.server=null
                Vue.prototype.$bluetooth.device=null
                this.$store.dispatch("bluetooth/setIsItConnect",false);
                this.$store.dispatch("bluetooth/setConnectingInProgress",false);
                this.$store.dispatch("bluetooth/setExampleTip",false);
                this.$dialog.alert({
                    title: window.$i18n.t('提示'),
                    message:  window.$i18n.t('连接失败请重新连接'),
                    confirmButtonText:window.$i18n.t('确定'),
                    confirmButtonColor:'#4800E0'
                }).then(() => {

                    Vue.prototype.$bluetoothConnectivity(localStorage.getItem('vmNumber'),localStorage.getItem('manufacturerId'))
                    // on close
                });
                console.log('连接蓝牙失败',error)
                // 建立连接时出错
            });
    })
        .catch(error => {
            this.$store.dispatch("bluetooth/setExampleTip",false);
            if (error.message.includes('Web Bluetooth is not supported on this platform')||error.message.includes('Bluetooth adapter not available')) {
                this.$dialog.alert({
                    title: window.$i18n.t('提示'),
                    message: window.$i18n.t('该浏览器不支持蓝牙'),
                    confirmButtonText: window.$i18n.t('去商城首页'),
                    confirmButtonColor: '#4800E0',
                    closeOnPopstate: false
                }).then(() => {
                    this.$router.push(
                        {
                            path: "/saas/",
                            query: { storeId: localStorage.getItem("storeID") },
                        });
                });
            }
            console.log(error,'搜索兰阿姨gx')
            // 发现设备时出错
        });
}
// 读取电量
Vue.prototype.$readVoltage=function (){
    Vue.prototype.$bluetooth.server.getPrimaryServices().then(services=>{
        if(localStorage.getItem('manufacturerId')=='A-001'){
            let uuid= services.filter(el=>{return el.uuid.indexOf(
                '0000fff0') != -1})
            if (uuid[0].uuid) {
                Vue.prototype.$bluetooth.server.getPrimaryService(uuid[0].uuid)
                    .then(service => {
                        service.getCharacteristics().then(characteristics=>{
                            let arr= characteristics.filter(el=>{return el.properties.write==true&&el.uuid.indexOf(
                                '0000fff2') != -1})
                            let arr2=characteristics.filter(el=>{return el.properties.notify==true})
                            service.getCharacteristic(arr2[0].uuid).then(characteristic=>{
                                characteristic.addEventListener(
                                    'characteristicvaluechanged', e => {
                                        var receiveText=''
                                        const buffer = e.target.value.buffer || e.target.value;
                                        const uint8View = new Uint8Array(buffer);
                                        for (let i = 0; i < uint8View.length; i++) {
                                            const value = uint8View[i];
                                            receiveText += String.fromCharCode(value);
                                            console.log(`第 ${i} 个字节的值：${value}`);
                                        }
                                        var parts = receiveText.split(':'); // 以冒号为分隔符分割字符串
                                        var valueAfterColon = parts[1]; // 获取冒号后面的值
                                        valueAfterColon=100
                                        if (valueAfterColon > 0) {
                                            this.$store.dispatch("bluetooth/updateStoreSellVoltage",valueAfterColon);
                                        }
                                    }
                                );
                                characteristic.startNotifications()
                            })
                            service.getCharacteristic(arr[0].uuid).then(characteristic => {
                                // 获取到特征
                                let value='AT+ADC#'
                                var buffer = new ArrayBuffer(value.length);
                                var dataView = new Uint8Array(buffer);
                                for (var i = 0; i < value.length; i++) {
                                    dataView[i] = value.charCodeAt(i);
                                }
                                setTimeout(()=>{
                                    characteristic.writeValue(buffer)
                                        .then(() => {
                                            // 写入成功
                                        })
                                        .catch(error => {
                                            console.log('写入失败',error)
                                            // 写入特征时出错
                                        });
                                },1000)

                            })
                                .catch(error => {
                                    // 获取服务或特征时出错
                                    console.log('获取特征值失败',error)
                                });

                        })
                    })
            }

        }
        if(localStorage.getItem('manufacturerId')=='B-001'){
            let uuid= services.filter(el=>{return el.uuid.indexOf(
                '0000ffc0') != -1})
            if(uuid){
                Vue.prototype.$bluetooth.server.getPrimaryService(uuid[0].uuid)
                    .then(service => {
                        service.getCharacteristics().then(characteristics=>{
                            let arr= characteristics.filter(el=>{return el.properties.write==true&&el.uuid.indexOf(
                                '0000ffc1') != -1})
                            let arr2=characteristics.filter(el=>{return el.properties.notify==true})
                            service.getCharacteristic(arr[0].uuid).then(characteristic => {
                                var hexArray = [0x66, 0xf0, 0x33, 0x77];
                                // 创建一个 Uint8Array 实例
                                const uint8Array = new Uint8Array(hexArray);
                                // 获取 ArrayBuffer
                                const arrayBuffer = uint8Array.buffer;
                                characteristic.writeValue(arrayBuffer).then(() => {
                                    // 写入成功
                                    console.log('读取电量成功B-001')
                                }).catch(error => {
                                    console.log('Error reading characteristic:', error);
                                    Vue.prototype.$disconnectConnection()
                                    console.log('写入失败')
                                    // 写入特征时出错
                                });

                            })
                            service.getCharacteristic(arr2[0].uuid).then(characteristic=>{
                                characteristic.addEventListener(
                                    'characteristicvaluechanged', e => {
                                        const buffer = e.target.value.buffer || e.target.value;
                                        const uint8View = new Uint8Array(buffer);
                                        // 将 Uint8Array 转换为十六进制数组
                                        const hexArray = Array.from(uint8View, byte =>
                                            `0x${byte.toString(16).padStart(2, '0')}`);
                                        console.log(hexArray, '但具体值取决于arrayBuffer的内容');
                                        let valueAfterColon=parseInt(((hexArray[3].slice(2)*1)/64)*100)
                                        console.log(valueAfterColon,'什么电量')
                                        if (valueAfterColon > 0) {

                                            this.$store.dispatch("bluetooth/updateStoreSellVoltage",valueAfterColon);
                                        }
                                    }
                                );
                                characteristic.startNotifications()
                            })
                        })
                    })
            }

        }
        if(localStorage.getItem('manufacturerId')=='C-001'){
            let uuid= services.filter(el=>{return el.uuid.indexOf(
                '0000fff0') != -1})
            if(uuid){
                Vue.prototype.$bluetooth.server.getPrimaryService(uuid[0].uuid)
                    .then(service => {
                        service.getCharacteristics().then(characteristics=>{
                            let arr= characteristics.filter(el=>{return el.uuid.indexOf(
                                '0000fff5') != -1})
                            service.getCharacteristic(arr[0].uuid).then(characteristicObj => {
                                console.log(characteristicObj)
                                characteristicObj.readValue().then(() => {
                                    // 写入成功
                                    console.log('读取电量成功C-001')
                                })
                                characteristicObj.addEventListener(
                                    'characteristicvaluechanged', e => {
                                        const buffer = e.target.value.buffer || e.target.value;
                                        const uint8View = new Uint8Array(buffer);
                                        // 将 Uint8Array 转换为十六进制数组
                                        const hexArray = Array.from(uint8View, byte =>
                                            `${byte.toString(16).padStart(2, '0')}`);
                                        // Vue.prototype.$bluetooth.keyRandomCode=hexArray.join('')
                                        const decimalValue = parseInt('0x'+hexArray.join(''), 16);
                                        let valueAfterColon=parseInt(((decimalValue*1)/450)*100)
                                        console.log(valueAfterColon,'什么电量')
                                        if (valueAfterColon > 0) {

                                            this.$store.dispatch("bluetooth/updateStoreSellVoltage",valueAfterColon>100?100:valueAfterColon);
                                        }
                                        console.log(hexArray.join(''), '但具体值取决于arrayBuffer的内容',decimalValue);
                                    }
                                );
                                characteristicObj.startNotifications()

                            })
                        })
                    })
            }
        }

    }).catch(error => {
        console.log(error,'error')
        // 写入特征时出错
    });
}

// 蓝牙开门 A厂商 B厂商 C厂商
Vue.prototype.$bluetoothDoorOpen=function (numIn){
    console.log(numIn,'第几个格子')
    Vue.prototype.$bluetooth.server.getPrimaryServices().then(services=>{
        if(localStorage.getItem('manufacturerId')=='A-001'){
            Vue.prototype.$bluetooth.server.getPrimaryService(services[0].uuid)
                .then(service => {
                    service.getCharacteristics().then(characteristics=>{
                        console.log(characteristics,'所有特征值')
                        let arr= characteristics.filter(el=>{return el.properties.write==true})
                        console.log(arr,'可以写的特征值')
                        // this.shuffleArray(arr);
                        console.log(arr,'可以写的特征值2')
                        service.getCharacteristic(arr[0].uuid).then(characteristic => {
                            console.log('获取特征值',characteristic)
                            // 获取到特征
                            let value='AT+MWORK=1,'+numIn+'&1#'
                            var buffer = new ArrayBuffer(value.length);
                            var dataView = new Uint8Array(buffer);
                            for (var i = 0; i < value.length; i++) {
                                dataView[i] = value.charCodeAt(i);
                            }
                            characteristic.writeValue(buffer)
                                .then(() => {
                                    this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                                        Vue.prototype.$bluetooth.loadingInstance.close();
                                    });
                                    Toast(window.$i18n.t('刷新成功'))

                                    // 写入成功
                                })
                                .catch(error => {
                                    this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                                        Vue.prototype.$bluetooth.loadingInstance.close();
                                    });
                                    console.log('写入失败',error)
                                    // 写入特征时出错
                                });
                        })
                            .catch(error => {
                                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                                    Vue.prototype.$bluetooth.loadingInstance.close();
                                });
                                // 获取服务或特征时出错
                                console.log('获取特征值失败',error)
                            });

                    })
                    console.log(service,'服务')

                })
        }
        if(localStorage.getItem('manufacturerId')=='B-001'){
            let uuid= services.filter(el=>{return el.uuid.indexOf(
                '0000ffc0') != -1})
            if(uuid){
                Vue.prototype.$bluetooth.server.getPrimaryService(uuid[0].uuid)
                    .then(service => {
                        service.getCharacteristics().then(characteristics=>{
                            let arr= characteristics.filter(el=>{return el.properties.write==true&&el.uuid.indexOf(
                                '0000ffc1') != -1})
                            service.getCharacteristic(arr[0].uuid).then(characteristic => {
                                var hexArray = [0xff, 0x4f, 0x50, 0x45, 0x4e, 0x00, 0x00, 0x88, 0x99, 0xfe];
                                hexArray[6] = '0x0' + numIn
                                // 创建一个 Uint8Array 实例
                                const uint8Array = new Uint8Array(hexArray);
                                // 获取 ArrayBuffer
                                const arrayBuffer = uint8Array.buffer;
                                characteristic.writeValue(arrayBuffer).then(() => {
                                    // 写入成功
                                    this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                                        Vue.prototype.$bluetooth.loadingInstance.close();
                                    });
                                    console.log('开门成功')
                                }).catch(error => {
                                    console.log('Error reading characteristic:', error);
                                    Vue.prototype.$disconnectConnection()
                                    console.log('写入失败')
                                    // 写入特征时出错
                                });

                            })
                        })
                    })
            }
        }
        if(localStorage.getItem('manufacturerId')=='C-001'){
            let uuid= services.filter(el=>{return el.uuid.indexOf(
                '0000fff0') != -1})
            if(uuid){
                Vue.prototype.$bluetooth.server.getPrimaryService(uuid[0].uuid)
                    .then(service => {
                        service.getCharacteristics().then(characteristics=>{
                            let arr= characteristics.filter(el=>{return el.properties.write==true&&el.uuid.indexOf(
                                '0000fff6') != -1})
                            service.getCharacteristic(arr[0].uuid).then(characteristic => {

                               var hexArray= Vue.prototype.$montageKey('A0'+numIn)
                                console.log('向蓝牙设备发送16进制数据')
                                console.log('--16jz数据===', hexArray)
                                var buffer = new ArrayBuffer(hexArray.length);
                                var dataView = new DataView(buffer);
                                for (var i = 0; i < hexArray.length; i++) {
                                    dataView.setUint8(i, hexArray[i]);
                                }
                                characteristic.writeValue(buffer).then(() => {
                                    // 写入成功
                                    this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                                        Vue.prototype.$bluetooth.loadingInstance.close();
                                    });
                                    console.log('开门成功')
                                }).catch(error => {
                                    this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                                        Vue.prototype.$bluetooth.loadingInstance.close();
                                    });
                                    console.log('Error reading characteristic:', error);
                                    Vue.prototype.$disconnectConnection()
                                    console.log('写入失败')
                                    // 写入特征时出错
                                });
                                characteristic.addEventListener(
                                    'characteristicvaluechanged', e => {
                                        const buffer = e.target.value.buffer || e.target.value;
                                        const uint8View = new Uint8Array(buffer);
                                        // 将 Uint8Array 转换为十六进制数组
                                        const hexArray = Array.from(uint8View, byte =>
                                            `${byte.toString(16).padStart(2, '0')}`);
                                        // Vue.prototype.$bluetooth.keyRandomCode=hexArray.join('')
                                        const decimalValue = parseInt('0x'+hexArray.join(''), 16);
                                        console.log(hexArray.join(''), '但具体值取决于arrayBuffer的内容',decimalValue);
                                    }
                                );
                                characteristic.startNotifications()

                            })
                        })
                    })
            }
        }
    }).catch(error => {
        this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
            Vue.prototype.$bluetooth.loadingInstance.close();
        });
        console.log(error,'error')
        // 写入特征时出错
    });
}


// 英文方法，隔开千分符
Vue.prototype.$formatNumber = function (num) {
    // 如果 num 为 null 或 undefined，则直接返回空字符串或其他默认值
    if (num == null) {
        return "";
    }
    // 如果传入的已经是带逗号的格式，则直接返回原数据
    if (typeof num === 'string' && num.includes(',')) {
        return num;
    }

    let strNum = typeof (num) == 'number' ? num?.toFixed(2).toString() :  num; // 将数字转换为字符串类型
    let arrNum = strNum.split("."); // 分离整数和小数部分
    let intPart = arrNum[0]; // 整数部分
    let decimalPart = arrNum.length > 1 ? "." + arrNum[1] : ""; // 小数部分（如果有）
    let regExp = /(\d)(?=(\d{3})+$)/g; // 正则表达式，匹配每三位数字
    intPart = intPart.replace(regExp, "$1,"); // 将整数部分按照正则表达式替换成“逗号+数字”的形式
    // 去掉小数部分末尾的多余的 0
    if (decimalPart !== "") {
        decimalPart = decimalPart.replace(/0+$/, "");
        if (decimalPart === ".") {
            decimalPart = "";
        }
    }
    return intPart + decimalPart;
}

Vue.prototype.$getTimeZoneOffset=function (date, inx = null, format = 'yyyy-MM-dd hh:mm:ss') {
    // 此处的i为8是北京东八区的时间，如果是西n区，则此处为：-n
    const i = -(new Date().getTimezoneOffset() / 60)
    date = date.replace(/-/g, '/')
    // 东八区，中国时间，不做处理
    if (!date || i === 8) {
        return date
    }

    date = new Date(date)


    // 北京时间戳
    const bjDate =date.getTime();
    // 当地时间戳
    const ddDate = (i - 8) * 60 * 60 * 1000 + bjDate;
    date = new Date(ddDate);

    if (!format) {
        return date
    }

    let o = {
        'M+': date.getMonth() + 1, // month
        'd+': date.getDate(), // day
        'h+': date.getHours(), // hour
        'm+': date.getMinutes(), // minute
        's+': date.getSeconds(), // second
        'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
        'S': date.getMilliseconds() // millisecond
    }
    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
        }
    }
    return format
}

// 添加路由前置守卫
router.beforeEach((to, from, next) => {
    console.log(from,'from')
    console.log(to,'to')
    if (to.path == '/saas/goods' || to.path == '/saas/shoppingCart' || to.path == '/saas/' || to.path == '/saas/usermy') {
        Vue.prototype.$tab = to.path == '/saas/goods' ? 1 : to.path == '/saas/shoppingCart' ? 2 : to.path == '/saas/usermy' ? 3 : to.path == '/saas/' ? 0 : 0
        Vue.prototype.$isShow = true
        if (to.path == '/saas/shoppingCart' && (from.fullPath.indexOf('productDetails') != -1||from.fullPath.indexOf('home')!=-1)) {
            Vue.prototype.$isShow = false
        }
    } else {
        Vue.prototype.$isShow = false
    }
    next()
})

document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
        event.preventDefault();
    }
}, {passive: false});


if (apiNow != 'prod') {
// 引入并启用 vConsole 库

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://cdn.bootcss.com/vConsole/3.9.5/vconsole.min.js'
    script.onload = function () {
        const vConsole = new window.VConsole();
        vConsole.setSwitchPosition(80, 80)
    }
    document.body.appendChild(script)
} else {
    // console.log = () => {};
    // console.info = () => {};
}


Vue.use(Vant);
Vue.use(ImagePreview);
// Vue.use(VueSocialSharing);
Vue.config.productionTip = false
new Vue({
    el: '#app',
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
