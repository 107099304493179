// lang/zh-CN.js: 中文翻译文件
export default {
    推荐商品: 'Recommendations',
    用户服务协议及法律声明: '《User Service Agreement and Legal Statement》',
    用户服务协议: '《User Service Agreement》',
    法律声明: '《Legal Statement》',
    隐私政策: '《Privacy Policy》',
    商品分类: 'Product Category',
    暂无商品: 'No products',
    留言管理: 'Message',
    留言回复: 'Reply',
    我要留言: 'Comment',
    没有更多了: 'No more',
    查看全部xx条回复: 'View all {num} replies',
    条回复: 'replies',
    留言详情: 'message details​',
    留言回复长度: 'Reply with 2-100 words',
    提交: 'Submit',
    邮箱: 'Email',
    手机号: 'Phone Number',
    留言内容: 'Message Content',
    请填写留言内容: 'Please enter your message',
    立即留言: 'Leave a message now',
    忘记密码: 'Forget Password',
    请输入手机号: 'Please enter a phone number',
    手机验证码: 'SMS verification code',
    请输入验证码: 'Please enter the phone verification code',
    新密码: 'New password',
    请输入新密码: 'Please enter a new password',
    立即提交: 'Submit now',
    登录: 'Sign in',
    欢迎登录: 'Welcome to login',
    密码登录: 'Password',
    验证码登录: 'Verification Code',
    邮箱手机号: 'Email / Phone number',
    请输入邮箱手机号: 'Please enter your email / phone number',
    密码: 'Password',
    请输入密码: 'Please enter your password',
    验证码: 'Verification Code',
    立即登录: 'Sign in now',
    已阅读并同意以下协议: 'I have read and agreed to',
    我是新用户: "I'm a new user",
    立即注册: 'Sign up now',
    注册: 'Sign up',
    确定: 'Confirm',
    消息通知: 'Notifications',
    全部消息: 'All messages',
    已读消息: 'Read',
    全选: 'Select All',
    标记已读: 'Marked as read',
    删除: 'Delete',
    消息详情: 'Message details',
    我的订单: 'My Order',
    全部: 'All',
    待付款: 'Unpaid',
    待发货: 'Unshipped',
    待收货: 'Undelivered',
    已完成: 'Completed',
    订单号: 'Order No',
    已关闭: 'Closed',
    部分订单取消: '部分订单取消',
    部分退款完成: '部分退款完成',
    部分退款: '部分退款',
    部分收货: '部分收货',
    退款中: 'Refunding',
    交易异常: 'transaction exception',
    部分发货: 'Partially Shipped',
    发货时间: 'Shipping Time',
    退款成功: 'Refunded successfully',
    共: 'Total',
    共x件商品已选择x件: 'Total {num} items, {num2} items selected',
    共x件商品: 'Total {num} items',
    总价: 'Total price',
    联系客服: 'Customer Service',
    删除订单: 'Delete Order',
    取消订单: 'Cancel',
    去付款: 'Pay',
    确认收货: 'Confirm delivery',
    暂无订单信息: 'No orders yet',
    客服电话: 'Hotline',
    客服邮箱: 'Email',
    付款时间: 'Payment Time',
    创建时间: 'Creation Time​',
    复制: 'Copy',
    订单编号: 'Order No',
    实付款: 'Actually paid',
    运费: 'Freight Charge',
    商品总额: 'Grand Total',
    支付宝: 'Alipay',
    微信: 'WeChat',
    线下支付: 'Offline Payment',
    支付方式: 'Payment Way',
    售后详情: 'After-sales details',
    售后申请: 'Request for After-sales',
    物流单号: 'Express Number：',
    购买商品: 'Product information',
    x件: '{num}pieces',
    收货地址: 'Shipping Address',
    收货人: 'Consignee',
    您当前是线下支付请耐心等待店铺确认收款若:
        'Paid offline, please wait for payment confirmation patiently！If the store does not confirm the payment after',
    后店铺未确认收款订单将自动取消: ', the order will be automatically canceled',
    请在: 'Please complete the payment within',
    内完成支付否则订单会被自动取消:
        ', otherwise the order will be automatically canceled',
    订单详情: 'Order Details',
    客户已付款等待店铺发货: 'Paid already, waiting for shipment',
    售后服务: 'After-sales service',
    该订单已取消: 'This order has been canceled',
    申请信息: 'Application Info',
    退货类型: 'Return Type',
    点击选择退货类型: 'Click to select return type',
    退货金额: 'Refund Amount​',
    退货原因: 'Reasons for return',
    点击选择退货原因: 'Click to select a reason for return',
    请输入留言内容: 'Please enter message content',
    x个字以内: 'within x words',
    上传凭证: 'Upload Vouchers',
    为了帮助您更好的解决问题请上传图片:
        'To help you better solve the problem, please upload pictures',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件:
        'Upload at most 5 pictures (bmp, gif, jpg, png or jpeg, each within 5M)',
    联系方式: 'Contact information',
    联系人: 'Contact person​',
    请填写联系人: 'Please enter a contact name',
    联系电话: 'Contact Number',
    请填写联系电话: 'Please enter a contact number',
    确定提交: 'Confirm Submission ',
    温馨提示: 'Warm tips',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询:
        'The return address of the product will be notified in the form of a text message or inquired in the application record after the approval',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通:
        'After submitting the request, the after-sales specialist may contact you by phone. Please keep your lines open.',
    退货处理成功后退款金额将原路返回到您的支持账户中:
        'The refund will be delivered to your paid account if return is successfully processed.',
    等待店铺审核: "Waiting for seller's review",
    店铺拒绝您的申请: 'The store rejected your application',
    客户取消售后: 'The customer canceled the after-sales service',
    售后单号: 'After-sales Order No',
    申请时间: 'Application Time',
    提交申请: 'Submit request​',
    店铺处理: 'In Progress​',
    店铺退款: 'Refunding',
    售后完成: 'After-sales completed',
    售后商品: 'After-sales product',
    售后信息: 'After-Sales Info',
    售后方式: 'After-Sales Method',
    退款金额: 'Refund Amount',
    退款原因: 'Refund Reason',
    退款说明: 'Refund Instructions',
    购买信息: 'Purchase Information​',
    商品单价: 'Unit Price',
    实付金额: 'Amount Paid',
    发货件数: 'Shipped Pieces',
    发货状态: 'Delivery Status',
    您可以在这里给店铺留言: 'You can leave a message to the seller here',
    为了保证你的权益: ' To protect your rights and interests',
    请尽可能详细的上传留言资料:
        '  please upload detailed information as more as possible.',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料:
        'You can leave a message to the seller here. To protect your rights and interests, please upload detailed information as more as possible',
    发表留言: 'left a comment',
    店: 'S',
    客: 'C',
    店铺: 'Seller',
    客户: 'The customer',
    取消售后: 'Canceled after-sales',
    重新申请: 'Re-apply',
    退款失败: 'Refund failed',
    退款完成: 'Refund completed',
    查看详情: 'View details',
    暂无售后信息: 'No after-sale orders yet.',
    修改邮箱: 'Change Email',
    手机验证: 'Verify Phone Number',
    邮箱修改: 'Change email address',
    邮箱验证: 'Verify Email',
    完成: 'Finish',
    为确认身份我们需验证您的安全手机:
        'To confirm identity, we need to verify your phone number',
    如果您当前的手机号已无法使用请联系客服:
        'If your phone number is no longer available, please contact customer service in time',
    当前安全手机号: 'Your Phone Number',
    短信验证码: 'SMS verification code',
    请输入短信验证码: 'Please enter the SMS verification code',
    下一步: 'Next',
    请输入邮箱: 'Please enter your email address',
    请填写邮箱: 'Please enter your email address',
    请输入正确的邮箱: 'Please enter correct email format',
    恭喜您邮箱地址验证成功: 'Congratulations! Email changed successfully',
    请在下次使用邮箱地址进行登录: 'Please sign in with email next time',
    返回: 'Back',
    修改密码: 'Change Password',
    原密码: 'Original Password',
    请输入原密码: 'Please enter the original password',
    确认密码: 'Confirm Password',
    请再次输入新密码: 'Please enter the new password again',
    保存: 'Save',
    未登录: 'Not logged in',
    修改: 'Modify',
    地址管理: 'Address Management',
    退出登录: 'Sign out',
    头像: 'Avatar',
    昵称: 'Alias',
    请输入用户名: 'please enter user name ',
    性别: 'Gender',
    男: 'Male',
    女: 'Female',
    保密: 'Private',
    手机号码: 'Phone Number',
    验证: 'verify',
    个人信息: 'Personal Info',
    售后申请成功: 'After-sales application is successful',
    售后申请提交成功: 'After-sales requested successfully',
    售后专员可能与您电话沟通请保持手机畅通:
        'The after-sales specialist may contact you by phone. Please keep your lines open',
    支付成功: 'Paid successfully​',
    支付失败: 'payment failed',
    查看订单: 'View orders',
    返回首页: 'Back to Home',
    支付订单: 'Pay for the order',
    优惠金额: 'Discount amount',
    确定支付: 'Confirm payment',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货:
        "Order paid offline won't support online after-sales service. If you need, please contact the seller. For paid order, please wait for payment delivered to the seller and shipment patiently",
    您可以参考如下线下付款方式: 'Preferably payment ways below',
    支付凭证: 'Payment Voucher',
    若您已按如上付款方式付款请上传相关凭证如转账截图等:
        'If you have paid by any of the above payment methods, please upload relevant vouchers, e.g. transfer screenshots',
    支持jpg和png格式文件: 'Support jpg and png format files',
    图片不大于3M: ' each no larger than 3M',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持jpg和png格式文件图片不大于3M: 'If paid by the above ways, please upload relevant vouchers, e.g. transfer screenshots (jpg, png supported, each no larger than 3M).',
    取消: 'Cancel',
    确定要取消支付这笔订单吗:
        'Are you sure you want to cancel payment for this order?',
    确认已付款: 'Paid already',
    您的收货地址为空点此添加收货地址:
        'Your delivery address is empty, click here to add a delivery address',
    商品: 'Product',
    备注说明: 'Remark',
    商品总价: 'Grand Total',
    活动优惠: 'Discount',
    优惠卷抵扣: 'Deduction',
    支付金额: 'Payment Amount',
    应付总额: 'Amount Due',
    提交订单: 'Submit Order',
    商品详情: 'Product Details​',
    选择: 'Select',
    发货: 'Shipped',
    剩余: 'Remaining',
    剩余X件: 'Remaining {num} pieces',
    购买数量: 'Purchase Qty.',
    加入购物车: 'Add to cart',
    立即购买: 'Buy now',
    此宝贝已下架: 'The product has been removed',
    客服: 'Customer service',
    购物车: 'Cart',
    请输入搜索关键词: 'Please enter search terms',
    搜索: 'Search',
    历史搜索: 'History Search',
    姓: 'Surname',
    请填写姓: 'Please fill in the surname',
    名: 'Given Name',
    请填写名: 'Please fill in the given name',
    请填写手机号: 'Please fill in your phone number ',
    国家区域: 'Country / Region',
    点击选择国家: 'Click to select country',
    请选择国家: 'Please select a country',
    城市: 'City',
    点击选择城市: 'Click to select a city',
    请选择城市: 'Please select a city',
    邮政编码: 'Zip Code',
    请填写邮政编码: 'Please enter the postal code  ',
    详细地址: 'Detailed Address',
    请输入详细地址信息如道路门牌号小区楼层号等:
        'Please enter detailed address information, such as road, house number, community, floor number, etc.',
    请填写详细地址: 'Please enter the detailed address',
    设为默认收货地址: 'Set as default shipping address',
    选中地址: 'Address selected',
    选择新地址: 'Select a new address',
    设为默认: 'Set as Default',
    默认地址: 'Default Address',
    未添加收货地址: 'Shipping address not added',
    添加新地址: 'Add a new address',
    您的购物车还没有商品哟: 'No products in your cart yet.',
    继续逛逛: 'Go for more',
    失效宝贝: 'Expired Items',
    删除失效商品: 'Delete invalid items',
    已选择: 'chosen',
    商品合计: 'Grand Total',
    下单结算: 'Settlement',
    件商品: 'items',
    首页: 'Home',
    分类: 'Category',
    我的: 'Account',
    请填写完整必填项: 'Please fill in all mandatory fields',
    销量最高: 'highest sales',
    销量最低: 'lowest sales',
    价格最高: 'highest price',
    价格最低: 'lowest price',
    评论成功: 'Commented successfully',
    秒后重新发送: 'Resend in {seconds} seconds',
    发送验证码: 'Verification Code',
    密码最少8位最多16位: 'Password within 8-16 characters',
    留言回复长度XX字符: 'Reply with 2-100 characters',
    请输入正确的密码格式必须包含两种字符:
        'Please enter correct password format, containing two character types',
    修改成功: 'Changed successfully',
    发送成功: 'Sent successfully',
    需勾选协议: 'Tick the agreement',
    注册成功: 'Sign up successfully',
    请勾选信息: 'Please tick messages',
    请选择删除项需已读才能删除:
        'Please select the item to delete. Only items read can be deleted',
    删除订单成功: 'Order deleted successfully',
    订单已超时: 'Order timed out',
    确认收货成功: 'Please tick messages',
    取消订单成功: 'Order canceled successfully',
    部分商品正在退款中: 'Some items are being refunded',
    复制成功: 'Copied successfully',
    天: 'day',
    时: 'hour',
    分: 'minute',
    秒: 'second',
    我要退款: 'I want a refund',
    我要退货退款: 'I want a return & refund',
    最多上传五张: 'Upload up to 5 images',
    不可修改最多: 'Unmodifiable, up to',
    含发货邮费: 'Shipping cost included',
    上传成功: 'Uploaded successfully',
    上传中: 'uploading...',
    图片上传成功: 'Image uploaded successfully',
    图片上传失败请重试: 'Image uploading failed, please try again',
    文件过大: 'file is too large',
    取消成功: 'Canceled successfully',
    请输入反馈内容: 'Please enter your feedback',
    重新发送验证码: 'Resend a verification code',
    两次输入密码不一致: 'The two passwords entered are inconsistent!',
    新密码不能和旧密码相同:
        'The new password cannot be the same as the old password',
    上传头像图片只能是JPG格式: 'Only JPG avatar image allowed',
    上传头像图片大小不能超过2MB:
        ' The size of the uploaded avatar picture cannot exceed 2MB!',
    昵称不能为空: 'No empty alias',
    添加超时: 'Add timed out',
    请选择付款方式: 'Please select a payment method',
    请填写收货地址: 'Please enter the delivery address',
    请选择: 'Please select',
    请选择规格: 'Please select specifications',
    添加成功: 'Added successfully ',
    添加失败库存不足: 'Fail to add, insufficient stock',
    确定要清空历史记录吗: 'Confirm to clear the history?',
    综合排序: 'Comprehensive Rank',
    销量高低: 'Sort by Sales',
    价格高低: 'Sort by Price',
    编辑地址: 'Edit address',
    新增地址: 'Add New Address',
    是否选择该地址: 'Confirm to select the address',
    是否删除地址: 'Confirm to delete the address?',
    删除成功: 'Deleted successfully',
    已取消删除: 'Undeleted',
    未选择地址或没有地址: 'No address selected or no address',
    选择成功: 'Selected successfully',
    确定删除选中的商品吗: 'Are you sure you want to delete the selected item?',
    已取消: 'Cancelled',
    提示: 'hint',
    请勾选删除商品: 'Please tick the items that need to be deleted',
    请勾选商品: 'Please tick the item',
    加载中: 'Loading',
    全部分类: 'All Categories',
    客户服务: 'Customer service',
    退货数量: 'Return Qty',
    未读消息: 'Unread',
    确定放弃支付吗: 'Are you sure you want to give up the payment?',
    确认订单: 'Confirm Order',
    请输入内容: 'Please enter content',
    未发货: 'Unshipped',
    已发货: 'Shipped',
    取消时间: 'Cancellation time',
    请输入下方图形验证码: 'Please enter verification code',
    看不清换一张: 'Change one',
    留言成功: 'Messaged successfully',
    长度在1到8个字符: 'Length with 1-8 characters',
    OnePay支付: 'Pay by OnePay',
    OnePay支付二维码: 'OnePay - QR',
    扫码或者点击去支付: 'Scan QR or click "Go to pay"',
    去支付: 'Go to pay',
    通过BCEL银行完成支付后务必返回当前页面确认支付是否成功:
        "After completing BCEL payment, please go back to the page to ensure it's successfully paid",
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名:
        'Please enter the content of the message. If you use BCEL or KBZPay to pay, please enter the bank card number and account name used to receive the refund here',
    查看支付是否成功: 'Check if the payment is successful>>>',
    查询: 'Check',
    KBZ支付: 'KBZpay',
    BCEL支付: 'BCEL',
    查询中: 'Checking',
    语言: 'Language',
    货币: 'Currency',
    切换语言和货币: 'Switch language and currency',
    您未支付请重新支付: 'You have not paid, please pay again',
    确定已输入接收退款的银行卡号和户名:
        'Make sure you have entered the bank card number and account name to receive the refund',
    绑定: 'Bind',
    绑定邮箱: 'Bind email',
    分享店铺: 'Share store',
    分享至: 'Share to',
    海报: 'poster',
    分享: 'Share',
    推荐店铺给你: 'Recommended mall to you',
    链接: 'Link',

    至: 'to',
    币种: 'Currency',
    变动理由: 'Reason for change',
    不记得: "Don't remember",
    充值: 'Recharge',
    打款凭证: 'Payment Voucher',
    打款说明: 'Payment Instructions',
    打款信息: 'Payment information',
    打款状态: 'Payment status',
    待打款: 'Pending Withdrawal',
    待审核: 'Unreviewed',
    到账方式: 'Payment method',
    到账银行卡号: 'Receiving Bank',
    冻结中: 'Freezing',
    国家: 'Nation',
    获取验证码: 'Get verification code',
    记得: 'Remember',
    记录时间: 'Recording time',
    拒绝打款: 'Refuse to pay',
    开户人: 'Account holder',
    开户支行: 'Issuing Bank Branch',
    可提现: 'Withdrawal available',
    可提现金额: 'Withdrawable Amount',
    立即提现: 'Withdraw immediately',
    流水号: 'Serial No.',
    没有适合此币种的银行卡请先添加: 'There is no bank card suitable for this currency, please add it first',
    没有收款码请先添加: 'No payment code, please add it first ',
    您是否记得账号当前使用的支付密码: 'Do you remember the payment password currently used by your account?',
    您未设置支付密码请先设置: 'You have not set a payment password, please set it first',
    钱包余额: 'wallet balance',
    请输入开户人: 'Please enter the account holder',
    请输入开户支行: 'Please enter issuing bank branch',
    请输入收款名称: 'Please enter the payment name',
    请输入提现金额: 'Please enter the withdrawal amount',
    请输入新支付密码6位数字: 'Please enter the new payment password, 6 digits',
    请输入新支付密码: 'Please enter new payment password',
    请输入银行卡号: 'Please enter the bank card number',
    请输入原支付密码: 'Please enter the original payment password',
    请输入支付密码6位数字: 'Please enter the payment password, 6 digits',
    请输入支付密码: 'Please enter the payment password ',
    请选择币种: 'Please select currency',
    请选择大洲: 'Please select a continent',
    请选择结束时间: 'Please select an end date',
    请选择开始时间: 'Please select a start date',
    请选择收款码类型: 'Please select the payment code type',
    请选择银行: 'Please select a bank',
    请选择银行名称: 'Please select bank name',
    请再次输入新支付密码: 'Please enter the new payment password again',
    请再次输入支付密码: 'Please enter the payment password again',
    去设置: 'Go to settings',
    全部提现: 'Withdraw All',
    确定删除收款码吗: 'Are you sure you want to delete the payment code?',
    确定删除银行卡吗: 'Are you sure you want to delete the bank card?',
    确定提现: 'Confirm withdrawal',
    确认打款: 'Confirm Payment',
    确认新密码: 'Please enter the new payment password again',
    确认支付密码: 'Confirm payment password',
    上传的收款二维码需要清晰无遮挡: 'The uploaded payment QR code must be clear and unobstructed',
    审核备注: 'Review Remarks',
    审核不通过: 'Audit not passed',
    审核结果: 'Review Result',
    审核通过: 'Review Passed',
    审核信息: 'Review information',
    时间: 'Time',
    实际到账金额: 'Actual amount received',
    收款方式: 'payment method',
    收款管理: 'Collection management',
    收款码: 'Payment code',
    收款码管理: 'Payment code management',
    收款码类型: 'Payment code management',
    收款名称: 'Payee name',
    收款账号: 'Payment account number',
    手续费: 'Withdrawal Fee',
    提现: 'Withdraw',
    提现币种: 'Withdrawal currency',
    提现成功: 'Successful Withdrawal',
    提现记录: 'Withdrawals record',
    提现金额: 'Withdrawal Amount',
    提现失败: 'Withdrawal Failed',
    提现时间: 'Withdrawal time',
    提现手续费: 'Withdrawal fee',
    提现详情: 'Withdrawal details',
    提现中: 'Withdrawing',
    提现状态: 'Withdrawal Status',
    添加收款码: 'Add payment code',
    添加银行卡: 'Add a bank card',
    退款: 'Refund',
    忘记支付密码: 'Forgot your payment password?',
    尾号: 'tail number',
    我的钱包: 'My purse',
    我的余额: 'My balance',
    详情: 'Details',
    消费: 'Consumption',
    新支付密码: 'New payment password',
    修改支付密码: 'Change payment password',
    选择收款方式: 'Select payment method',
    银行卡: 'Bank Card',
    银行卡管理: 'Bank card management',
    银行卡号: 'Card No.',
    银行名称: 'Bank name',
    余额记录: 'Balance record',
    余额数据: 'Balance data',
    原支付密码: 'Original payment password',
    暂无数据: 'No data',
    支持币种: 'Currencies Supported',
    支付密码: 'Payment password',
    只可上传一张图片图片大小不超过5M支持jpgpngjpeg格式文件: 'Only one image can be uploaded, the image size does not exceed 5M, supports jpg, png, jpeg format files',
    账户余额: 'Account Balance',
    钱包服务: 'Wallet service',

    每天最多提现x次单次最高提现金额xx最低提现金额xx: 'The maximum withdrawal amount per day is {num} times, the maximum withdrawal amount per time is {icon}{money}, and the minimum withdrawal amount is {icon}{money2}',
    确认: 'Confirm',
    输入金额超过可提现余额: 'The input amount exceeds the withdrawable balance',
    日期选择: 'date selection',
    备注: 'Remark',
    当前没有余额记录: 'There is currently no balance record!',
    当前没有提现记录: 'There is currently no withdrawal record!',
    还没有绑定银行卡: 'No bank card has been bound yet!',
    还没有添加收款码: 'No payment code has been added yet!',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡: 'Tip: Only one picture can be uploaded, the size of the picture should not exceed 5M, and jpg, png, and jpeg format files are supported. The uploaded payment QR code must be clear and unobstructed.',
    请上传收款码: 'Please upload the payment code',
    请输入收款码名称: 'Please enter the payment code name',
    暂无银行卡: 'No bank card yet',
    余额: 'balance',
    重置: 'Reset',
    暂无收款码: 'No payment code yet',
    保障支付安全: 'Ensure payment security',
    打款成功: 'Payment successful',
    请选择收款方式: 'Please select payment method',
    收款类型: 'Payment type',
    编辑银行卡: 'Edit bank card',
    编辑收款码: 'Edit payment code',
    收款码名称: 'Payment code name',
    订单ID: 'Order ID',
    如果您已支付成功请点击确认支付按钮: 'If your payment has been successful, please click the "Confirm Payment" button',
    确认支付: 'Confirm Payment',
    支付遇到问题: 'Encounter problems',
    对不起您访问的店铺可能已被删除名称被更改或不存在: 'Sorry, the store you visited may have been deleted, its name has been changed, or it may not exist...',
    卢比支付: 'IDR payment',
    比索支付: 'PHP payment',
    泰铢支付: 'THB payment',
    越南盾支付: 'VND payment',
    银行卡编码: 'Bank code',
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0": "{currencyUnit}{money}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1": "First Standard Weight{num}kg{currencyUnit}{money}，Additional Weight{num2}kg{currencyUnit}{money2}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2": "First Parcel{num3}pieces{currencyUnit}{money3}，Additional Parcels{num4}pieces{currencyUnit}{money4}",
    暂无配送地区: 'No delivery area yet',
    该商品在当前地区暂不支持配送: 'This product does not currently support delivery in the current region',
    当前商品仅支持以下地区发货如有疑问请联系店铺:
        'The current products only support delivery to the following areas. If you have any questions, please contact the store',
    运费计价说明: 'Freight pricing instructions',
    发货地: 'Shipping Place',
    配送至: 'Deliver to',
    打款中: 'Payment in progress',
    未添加地址请先添加: 'No address added, please add it first',
    请输入大于1的正整数: 'Please enter a positive integer greater than 1',
    设置密码: 'Set password',
    登录密码: 'Login password',
    长度在8到16个字符: '8-16 characters',
    请输入旧密码: 'Please enter the password',
    必须包含两种字符: 'including 2 character types',
    请再次输入密码: 'Please enter the password again',
    更多: 'More',
    最近新品: 'New Products',
    促销广告: 'Promotional advertising',
    查看运费: 'View shipping costs',
    运费方式: 'Shipping method',
    价格: 'price',
    请选择配送方式: 'Please select a shipping method',
    配送方式: 'Delivery Method',
    配送费说明: 'Delivery fee description',
    我明白了: 'I see',
    设置成功: 'Setup successful',
    若多个商品选用同一个运费模板则运费是按同一个运费方式的规则进行计算: 'If multiple products use the same freight template, the freight will be calculated according to the rules of the same freight method.',
    若多个商品不是同一个运费模板则运费是多个模板的价格之和: 'If multiple products do not have the same shipping fee template, the shipping fee is the sum of the prices of the multiple templates.',
    快捷登录登录即表示同意协议: 'By logging in you agree to the agreement',
    当前仅支持xxx的金额: 'Currently only supports amounts from {currency} {min}-{currency} {max}',
    更多商品:'More products',


    //     1.6
    我的优惠券:'My discount coupon',
    历史记录:'History record',
    已使用:'Used',
    已过期:'Expired',
    优惠券:'Coupon',
    领取:'Receive',
    已领取:'Received',
    有效期:'Validity period：',
    活动优惠详情:'Event discount details',
    可用商品:'Available items',
    单独购买:'Original price',
    发起拼团:'Group price',
    拼团价:'Group price',
    可参与的拼团:'Available group buyings',
    '再邀X人即可拼单成功':'Share with {num} more people for successful order>',
    拼单详情:'Group purchase details',
    待分享:'To be shared',
    '若拼团失败，货款将原路退回':'If the group purchase fails, the payment will be refunded.',
    促销价:'Promotional price',
    距离结束时间:'End in',
    满XX减XX:"Spend {currencyUnit}{priceStart} get {currencyUnit}{reducePrice}{couponType==2?'%':''} off" ,
    满XX减X:'Spend {currencyUnit}{priceStart} get {reducePrice}% off',
    分享好友X人成团立省X:'Group purchase for<span style="color: #8D4BBB;">{num}</span>people，save<span style="color: #8D4BBB;">{currencyUnit}{groupPrice}</span>',
    分享好友:'Share with friends',
    '这些人正在拼，立即参与 ':'Group buying in progress, join now ',
    满x可用:'Available for orders over {currencyUnit}{priceStart}',
    这些人正在拼立即参与:'Group buying in progress, join now ',
    限领x张:'{receiveCount} coupons per user limit',
    满XX送赠品:'Spend {currencyUnit}{priceStart} get free gift',
    去使用:'To use',
    销量:'Sales',
    赠品:'Giveaway',
    限时优惠:'Limited Time Offer',
    距结束:'End in',
    拼团好物:'Group Buying',
    当前优惠券可以购买以下商品:'The current coupon can purchase the following products:',
    以下商品:'The following products',
    满XX减XX可用:"Spend {currencyUnit}{priceStart} get {currencyUnit}{reducePrice} off",
    满XX减X可用:'Spend {currencyUnit}{priceStart} get {reducePrice}% off',
    再买x可用:'Available for orders over {currencyUnit}{difPrice}',
    合计:'Total',
    优惠:'Discount',
    去购物车:'To cart',
    拼团:'Group Buying',
    满XX可用:'Available for orders over {currencyUnit}{priceStart}',
    满减满赠:'Full discount/full gift',
    成团发货:'Wait for delivery',
    还差xx人成团:'Still need<span style="color: #FF4545;">{num}</span>people to place orders successfully',
    拼团结束:'Group buying ends',
    拼团成功:'Group purchase successful',
    有成员未付款请等待付款成团:'Someone has not paid, please wait for payment',
    我正在参与的拼团:'Participating in group buying',
    满减:'Full discount',
    满赠:'Full gift',
    凑单:'Filler items',
    切换其他优惠:'Select another offer',
    超过最大购买数:'Exceeded maximum number of purchases',
    商品限购x件:'Limit {num} pieces',
    参与拼团:'Join',
    结束:'结束',
    您还没有优惠券:"You don't have a coupon",
    优惠券历史记录:'History record',
    拼单失败:'Group purchase failed',
    免费:'Free',
    已赠完:'Out of stock',
    此拼团已满请选择其他拼团:'This group purchase is full, please choose another group purchase',
    以下商品正在限时优惠当中:'Limited time offer on the following products',
    以下商品正在限时拼团当中:'Limited time group purchase of the following products',
    再买X可减X:'Buy another {currencyUnit}{difPrice} and get {currencyUnit}{reducePrice} off',
    已领完:"Used up",
    活动未开始:'The event has not started',

    已满X可减X:'Discount {currencyUnit}{difPrice} for purchases over {currencyUnit}{reducePrice}',
    已满X可减XX:'Discount {reducePrice}% for purchases over {currencyUnit}{difPrice}',

    再买X可减XX:'Buy another {currencyUnit}{difPrice} and get {reducePrice}% off',
    再买X送赠品:'Buy another {currencyUnit}{difPrice} and get a free gift',
    已满X送赠品:'Gifts have been given',
    缺少库存: 'out of stock',
    限X件:'Purchase limit {num} pieces',
    房间号已失效请联系工作人员:'The room number has expired, please contact the staff!',


    退货积分:'Return points',
    积分:'points',
    用户注册:'User registration',
    手动增加:'Manually increase',
    积分兑换退款:'Points refund',
    下单购物:'Place an order for shopping',
    过期积分:'Expired points',
    兑换商品:'Redeem goods',
    手动减少:'Manual reduction',
    订单退款:'Order refund',
    积分明细:'Points details',
    已兑:'Already redeemed',
    积分商城:'Points Mall',
    规则:'rule',
    可用积分:'Points Available',
    冻结积分:'freeze points',
    我的兑换:'My exchange',
    售后:'After-sales',
    分销中心:'Distribution center',
    立即兑换:'Redeem now',
    已兑完:'Already redeemed',
    "积分不足，无法兑换":'Insufficient points cannot be redeemed',
    兑换数量:'Exchange quantity',
'限兑*件':'Limited redemption {num} items',
    积分抵扣:'Points deduction',
'共*分':'Total {num} points',
    全部订单:'All orders',

兑换须知:'Redemption instructions',
    已达兑换上限:'The redemption limit has been reached',
    我的积分:'My scores',
    积分规则:'Points rules',
    兑换时间:'Redemption time',
    我的佣金:'my commission',
    佣金数据:'Commission data',
    分销申请协议:'Distribution Application Agreement',
    申请成为分销员:'Apply to become a distributor',
    "已申请，请等待审核":'Already applied, please wait for review',
    "审核拒绝，继续申请":'Rejected by review, continue to apply',
    可提佣金:'Withdrawal commission',
    冻结佣金:'Freeze commission',
    分销订单:'Distribution order',
    佣金明细:'Commission details',
    我的下级:'My subordinates',
    推广商品:'Promote products',
    推广店铺:'Promote store',
    暂无记录:'No records',
    一级佣金收入:'First level commission income',
    一级佣金退款:'First level commission refund',
    二级佣金收入:'Secondary commission income',
    二级佣金退款:'Second level commission refund',
    下单用户:'Order placing user',
    订单总价:'Order total price',
    分销利润层级:'Distribution level',
    结算状态:'settlement status',
    佣金:'commission',
    一级:'Level 1',
    二级:'Level 2',
    已结算:'Settled',
    请输入用户昵称或手机号:'Please enter user nickname or mobile phone number',
    二级团队:'Second level team',
    分享链接:'Share link',
    可推广至:'Can be extended to',
    分享最高可赚x:'Earn up to {icon}{num} by sharing',
    分享推广:'Share and promote',
    用户昵称或手机号:'User nickname or mobile phone number',
    商品名称:'product name',
    最高可得:'Highest available',
    可获得积分:'Points earned',
    总获佣金:'Total commission earned',

    佣金详情:'Commission details',
    已退款:'refunded',
    请输入商品名称:'Please enter product name',
    请选择银行卡: 'Please select a bank card',
    请选择收款码: 'Please select payment code',
    不能超过最小提现额度:'The minimum withdrawal amount cannot be exceeded!',


    货柜商品:'Container goods',
    该浏览器不支持蓝牙:'This browser does not support Bluetooth, please copy the link to Chrome, Samsung Internet browser to open',
    iOS系统暂不支持售货机取货请使用Android系统扫码购物:'Please use the Android system to scan the QR code to shop. For the iOS system, please click "Go to the mall homepage" to shop, and a dedicated person will deliver it to your door. Thank you for your understanding!',
    去商城首页:'Go to the mall homepage',
    已售完:'Sold out',
    蓝牙未连接:'Bluetooth not connected',
    蓝牙已连接:'Bluetooth is connected',
    重试:'Try again',
    蓝牙未授权请打开蓝牙后重试:'Bluetooth is not authorized, please turn on Bluetooth and try again!',
    蓝牙未连接请选择蓝牙并配对:'Bluetooth is not connected, please select Bluetooth and pair!',
    连接:'connect',
    蓝牙已断开请重新连接:'Bluetooth has been disconnected, please reconnect',
    蓝牙连接中:'Bluetooth connection...',
    购买成功请取出商品若柜门未打开请尝试再次打开:'The purchase is successful, please take out the product! If the cabinet door does not open, please try opening it again!',
    再次打开:'Open again',
    普通订单:'Ordinary order',
    售货机:'vending machine',
    售货机已无货无法支付:'The vending machine is out of stock and payment cannot be made.',
    订单来源:'Order source',
    连接失败请重新连接:'Connection failed, please reconnect',
    售罄:'sold out',
    '房间号/桌号':'Room number/table number',
    "柜门开启中···": "The cabinet door is opening...",
    "微信不支持蓝牙，请点击":'WeChat browser does not support Bluetooth, please click',
    "选择在Google或Samsung Internet浏览器中打开":'Choose to open in Google<img src="https://saasimage.bawanli.com/users/2024-11-01/485b6a31-fe3e-4b07-b42a-71f3e2462ce2.png" style="width: 30px;height: 30px;margin:0 4px;"> or Samsung Internet browser <img src="https://saasimage.bawanli.com/users/2024-11-01/805ed567-6a5b-47fc-a053-e1c2f50b67f6.png" style="width: 30px;height: 30px;margin:0 4px;">,<span style="color: red;">If you do not have the above browser, please click the link below to download: <a href="https://saasimage.bawanli.com/users/2024-11-01/Chrome.apk">【Chrome】</a><a href="https://saasimage.bawanli.com/users/2024-11-04/Samsung.apk">【Samsung Internet】</a></span>',
    "复制链接":'Copy link',
    "通过在线商城购买的订单，哇呗小店将配送上门，配送时间：06:00-23:00":'Orders purchased through the online mall will be delivered to your doorstep by Wabei Store. Delivery time: 06:00-23:00',
    "我已知晓":'I already know'
}
//英语
