import request from '@/utils/request'

//蓝牙商品api
//蓝牙页面
export function listStoreSellGoods(params) {
    return request({
        url: '/userConsumer/userGoods/listStoreSellGoods',
        method: 'post',
        params
    })
}
// 商品详情
export function getStoreSellGoods(params) {
    return request({
        url: '/userConsumer/userGoods/getStoreSellGoods',
        method: 'post',
        params
    })
}
// 创建订单
export function placeStoreSellGoods(params) {
    return request({
        url: '/userConsumer/userOrder/placeStoreSellGoods',
        method: 'post',
        params
    })
}
export function updateStoreSellVoltage(params) {
    return request({
        url: '/userConsumer/userGoods/updateStoreSellVoltage',
        method: 'post',
        params
    })
}

// 埋点记录 蓝牙 房间id
export function recordUserScan(params) {
    return request({
        url: '/userConsumer/users/recordUserScan',
        method: 'post',
        params
    })
}
// app更新
export function checkAppUpdate(params) {
    return request({
        url: '/userConsumer/store/getAppVersion',
        method: 'post',
        params
    })
}
