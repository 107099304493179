import axios from 'axios'
import Qs from 'qs'
import { Toast } from 'vant'
import store from '@/store'
import router from '@/router/index.js'
import { gettoken, removetoken } from '@/utils/auth'
import { environments } from './env'
const { apiUrl } = environments[environments.apiNow]
console.log(process.env)
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 90000
})
let   loadingInstance
//拦截器  -请求拦截
http.interceptors.request.use(
  config => {
    if (!config.noLoading) {
      loadingInstance = Toast.loading({
        fullscreen: true,
        background: 'rgb(0,0,0, .1)',
        message: window.$i18n.t('加载中')
      })
    }

    config.headers['clientId'] = 'users'
    config.headers['storeId'] = localStorage.getItem('storeID')
    config.headers['lang'] = localStorage.getItem('lang')
    config.headers['currencyId4Font'] = localStorage.getItem('currency')
    config.headers['mode'] = 'mobile'
      if(config.url=='/userConsumer/userGoods/getFrontGoods4Id'||config.url=='/userConsumer/userGoods/userListFrontGoodsStock'){
          if(config.params?.teamworkID){
              config.headers['groupId']=config.params?.teamworkID
          }
      }

    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('micromessenger')) {
      config.headers['mySource'] = '1';
    } else if (userAgent.includes('chrome')) {
      config.headers['mySource'] = '2';
    } else if (userAgent.includes('facebook')) {
      config.headers['mySource']= '3';
    } else if (userAgent.includes('whatsapp')) {
      config.headers['mySource']= '4';
    } else {
      config.headers['mySource']= '5';
    }

    // if(!localStorage.getItem('storeID')){
    //   return router.push('/404')
    // }
    if (navigator.userAgent.match(/(iPhone|iPod|iPad)/)) {
      // iOS设备，使用Canvas2Image保存到相册
      config.headers['mobile'] = 'h5'
        if(config.url=='/userConsumer/users/recordUserScan'){
            config.headers['mobile'] = 'ios'
        }
    }else{
      config.headers['mobile'] = 'h5'
        if(config.url=='/userConsumer/users/recordUserScan'){
            config.headers['mobile'] = 'Android'
        }
    }
    if(config.url=='/payCon/userOrderPay/bcelPayNotify'){
      config.headers['X-ft-Sign'] = localStorage.getItem('signS')
    }

    if (
      config.method &&
      config.method.toLocaleLowerCase() == 'get' &&
      config.data
    ) {
      config.url = config.url + '?' + Qs.stringify(config.data)
    }
    if (store.state.user.token) {
      config.headers['Authorization'] = gettoken()
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

//拦截器  -响应拦截
http.interceptors.response.use(
  response => {
    const res = response.data
    if (router.currentRoute.path === '/404') {
      // 如果当前页面是404页面，则不执行后续逻辑
      return Promise.resolve(response);
    }
    // response.config.url != '/goodsConsumer/utils/ImgUpload'
    if (response.config.url != '/threeTerminalCon/utils/ImgUpload'&&!response.config.noLoading) {
      loadingInstance && loadingInstance.clear()
    }
    const whiteList = ['getImageCode','recordUserScan']
    if (whiteList.find(url => response.config.url.indexOf(url) >= 0)) {
      return Promise.resolve(response)
    }
    if (res.code !== 200) {
      //token过期
      if (res.code === 200435||res.code==20999) {
        removetoken()
        localStorage.removeItem('userData')
          localStorage.removeItem('parentUserId')
        store.dispatch('user/removetoken')
        store.dispatch('order/Seaddress', null)
        store.commit('locshoppingcar/lookloacshoppingcarnum')
        router.push('/saas/login?storeId='+localStorage.getItem("storeID"))
        return Promise.reject(new Error(res.msg || 'Error'))
      } else if (res.code === 200500) {
        removetoken()
        store.dispatch('user/removetoken')
        store.dispatch('order/Seaddress', null)
        store.commit('locshoppingcar/lookloacshoppingcarnum')
        store.dispatch('loginfalse/updataorder', '1')
        router.push('/404')
      } else if (res.code === 200491) {
        removetoken()
        localStorage.removeItem('userData')
          localStorage.removeItem('parentUserId')
        store.dispatch('user/removetoken')
        store.dispatch('order/Seaddress', null)
        // router.push('/login?storeId='+localStorage.getItem("storeID"))
        store.commit('locshoppingcar/lookloacshoppingcarnum')
        return Promise.reject(new Error(res.msg || 'Error'))
      } else if (res.code === 200497) {
          Toast({
              message: res.data||res.msg,
              type: 'fail'
          })
        return Promise.resolve(res)
      } else if (res.code === 200469) {
        return Promise.resolve(res)
      }else if (res.code ===200001&&response.config.url.indexOf('checkPay') >= 0){
        return Promise.resolve(res)
      }else if (res.code ===200514&&response.config.url.indexOf('checkPay') >= 0){
          return Promise.resolve(res)
      }else if (res.code === 200498) {
        setTimeout(() => {
          Toast({
            message: res.data||res.msg,
            type: 'fail'
          })
        })

      } else if (res.code === 200426) {
        setTimeout(() => {
          Toast({
            message: window.$i18n.t('请填写完整必填项'),
            type: 'fail'
          })
        }, 200)
      }else if(res.code===200444){
          return Promise.resolve(res)
      } else {
        setTimeout(() => {
          Toast({
            message: res.message || res.msg || 'Error',
            type: 'fail'
          })
        }, 200)
      }
      return Promise.resolve(res)
    } else {
      return Promise.resolve(res)
    }
  },
  err => {
    return Promise.reject(err)
  }
)

export default http
