import request from '@/utils/request'

// 微信支付获取url
export function wxPayUrl(params) {
  return request({
    url: '/userConsumer/front/auth/wxPayUrl',
    method: 'get',
    params
  })
}

// 微信支付获取openId
export function wxPayBack(params) {
  return request({
    url: '/userConsumer/front/auth/wxPayBack',
    method: 'get',
    params
  })
}

// 用户下单
export function placeAnOrder(params) {
  return request({
    url: '/payCon/userOrderPay/placeAnOrder',
    method: 'post',
    params,
    noLoading:true
  },)
}

// 用户查询是否支付 bcel
export function placeAnOrderBcel(params) {
  return request({
    url: '/payCon/userOrderPay/placeAnOrderBcel',
    method: 'post',
    params,
    noLoading:true
  })
}

// 用户下单 kbz bcel  货币转换
export function currencyConversion(params) {
  return request({
    url: '/userConsumer/userOrder/currencyConversion',
    method: 'post',
    params
  })
}

// 前端 支付状态查询
export function checkPay(params) {
  return request({
    url: '/payCon/userOrderPay/checkPay',
    method: 'post',
    noLoading:true,
    params
  })
}

// 修改订单状态
export function userUpdateOrderStatus(params) {
  return request({
    url: '/userConsumer/userOrder/userUpdateOrderStatus',
    method: 'post',
    params
  })
}

// 用户订单查询
export function listOrder4User(params) {
  return request({
    url: '/userConsumer/userOrder/listOrder4User',
    method: 'post',
    params
  })
}
// 根据ID查询订单
export function getOrder4Id(params) {
  return request({
    url: '/userConsumer/userOrder/getBigOrder4NotPay',
    method: 'post',
    params
  })
}
// 根据ID查询订单
export function getOrder4Id1(params) {
  return request({
    url: '/userConsumer/userOrder/getOrder4Id',
    method: 'post',
    params
  })
}
// 售后申请
export function getReturnGoods(params) {
  return request({
    url: '/userConsumer/userOrderReturn/getReturnGoods',
    method: 'post',
    params
  })
}

// 确认收货与取消订单
export function confirmGoods(params) {
  return request({
    url: '/payCon/userOrderPay/confirmGoods',
    method: 'post',
    params
  })
}


//保存退货单
export function saveReturnOrder(params) {
  return request({
    url: '/userConsumer/userOrderReturn/saveReturnOrder',
    method: 'post',
    params
  })
}
//获取用户退货详情列表
export function listReturnOrder(params) {
  return request({
    url: '/userConsumer/userOrderReturn/listReturnOrder',
    method: 'post',
    params
  })
}
// 退货单详情
export function getReturnOrder4Id(params) {
  return request({
    url: '/userConsumer/userOrderReturn/getReturnOrder4Id',
    method: 'post',
    params
  })
}

// 添加商榷记录
export function saveNegotiationRecords(params) {
  return request({
    url: '/userConsumer/userOrderReturn/saveNegotiationRecords',
    method: 'post',
    params
  })
}

// 用户取消售后
export function cancelAftermarket(params) {
  return request({
    url: '/userConsumer/userOrderReturn/cancelAftermarket',
    method: 'post',
    params
  })
}

//图片
export function ImgUpload(data) {
  return request({
    url: '/threeTerminalCon/utils/ImgUpload',
    // url: '/goodsConsumer/utils/ImgUpload',
    method: 'post',
    noLoading:true,
    data
  })
}


// 确认收货
export function confirmReceipt(data) {
  return request({
    url: '/userConsumer/userOrder/confirmReceipt',
    method: 'post',
    data
  })
}

//查询店铺支付说明
export function getStorePayContent(params) {
  return request({
    url: '/userConsumer/userOrder/getStorePayContent',
    method: 'post',
    params
  })
}

// BCL 支付回调
export function bcelPayNotify(params) {
  return request({
    url: '/payCon/userOrderPay/bcelPayNotify',
    method: 'post',
    params
  })
}
