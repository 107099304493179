<template>
  <div class="hello">
<!--    <div style="height: 94px;"></div>-->
    <van-tabbar v-model="active" active-color="#8D4BBB" inactive-color="#999999" placeholder >
      <van-tabbar-item :to="{ path: '/saas/', query: { storeId: storeID } }">
        <span>{{$t('首页')}}</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item :to="{ path: '/saas/goods', query: { storeId: storeID } }">
        <span style="width:.2rem">{{$t('分类')}}</span>
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item :to="{ path: '/saas/shoppingCart', query: { storeId: storeID } }"
        :badge="this.$store.state.user.token&&Num>0 ? Num : ''">
        <span>{{$t('购物车')}}</span>
        <template #icon="props">
          <img :src="props.active ? icon2.active : icon2.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item :to="{ path: '/saas/usermy', query: { storeId: storeID } }">
        <span>{{$t('我的')}}</span>
        <template #icon="props">
          <img :src="props.active ? icon3.active : icon3.inactive" />
        </template>
      </van-tabbar-item>

    </van-tabbar>
  </div>
</template>

<script>
import homeImgAct from '@/assets/编组@2x 拷贝 7@2x.png'
import homeImg from '@/assets/编组@2x 拷贝 4@2x.png'
import moreImg from '@/assets/编组@2x@2x.png'
import moreImgAct from '@/assets/编组@2x 拷贝 9@2x.png'
import cartImg from '@/assets/容器@2x 拷贝@2x.png'
import cartImgAct from '@/assets/容器@2x@2x.png'
import { mapGetters } from "vuex";
import userImg from '@/assets/编组@2x 拷贝@2x.png'
import userImgAct from '@/assets/编组@2x@2x (1).png'

export default {
  name: 'homeTabbar',
  props: {
    act: Number
  },
  computed: {
    ...mapGetters(["Num", "locNum"]),
    shopNumber() {
      return this.$store.state.shoppingcar.shopnumber;
    }
  },
  watch: {
    act(newValue) {
      this.active = newValue
    },


  },
  data() {
    return {
      active: this.act,
      icon: {
        active: homeImgAct,
        inactive: homeImg,
      },
      icon1: {
        active: moreImgAct,
        inactive: moreImg,
      },
      icon2: {
        active: cartImgAct,
        inactive: cartImg,
      },
      icon3: {
        active: userImgAct,
        inactive: userImg,
      },
      storeID: localStorage.getItem("storeID"),
      goodsCart: this.$store.state.shoppingcar.shopnumber
    };
  },
  created() {
    this.creadshoppinglist()
  },
  methods: {
    // 获取购物车列表
    async creadshoppinglist() {
      if (this.$store.state.user.token) {
        let a = {
          userId: this.$store.state.user.personaluser.id,
          storeId:localStorage.getItem("storeID")
        };

        await this.$store.dispatch("shoppingcar/inquireshoppinglist", a);
      } else {
        this.$store.commit("locshoppingcar/lookloacshoppingcarnum");
      }
    },
  }
}
</script>


<style scoped lang="scss">
/deep/ .van-tabbar{
  height: 60px;
}
/deep/ .van-tabbar-item__text{
  width: 70px;
  text-align: center;
}
</style>
