import { gettoken, settoken } from '@/utils/auth'
import { login, userList } from '@/API/user'
import { getStoreIndexInfo } from "@/API/listFront";
const state = {
  Bannerid: localStorage.getItem('Bannerid'),
  Bannergoods: localStorage.getItem('Bannergoods'),
  // 从cookie获取token
  token: gettoken(),
  // localStorage获取个人信息
  personaluser: JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : '',
  // 导航栏ID
  tablists: localStorage.getItem('TablistID'),
  // 店铺信息
  logotable: {}
}
const mutations = {
  // 修改token
  set_tokens(state, value) {
    state.token = value
  },
  // 修改个人信息
  set_personaluser(state, value) {
    state.personaluser = value
  },
  remove_tokens(state) {
    state.token = ''
  },
  remove_personaluser(state,) {
    state.personaluser = {}
  },
  // 轮播图ID
  ser_Bannerid(state, value) {
    state.Bannerid = value
  },
  ser_Bannergoods(state, value) {
    state.Bannergoods = value
  },

  ser_TablistID(state, value) {
    state.tablists = value
  },
  ser_logotable(state, value) {
    state.logotable = value
  }
}
const actions = {
  removetoken(context,) {
    context.commit('remove_tokens')
    context.commit('remove_personaluser')
  },
  //登录后获取个人信息和token
  async getokens(context, value) {
    try {
      if(!localStorage.getItem('xcxSource')){
        let { data } = await login(value)
        // token存储到cookies
        settoken(data.access_token)
        // state中存入token
        context.commit('set_tokens', data.access_token)
      }
      console.log(value,localStorage.getItem('xcxSource'),'xcxSource')
      if(localStorage.getItem('xcxSource')=='xcx'){
        // token存储到cookies
        settoken(value)
        // state中存入token
        context.commit('set_tokens', value)
      }
      // 个人信息存储到本地
      let c = {
        storeId: localStorage.getItem('storeID')
      }
      let userdata = await userList(c)
      localStorage.setItem('userData', JSON.stringify(userdata.data))
      //  state中存入个人信息
      context.commit('set_personaluser', userdata.data)
    } catch (error) {
      return
    }
  },
  //  存入轮播图ID
  Bannerid(context, value) {
    localStorage.setItem('Bannerid', value)
    context.commit('ser_Bannerid', value)
  },
  //  存入轮播图商品ID
  Bannergoods(context, value) {
    localStorage.setItem('Bannergoods', value)
    context.commit('ser_Bannergoods', value)
  },
  //  存入TAB导航栏
  TablistID(context, value) {
    localStorage.setItem('TablistID', value)
    context.commit('ser_TablistID', value)
  },
  async logoTable(context) {
    let data1 = {
      storeId: localStorage.getItem("storeID"),
    };
    let  data2  = await getStoreIndexInfo(data1);
    let data=data2.data.storeIndexInfo
    localStorage.setItem('storeIndexInfo', JSON.stringify(data2.data))
    document.title = data.title;
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = data.storeLogo;
    document.getElementsByTagName("head")[0].appendChild(link);
    context.commit('ser_logotable', data2.data)
  }
}
const getters = {

}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

