import {updateStoreSellVoltage} from "@/API/bluetooth";
const state = {
    isItConnect: false,//蓝牙状态判断
    connectingInProgress: false,//蓝牙状态判断
    doYouWantToRefresh: false,//刷新当前页面
    bluetoothInterval: null,//监听
    ExampleTip:false
}
const mutations = {
    // 修改蓝牙状态判断
    set_isItConnect(state, value) {
        console.log(value,'value修改蓝牙状态判断')
        state.isItConnect = value
    },
    // 修改蓝牙状态判断
    set_connectingInProgress(state, value) {
        state.connectingInProgress = value
    },
    // 修改刷新当前页面判断
    set_doYouWantToRefresh(state,value) {
        state.doYouWantToRefresh = value
    },
    set_bluetoothInterval(state,value) {
        state.personaluser = {}
    },
    set_exampleTip(state,value){
        console.log(value,'exampleTip')
        state.ExampleTip = value
    },
}
const actions = {
    //  修改蓝牙状态判断
    setIsItConnect(context, value) {
        context.commit('set_isItConnect', value)
    },
    // 修改示例图展示
    setExampleTip(context,value){
        context.commit('set_exampleTip', value)
    },
    //  修改蓝牙状态判断
    setConnectingInProgress(context, value) {
        context.commit('set_connectingInProgress', value)
    },
    //  修改刷新当前页面判断
    setDoYouWantToRefresh(context, value) {
        context.commit('set_doYouWantToRefresh', value)
    },
  async  updateStoreSellVoltage(context, value){
      let storedData = JSON.parse(localStorage.getItem('storeSell'))
      let res = await updateStoreSellVoltage({
          id: storedData.storeSellId,
          voltage: value
      })
      if (res.code == 200) {

      }
    },
}
const getters = {

}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

