export default {
    BCEL支付: 'pembayaran BCEL',
    KBZ支付: 'pembayaran KBZ',
    OnePay支付: 'Pembayaran OnePay',
    OnePay支付二维码: 'Pembayaran OnePay',
    保存: 'menyimpan',
    保密: 'Rahasia',
    备注说明: 'instruksi manual',
    编辑地址: 'Sunting alamat',
    标记已读: 'tandai sebagai membaca',
    不可修改最多: 'Yang paling tidak bisa dimodifikasi',
    部分订单取消: 'Pesanan sebagian dibatalkan',
    部分发货: 'Pengiriman sebagian',
    部分商品正在退款中: 'Beberapa item sedang dikembalikan',
    部分收货: 'Penerimaan sebagian',
    部分退款: 'Pengembalian dana sebagian',
    部分退款完成: 'Pengembalian dana sebagian selesai',
    查看订单: 'periksa pesanan',
    查看全部: 'Lihat semua',
    查看全部xx条回复: 'Lihat semua {num} balasan',
    查看详情: 'periksa detailnya',
    查看支付是否成功: 'Periksa apakah pembayaran berhasil>>>',
    查询中: 'Menanyakan',
    城市: 'Kota',
    创建时间: 'Waktu penciptaan',
    此宝贝已下架: 'stok Habis',
    待发货: 'untuk dikirimkan',
    待付款: 'Pembayaran menunggu keputusan',
    待收货: 'Menunggu Tanda Terima',
    当前安全手机号: 'Nomor ponsel aman saat ini',
    地址管理: 'Nomor ponsel aman saat ini',
    登录: 'Masuk log in',
    等待店铺审核: 'Menunggu ulasan toko',
    点击选择城市: 'Klik untuk memilih kota',
    点击选择国家: 'Klik untuk memilih negara',
    点击选择退货类型: 'Klik untuk memilih jenis pengembalian',
    点击选择退货原因: 'Klik untuk memilih alasan pengembalian',
    店: 'toko',
    店铺: 'toko',
    店铺处理: 'Pemrosesan toko',
    店铺拒绝您的申请: 'Toko menolak aplikasi Anda',
    店铺退款: 'Pengembalian dana toko',
    订单编号: 'jumlah order',
    订单号: 'jumlah order',
    订单详情: 'Detail pesanan',
    订单已超时: 'Waktu pemesanan telah habis',
    短信验证码: 'Kode verifikasi SMS',
    发表留言: 'Tinggalkan pesan',
    发货: 'Mengirimkan',
    发货件数: 'Jumlah potongan yang dikirim',
    发货时间: 'Waktu pengiriman',
    发货状态: 'Status pengiriman',
    发送成功: 'Berhasil dikirim',
    发送验证码: 'Kirim kode verifikasi',
    法律声明: 'Pemberitahuan Hukum',
    返回: 'kembali',
    返回首页: 'Kembali ke beranda',
    分: 'titik',
    分类: 'Klasifikasi',
    付款时间: 'Waktu pembayaran',
    复制: 'menyalin',
    复制成功: 'Berhasil disalin',
    该订单已取消: 'Pesanan telah dibatalkan',
    个人信息: 'informasi pribadi',
    个字以内: 'Dalam karakter',
    恭喜您邮箱地址验证成功: 'Selamat atas keberhasilan verifikasi alamat email Anda',
    购买商品: 'membeli barang',
    购买数量: 'Kuantitas pembelian',
    购买信息: 'Kuantitas pembelian',
    购物车: 'kereta Belanja',
    国家区域: 'wilayah negara',
    含发货邮费: 'Termasuk ongkos kirim',
    欢迎登录: 'Silakan masuk',
    活动优惠: 'Diskon acara',
    货币: 'membeli barang',
    继续逛逛: 'Tetap berbelanja',
    加入购物车: 'tambahkan ke troli',
    加载中: 'memuat',
    价格高低: 'tingkat harga',
    价格最低: 'harga terendah',
    价格最高: 'harga tertinggi',
    件商品: 'Barang',
    交易异常: 'Kelainan transaksi',
    看不清换一张: 'Tidak bisa melihat dengan jelas, ganti ke yang lain',
    客: 'tamu',
    客服: 'pelayanan pelanggan',
    客服电话: 'hotline konsumen',
    客服邮箱: 'Email Layanan Pelanggan',
    客户: 'klien',
    客户服务: 'pelayanan pelanggan',
    客户取消售后: 'Pelanggan membatalkan layanan purna jual',
    客户已付款等待店铺发货: 'Pelanggan telah membayar dan menunggu pengiriman dari toko',
    历史搜索: 'Pencarian sejarah',
    立即登录: 'segera masuk',
    立即购买: 'Beli sekarang',
    立即留言: 'Tinggalkan pesan sekarang',
    立即提交: 'Kirim sekarang',
    立即注册: 'Daftar sekarang',
    联系电话: 'nomor kontak',
    联系方式: 'Kontak informasi',
    联系客服: 'Hubungi Layanan Pelanggan',
    联系人: 'Kontak person',
    两次输入密码不一致: 'Kata sandi yang dimasukkan dua kali tidak konsisten!',
    留言成功: 'Pesan berhasil',
    留言管理: 'Pesan berhasil',
    留言回复: 'meninggalkan umpan balik',
    留言回复长度: 'Panjang balasan pesan: ',
    留言回复长度XX字符: 'Panjang balasan pesan XX karakter: ',
    留言内容: 'Isi pesan',
    留言详情: 'Detail pesan',
    没有更多了: 'tidak lagi',
    每张图片大小不超过3M: 'Ukuran setiap gambar tidak melebihi 3M',
    密码: 'kata sandi',
    密码登录: 'Masuk kata sandi',
    密码最少8位最多16位: 'Kata sandi minimal harus 8 karakter dan maksimal 16 karakter',
    秒: 'Kedua:',
    秒后重新发送: 'Kirim ulang setelah beberapa detik',
    名: 'nama',
    默认地址: 'alamat bawaan',
    男: 'pria',
    昵称: 'nama panggilan',
    昵称不能为空: 'Nama panggilan harus diisi',
    您当前是线下支付请耐心等待店铺确认收款若x后店铺未确认收款订单将自动取消: 'Saat ini Anda membayar secara offline. Harap sabar menunggu toko mengonfirmasi pembayaran. Jika toko tidak mengonfirmasi pembayaran setelah <span style="color: red;">{num}</span>, pesanan akan otomatis dibatalkan!',
    您的购物车还没有商品哟: 'Belum ada item di keranjang belanja Anda',
    您的收货地址为空点此添加收货地址: 'Alamat pengiriman Anda kosong. Klik di sini untuk menambahkan alamat pengiriman',
    您可以参考如下线下付款方式: 'Alamat pengiriman Anda kosong. Klik di sini untuk menambahkan alamat pengiriman',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料: 'Alamat pengiriman Anda kosong. Klik di sini untuk menambahkan alamat pengiriman',
    您未支付请重新支付: 'Anda belum membayar, silakan bayar lagi',
    女: 'perempuan',
    评论成功: 'Komentar berhasil',
    切换语言和货币: 'Ganti bahasa dan mata uang',
    请勾选删除商品: 'Harap centang untuk menghapus produk',
    请勾选商品: 'Silakan periksa produknya',
    请勾选信息: 'Silakan periksa informasinya',
    请上传图片: 'Silakan unggah gambar',
    请输入短信验证码: 'Silakan masukkan kode verifikasi SMS',
    请输入反馈内容: 'Silakan masukkan konten umpan balik',
    请输入留言内容: 'Silakan masukkan isi pesan',
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名: 'Silakan masukkan isi pesan Jika Anda menggunakan BCEL untuk membayar, silakan masukkan nomor kartu bank dan nama rekening yang digunakan untuk menerima pengembalian dana di sini.',
    请输入密码: 'Silakan masukkan kata sandi',
    请输入内容: 'Silakan masukkan konten',
    请输入手机号: 'Silakan masukkan nomor telepon',
    请输入搜索关键词: 'Masukkan kata kunci pencarian',
    请输入下方图形验证码: 'Silakan masukkan kode verifikasi grafis di bawah ini',
    请输入详细地址信息如道路门牌号小区楼层号等: 'Silakan masukkan informasi alamat rinci seperti jalan, nomor rumah, nomor lantai komunitas, dll.',
    请输入新密码: 'Silakan masukkan kata sandi baru',
    请输入验证码: 'silakan masukkan kode verifikasi',
    请输入用户名: 'silakan masukkan nama pengguna',
    请输入邮箱: 'silakan masukkan nama pengguna',
    请输入邮箱手机号: 'silakan masukkan nama pengguna',
    请输入原密码: 'Silakan masukkan kata sandi asli',
    请输入正确的密码格式必须包含两种字符: 'Silakan masukkan format kata sandi yang benar. Harus berisi dua karakter.',
    请输入正确的邮箱: 'silakan masukkan email valid Anda',
    请填写联系电话: 'Silakan isi nomor kontak',
    请填写联系人: 'Silakan isi contact personnya',
    请填写留言内容: 'Silakan isi isi pesan',
    请填写名: 'Silakan isi namanya',
    请填写收货地址: 'Silakan isi alamat pengiriman',
    请填写完整必填项: 'Silakan isi semua bidang yang wajib diisi',
    请填写详细地址: 'Silakan isi alamat detailnya',
    请填写姓: 'Silakan isi nama belakang',
    请填写邮政编码: 'Silakan isi kode pos',
    请选择: 'tolong pilih',
    请选择城市: 'tolong pilih',
    请选择付款方式: 'Silakan pilih metode pembayaran',
    请选择规格: 'Silakan pilih spesifikasi',
    请选择国家: 'Silakan pilih negara',
    请选择删除项需已读才能删除: 'Silakan pilih item yang ingin Anda hapus, Anda harus sudah membacanya sebelum dapat menghapusnya.',
    请在x内完成支付否则订单会被自动取消: 'Harap selesaikan pembayaran dalam waktu <span style="color: red;">{num}</span> jika tidak pesanan akan dibatalkan secara otomatis',
    请在下次使用邮箱地址进行登录: 'Silakan gunakan alamat email Anda untuk login lain kali',
    取消: 'Membatalkan',
    取消成功: 'Batalkan kesuksesan',
    取消订单: 'Batalkan pesanan',
    取消订单成功: 'Pesanan berhasil dibatalkan',
    取消时间: 'Waktu pembatalan',
    取消售后: 'Waktu pembatalan',
    去付款: 'Pergi dan bayar',
    去支付: 'membayar',
    全部: 'semua',
    全部分类: 'semua Kategori',
    全部消息: 'Semua berita',
    全选: 'Pilih Semua',
    确定: 'Tentu',
    确定放弃支付吗: 'Apakah Anda yakin ingin membatalkan pembayaran?',
    确定删除选中的商品吗: 'Apakah Anda yakin ingin menghapus produk yang dipilih?',
    确定提交: 'Konfirmasi Pengiriman',
    确定要清空历史记录吗: 'Apakah Anda yakin ingin menghapus riwayat?',
    确定要取消支付这笔订单吗: 'Apakah Anda yakin ingin menghapus riwayat?',
    确定已输入接收退款的银行卡号和户名: 'Pastikan Anda telah memasukkan nomor kartu bank dan nama rekening untuk menerima pengembalian dana',
    确定支付: 'Konfirmasi pembayaran',
    确认订单: 'Konfirmasi pemesanan',
    确认密码: 'konfirmasi sandi',
    确认收货: 'konfirmasi penerimaan barang',
    确认收货成功: 'Konfirmasi penerimaan barang berhasil',
    确认已付款: 'Konfirmasi pembayaran',
    如果您当前的手机号已无法使用请联系客服: 'Jika nomor ponsel Anda saat ini tidak tersedia lagi, silakan hubungi layanan pelanggan',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持jpg和png格式文件图片不大于3M: 'Jika Anda telah membayar sesuai dengan metode pembayaran di atas, silakan unggah voucher yang relevan, seperti tangkapan layar transfer, dll., file format jpg dan png didukung, dan gambar tidak boleh lebih besar dari 3M:',
    扫码或者点击去支付: 'Pindai kode atau klik untuk membayar“',
    删除: 'menghapus',
    删除成功: 'menghapus',
    删除订单: 'Hapus pesanan',
    删除订单成功: 'Pesanan berhasil dihapus',
    删除失效商品: 'Hapus produk kadaluwarsa',
    商品: 'komoditas',
    商品单价: 'Harga komoditas',
    商品分类: 'Kategori',
    商品合计: 'jumlah produk',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询: 'Alamat pengembalian produk akan diberitahukan melalui pesan teks atau ditanyakan dalam catatan aplikasi setelah disetujui.',
    商品详情: 'Rincian Produk',
    商品总额: 'total volume barang dagangan',
    商品总价: 'Total harga produk',
    上传成功: 'Unggahan berhasil',
    上传凭证: 'unggah sertifikat',
    上传头像图片大小不能超过2MB: 'Ukuran gambar avatar yang diunggah tidak boleh melebihi 2MB!',
    上传头像图片只能是JPG格式: 'Upload gambar avatar hanya bisa dalam format JPG!',
    上传中: 'mengunggah',
    设为默认: 'ditetapkan sebagai default',
    设为默认收货地址: 'Tetapkan sebagai alamat pengiriman default',
    申请时间: 'waktu aplikasi',
    申请信息: 'informasi aplikasi',
    剩余X件: '{num} item tersisa',
    失效宝贝: 'bayi tidak valid',
    时: 'jam',
    实付金额: 'Jumlah yang sebenarnya dibayarkan',
    实付款: 'pembayaran nyata',
    是否删除地址: 'Apakah akan menghapus alamatnya',
    是否选择该地址: 'Apakah akan memilih alamat ini',
    收货地址: 'Alamat pengiriman',
    收货人: 'Penerima',
    手机号: 'Nomor telepon',
    手机号码: 'nomor telepon',
    手机验证: 'Verifikasi telepon',
    手机验证码: 'Kode verifikasi ponsel',
    首页: 'halaman Depan',
    售后单号: 'Nomor pesanan purna jual',
    售后方式: 'metode purna jual,',
    售后服务: 'Layanan purna jual',
    售后商品: 'Produk purna jual',
    售后申请: 'Aplikasi purna jual',
    售后申请成功: 'Aplikasi purna jual berhasil',
    售后申请提交成功: 'Aplikasi purna jual berhasil dikirimkan',
    售后完成: 'Penyelesaian purna jual',
    售后详情: 'Detail purna jual',
    售后信息: 'Informasi purna jual',
    售后专员可能与您电话沟通请保持手机畅通: 'Spesialis purna jual dapat berkomunikasi dengan Anda melalui telepon. Harap tetap membuka ponsel Anda.',
    搜索: 'mencari',
    提交: 'kirim',
    提交订单: 'Masukan pesanan',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通: 'Setelah mengirimkan pesanan layanan, spesialis purna jual dapat berkomunikasi dengan Anda melalui telepon. Harap tetap membuka ponsel Anda.',
    提交申请: 'mengajukan permohonan',
    提示: 'petunjuk',
    天: 'langit',
    添加超时: 'tambahkan batas waktu',
    添加成功: 'Berhasil ditambahkan',
    添加失败库存不足: 'Gagal menambahkan karena inventaris tidak mencukupi',
    添加新地址: 'Tambahkan alamat baru',
    条回复: 'Balasan',
    通过BCEL银行完成支付后务必返回当前页面确认支付是否成功: 'Setelah menyelesaikan pembayaran melalui Bank BCEL, pastikan untuk kembali ke halaman saat ini untuk memastikan apakah pembayaran berhasil.',
    头像: 'avatar',
    图片上传成功: 'Gambar berhasil diunggah',
    图片上传失败请重试: 'Gagal mengunggah gambar, coba lagi',
    退出登录: 'keluar',
    退货处理成功后退款金额将原路返回到您的支持账户中: 'Setelah pengembalian berhasil diproses, jumlah pengembalian dana akan dikembalikan ke akun dukungan Anda melalui jalur asli.',
    退货金额: 'Jumlah pengembalian',
    退货类型: 'Jenis pengembalian',
    退货数量: 'Kuantitas pengembalian',
    退货原因: 'alasan untuk kembali',
    退款成功: 'Pengembalian dana berhasil',
    退款金额: 'Jumlah pengembalian uang',
    退款失败: 'Pengembalian dana gagal',
    退款说明: 'Instruksi pengembalian dana',
    退款完成: 'Pengembalian dana selesai',
    退款原因: 'alasan untuk kembali',
    退款中: 'Pengembalian dana',
    完成: 'Selesai',
    忘记密码: 'lupa kata sandinya',
    微信: 'Wechat wechat',
    为了帮助您更好的解决问题请上传图片: 'Untuk membantu Anda memecahkan masalah dengan lebih baik, harap unggah gambar',
    为确认身份我们需验证您的安全手机: ' Untuk mengonfirmasi identitas Anda, kami perlu memverifikasi ponsel aman Anda',
    未登录: 'Belum login',
    未读消息: 'pesan yang belum dibaca',
    未发货: 'Tidak dikirim',
    未添加收货地址: 'Tidak ada alamat pengiriman yang ditambahkan',
    未选择地址或没有地址: 'Tidak ada alamat yang dipilih atau tidak ada alamat',
    温馨提示: 'Tip yang baik',
    文件过大: 'File terlalu besar',
    我的: 'saya',
    我的订单: 'Pesananku',
    我是新用户: 'Saya adalah pengguna baru',
    我要留言: 'Saya ingin meninggalkan pesan',
    我要退货退款: 'Saya ingin mengembalikan produk untuk pengembalian dana',
    我要退款: 'Saya ingin pengembalian dana',
    物流单号: 'nomor pengiriman',
    下单结算: 'Penyelesaian pesanan',
    下一步: 'Langkah berikutnya',
    线下支付: 'Pembayaran luring',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货: 'Pembayaran offline tidak mendukung layanan purna jual online. Jika memerlukan layanan purna jual silahkan menghubungi toko. Jika sudah membayar harap bersabar dan menunggu toko mengkonfirmasi penerimaan dan pengiriman.',
    详细地址: 'Alamat',
    消息通知: 'pemberitahuan',
    消息详情: 'Detail pesan',
    销量高低: 'Volume penjualan',
    销量最低: 'volume penjualan terendah',
    销量最高: 'volume penjualan tertinggi',
    新密码: 'kata sandi baru',
    新密码不能和旧密码相同: 'Password baru tidak boleh sama dengan password lama',
    新增地址: 'Tambahkan alamat',
    性别: 'jenis kelamin',
    姓: 'nama belakang',
    修改: 'Merevisi',
    修改成功: 'Berhasil dimodifikasi',
    修改密码: 'ganti kata sandi',
    修改邮箱: 'Ubah email',
    需勾选协议: 'Perlu memeriksa perjanjian',
    选择: 'memilih',
    选择成功: 'Pilih kesuksesan',
    选择新地址: 'Pilih alamat baru',
    选中地址: 'Pilih alamat',
    验证: 'memeriksa',
    验证码: 'Kode verifikasi',
    验证码登录: 'Login kode verifikasi',
    已读消息: 'membaca pesan',
    已发货: 'Dikirim',
    已关闭: 'tertutup',
    已取消: 'Dibatalkan',
    已取消删除: 'Batalkan penghapusan',
    已完成: 'lengkap',
    已选择: 'terpilih ',
    已阅读并同意以下协议: 'Telah membaca dan menyetujui perjanjian berikut',
    隐私政策: 'Kebijakan pribadi',
    应付总额: 'Total jumlah yang harus dibayar',
    用户服务协议: 'Perjanjian Layanan Pengguna',
    用户服务协议及法律声明: 'Perjanjian Layanan Pengguna dan Pernyataan Hukum',
    优惠金额: 'Harga setelah diskon',
    优惠卷抵扣: 'Diskon kupon',
    邮箱: 'Surat',
    邮箱手机号: 'Nomor telepon email',
    邮箱修改: 'Modifikasi email',
    邮箱验证: 'Verifikasi email',
    邮政编码: 'kode Pos',
    语言: 'bahasa',
    原密码: 'password lama',
    运费: 'kargo',
    暂无订单信息: 'Belum ada informasi pesanan~',
    暂无商品: 'Belum ada produk',
    暂无售后信息: 'Belum ada informasi purna jual',
    长度在1到8个字符: 'Panjangnya 1 hingga 8 karakter',
    支付宝: 'Alipay',
    支付成功: 'pembayaran berhasil',
    支付订单: 'Bayar pesanan',
    支付方式: 'Cara Pembayaran',
    支付金额: 'Jumlah pembayaran',
    支付凭证: 'Voucher pembayaran',
    支付失败: 'Pembayaran gagal',
    重新发送验证码: 'Kirim Ulang Kode',
    重新申请: 'Melamar lagi',
    注册: 'daftar',
    注册成功: 'registrasi berhasil',
    综合排序: 'Penyortiran yang komprehensif',
    总价: 'Total Harga: ',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件: 'Hingga 5 gambar dapat diunggah, setiap ukuran gambar tidak melebihi 5M, mendukung file format bmp, gif, jpg, png, jpeg',
    最多上传五张: 'Hingga lima unggahan',
    推荐商品: 'Produk yang direkomendasikan',
    共x件商品已选择x件: 'Total {num} item, {num2} dipilih',
    共x件商品: 'Jumlah {num} item',
    绑定: 'mengikat',
    绑定邮箱: 'Ikat email',
    分享店铺: 'Bagikan toko',
    分享至: 'bagikan ke',
    海报: 'poster',
    链接: 'Menghubungkan',
    x件: '{num}bagian-bagian',
    请再次输入新密码: 'Silakan masukkan kata sandi baru lagi',
    至: 'ke',
    币种: 'Komentar',
    变动理由: 'alasan untuk berubah',
    不记得: 'tidak ingat',
    充值: 'isi ulang',
    打款凭证: 'Voucher pembayaran',
    打款说明: 'Instruksi pembayaran',
    打款信息: 'Informasi Pembayaran',
    打款状态: 'Status pembayaran',
    待打款: 'Menunggu Pembayaran',
    待审核: 'Tinjauan tertunda',
    到账方式: 'Cara Pembayaran',
    到账银行卡号: 'Nomor kartu bank setoran',
    冻结中: 'Pembekuan',
    国家: 'bangsa',
    获取验证码: 'dapatkan kode verifikasi',
    记得: 'Ingat',
    记录时间: 'Rekor waktu',
    拒绝打款: 'Menolak untuk membayar',
    开户人: 'Pemilik akun',
    开户支行: 'Cabang pembukaan rekening',
    可提现: 'Dapat ditarik',
    可提现金额: 'Jumlah uang tunai yang tersedia',
    立即提现: 'Tarik segera',
    流水号: 'nomor seri',
    没有适合此币种的银行卡请先添加: 'Tidak ada kartu bank yang cocok untuk mata uang ini, harap tambahkan terlebih dahulu',
    没有收款码请先添加: 'Tidak ada kode pembayaran, silakan tambahkan terlebih dahulu',
    每天最多提现x次单次最高提现金额x: 'Jumlah maksimum penarikan per hari adalah *, dan jumlah maksimum penarikan per waktu*',
    您是否记得账号当前使用的支付密码: 'Apakah Anda ingat kata sandi pembayaran yang saat ini digunakan oleh akun Anda?',
    您未设置支付密码请先设置: 'Anda belum menetapkan kata sandi pembayaran, silakan atur terlebih dahulu',
    钱包余额: 'saldo dompet',
    请输入开户人: 'Silakan masukkan pemegang akun',
    请输入开户支行: 'Silakan masuk ke cabang tempat pembukaan rekening',
    请输入收款名称: 'Silakan masukkan nama pembayaran',
    请输入提现金额: 'Silakan masukkan jumlah penarikan',
    请输入新支付密码6位数字: 'Silakan masukkan kata sandi pembayaran 6 digit yang baru',
    请输入新支付密码: 'Silakan masukkan kata sandi pembayaran baru',
    请输入银行卡号: 'Silakan masukkan nomor kartu bank',
    请输入原支付密码: 'Silakan masukkan kata sandi pembayaran asli',
    请输入支付密码6位数字: 'Silakan masukkan kata sandi pembayaran, 6 digit',
    请输入支付密码: 'Silakan masukkan kata sandi pembayaran',

    请选择币种: 'Silakan pilih mata uang',
    请选择大洲: 'Silakan pilih benua',

    请选择结束时间: 'Silakan pilih waktu berakhir:',
    请选择开始时间: 'Silakan pilih waktu mulai',
    请选择收款码类型: 'Silakan pilih jenis kode pembayaran',
    请选择银行: 'Silakan pilih bank',
    请选择银行名称: 'Silakan pilih nama bank',
    请再次输入新支付密码: 'Silakan masukkan kembali kata sandi pembayaran baru',
    请再次输入支付密码: 'Silakan masukkan kembali kata sandi pembayaran baru',
    去设置: 'Pergi ke pengaturan',
    全部提现: 'Semua penarikan',
    确定删除收款码吗: 'Konfirmasi untuk menghapus kode pembayaran?',
    确定删除银行卡吗: 'Apakah Anda yakin ingin menghapus kartu bank?',
    确定提现: 'Konfirmasikan penarikan',
    确认打款: 'Konfirmasi pembayaran:',
    确认新密码: 'Konfirmasikan kata sandi baru',
    确认支付密码: 'Konfirmasikan kata sandi pembayaran',
    上传的收款二维码需要清晰无遮挡: 'Kode QR pembayaran yang diunggah harus jelas dan tidak terhalang',
    审核备注: 'Tinjau komentar',
    审核不通过: 'Audit tidak lulus',
    审核结果: 'Hasil audit',
    审核通过: 'ujian lulus',
    审核信息: 'Tinjau informasi',
    时间: 'waktu',
    实际到账金额: 'Jumlah aktual yang diterima:',
    收款方式: 'Cara Pembayaran',
    收款管理: 'Manajemen koleksi',
    收款码: 'Kode pembayaran',
    收款码管理: 'Manajemen kode pembayaran',
    收款码类型: 'Jenis kode pembayaran',
    收款名称: 'Nama penerima pembayaran',
    收款账号: 'Nomor rekening pembayaran',
    手续费: 'Biaya penanganan:',
    提现: 'menarik',
    提现币种: 'Mata uang penarikan',
    提现成功: 'Penarikan berhasil',
    提现记录: 'Catatan penarikan',
    提现金额: 'Jumlah penarikan',
    提现失败: 'Penarikan gagal',
    提现时间: 'Waktu penarikan',
    提现手续费: 'Biaya penarikan',
    提现详情: 'Detail penarikan',
    提现中: 'Penarikan',
    提现状态: 'Status penarikan',
    添加收款码: 'Tambahkan kode pembayaran',
    添加银行卡: 'Tambahkan kartu bank',
    退款: 'Pengembalian dana',
    忘记支付密码: 'Lupa kata sandi pembayaran Anda?',
    尾号: 'nomor ekor',
    我的钱包: 'dompet saya',
    我的余额: 'saldo saya',
    详情: 'Detail',
    消费: 'Konsumsi',
    新支付密码: 'Kata sandi pembayaran baru',
    修改支付密码: 'Ubah kata sandi pembayaran',
    选择收款方式: 'Pilih metode pembayaran',
    银行卡: 'kartu bank',
    银行卡管理: 'Manajemen kartu bank',
    银行卡号: 'Nomor kartu bank',
    银行名称: 'nama Bank',
    余额记录: 'Catatan saldo',
    余额数据: 'data saldo',
    原支付密码: 'Kata sandi pembayaran asli',
    暂无数据: 'Tidak ada data',
    支持币种: 'Mata uang yang didukung',
    支付密码: 'Kata sandi pembayaran',
    只可上传一张图片图片大小不超过5M支持xxx格式文件: 'Hanya satu gambar yang dapat diunggah, ukuran gambar tidak melebihi 5M, mendukung file berformat jpg, png, jpeg',
    账户余额: 'Saldo rekening',
    钱包服务: 'Layanan dompet',
    每天最多提现x次单次最高提现金额xx最低提现金额xx: 'Jumlah maksimum penarikan per hari adalah {num} kali, jumlah maksimum penarikan per waktu adalah {icon}{money}, dan jumlah minimum penarikan adalah {icon}{money2}',
    确认: 'mengonfirmasi',
    输入金额超过可提现余额: 'Jumlah masukan melebihi saldo yang dapat ditarik',
    日期选择: 'pemilihan tanggal',
    备注: 'Komentar',
    当前没有余额记录: 'Saat ini tidak ada catatan saldo!',
    当前没有提现记录: 'Saat ini tidak ada catatan penarikan!',
    还没有绑定银行卡: 'Belum ada kartu bank yang diikat',
    还没有添加收款码: 'Belum ada kode pembayaran yang ditambahkan!',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡: 'Tip: Hanya satu gambar yang dapat diunggah, ukuran gambar tidak boleh melebihi 5M, dan file berformat jpg, png, dan jpeg didukung. Kode QR pembayaran yang diunggah harus jelas dan tidak terhalang.',
    请上传收款码: 'Silakan unggah kode pembayaran',
    请输入收款码名称: 'Silakan masukkan nama kode pembayaran',
    暂无银行卡: 'Belum ada kartu bank',
    余额: 'keseimbangan',
    重置: 'mengatur ulang',
    暂无收款码: 'Belum ada kode pembayaran',
    保障支付安全: 'Pastikan keamanan pembayaran',
    打款成功: 'Pembayaran berhasil',
    请选择收款方式: 'Silakan pilih metode pembayaran',
    收款类型: 'Tipe pembayaran',
    编辑收款码: 'Edit kode pembayaran',
    编辑银行卡: 'Sunting kartu bank',
    收款码名称: 'Nama kode pembayaran',
    订单ID: 'jumlah order',
    推荐店铺给你: 'Merekomendasikan toko kepada Anda',
    如果您已支付成功请点击确认支付按钮: 'Jika pembayaran Anda telah berhasil, silakan klik tombol "Konfirmasi Pembayaran"',
    确认支付: 'Konfirmasi Pembayaran',
    支付遇到问题: 'Menghadapi masalah',
    对不起您访问的店铺可能已被删除名称被更改或不存在: 'Maaf, toko yang Anda kunjungi mungkin telah dihapus, namanya diubah, atau mungkin tidak ada...',
    卢比支付: 'pembayaran IDR',
    比索支付: 'pembayaran PHP',
    泰铢支付: 'pembayaran THB',
    越南盾支付: 'pembayaran VND',
    银行卡编码: 'Kode Bank',
    请输入旧密码: 'Silakan masukkan kata sandi lama',
    必须包含两种字符: 'Harus berisi dua karakter',
    长度在8到16个字符: 'Panjang antara 8 dan 16 karakter',
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0": "{currencyUnit}{money}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1": "prioritas utama{num}kg{currencyUnit}{money}，Perbarui berat badan{num2}kg{currencyUnit}{money2}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2": "barang pertama {num3}bagian-bagian{currencyUnit}{money3}，kelanjutan{num4}bagian-bagian{currencyUnit}{money4}",
    暂无配送地区: 'Belum ada wilayah pengiriman',
    该商品在当前地区暂不支持配送: 'Produk ini saat ini tidak mendukung pengiriman di wilayah saat ini',
    当前商品仅支持以下地区发货如有疑问请联系店铺:
        'Produk saat ini hanya mendukung pengiriman ke area berikut. Jika Anda memiliki pertanyaan, silakan hubungi toko',
    运费计价说明: 'Petunjuk penetapan harga pengiriman',
    发货地: 'tempat pengiriman',
    配送至: 'Kirim ke',
    打款中: 'Pembayaran sedang berlangsung',
    未添加地址请先添加: 'Tidak ada alamat yang ditambahkan, harap tambahkan terlebih dahulu',
    请输入大于1的正整数: 'Silakan masukkan bilangan bulat positif yang lebih besar dari 1',
    设置密码: 'mengatur kata sandi',
    登录密码: 'kata sandi masuk',
    请再次输入密码: 'Silakan masukkan kata sandi Anda lagi',
    当前仅支持xxx的金额: 'Saat ini hanya mendukung jumlah dari {currency} {min}-{currency} {max}',
    查看运费: 'Lihat biaya pengiriman',
    运费方式: 'Metode pengiriman',
    价格: 'harga',
    请选择配送方式: 'Silahkan pilih metode pengiriman',
    配送方式: 'Metode Pengiriman',
    配送费说明: 'Deskripsi biaya pengiriman',
    我明白了: 'Jadi begitu',
    若多个商品选用同一个运费模板则运费是按同一个运费方式的规则进行计算: 'Jika beberapa produk menggunakan template pengiriman yang sama, biaya pengiriman akan dihitung berdasarkan aturan metode pengiriman yang sama.',
    若多个商品不是同一个运费模板则运费是多个模板的价格之和: 'Jika beberapa produk tidak memiliki template biaya pengiriman yang sama, biaya pengiriman adalah jumlah dari harga beberapa template.',
    快捷登录登录即表示同意协议: 'Dengan masuk, Anda menyetujui perjanjian tersebut',
    促销广告: 'iklan promosi',
    最近新品: 'Produk baru',
    更多: 'Lagi',
    设置成功: 'Penyiapan berhasil',
    更多商品:'Lebih banyak produk',

    //     1.6
    我的优惠券:'Kupon diskon saya',
    历史记录:'Catatan sejarah',
    已使用:'Digunakan',
    已过期:'kedaluwarsa',
    优惠券:'kupon',
    领取:'Menerima',
    已领取:'Diterima',
    有效期:'Masa berlaku：',
    活动优惠详情:'Detail diskon acara',
    可用商品:'Item yang tersedia',
    单独购买:'Harga asli',
    发起拼团:'Harga grup',
    拼团价:'Harga grup',
    可参与的拼团:'Pembelian grup yang tersedia',
    '再邀X人即可拼单成功':'Bagikan kepada {num}orang lagi agar pesanan berhasil>',
    拼单详情:'Detail pembelian grup',
    待分享:'menunggu untuk dibagikan',
    '若拼团失败，货款将原路退回':'Jika pembelian grup gagal, pembayaran akan dikembalikan.',
    促销价:'Harga penjualan',
    距离结束时间:'End in',
    满XX减XX:"Beli{currencyUnit}{priceStart}dapat potongan{currencyUnit}{reducePrice}{couponType==2?'%':''} harga",
    满XX减X:'Beli{currencyUnit}{priceStart}dapat potongan{reducePrice}% harga',
    分享好友X人成团立省X:'Pembelian grup untuk<span style="color: #8D4BBB;">{num}</span>orang, hemat<span style="color: #8D4BBB;">{currencyUnit}{groupPrice}</span>',
    分享好友:'Bagikan dengan teman',
    满x可用:'Tersedia untuk pesanan di atas {currencyUnit}{priceStart}',
    这些人正在拼立即参与:'Pembelian grup sedang berlangsung, bergabunglah sekarang',
    限领x张:'{receiveCount} kupon per batas pengguna',
    满XX送赠品:'Hadiah gratis untuk pesanan lebih dari {currencyUnit}{priceStart}',
    去使用:'menggunakan',
    销量:'Penjualan',
    赠品:'Hadiah Gratis',
    限时优惠:'Penawaran terbatas',
    距结束:'End in',
    拼团好物:'Pembelian Kelompok',
    当前优惠券可以购买以下商品:'Kupon saat ini dapat membeli produk berikut:',
    以下商品:'Produk berikut',
    满XX减XX可用:"Beli{currencyUnit}{priceStart}dapat potongan{currencyUnit}{reducePrice}harga",
    满XX减X可用:'Beli{currencyUnit}{priceStart}dapat potongan{reducePrice}% harga',
    再买x可用:'Masih perlu membeli {currencyUnit}{difPrice}',
    合计:'Total',
    优惠:'Diskon',
    去购物车:'kereta Belanja',
    拼团:'Pembelian Kelompok',
    满XX可用:'Tersedia untuk pesanan di atas {currencyUnit}{priceStart}',
    满减满赠:'Diskon penuh/hadiah penuh',
    成团发货:'Tunggu pengiriman',
    还差xx人成团:'Masih membutuhkan <span style="color: #FF4545;">{num}</span> orang untuk berhasil memesan',
    拼团结束:'Pembelian kelompok berakhir',
    拼团成功:'Pembelian grup berhasil',
    有成员未付款请等待付款成团:'Ada yang belum bayar, mohon tunggu pembayarannya',
    切换其他优惠:'Pilih tawaran lain',
    满减:'Diskon penuh',
    满赠:'Hadiah penuh',
    凑单:'Buat pesanan bersama',
    超过最大购买数:'Melebihi jumlah pembelian maksimum',
    商品限购x件:'Batasi {num} buah',
    参与拼团:'Bergabung',
    结束:'结束',
    我正在参与的拼团:'Berpartisipasi dalam pembelian kelompok',
    您还没有优惠券:'Anda tidak memiliki kupon',
    优惠券历史记录:'Catatan sejarah',
    拼单失败:'Pembelian grup gagal',
    免费:'bebas',
    已赠完:'stok Habis',
    此拼团已满请选择其他拼团:'Pembelian grup ini sudah penuh, silakan pilih pembelian grup lainnya',
    以下商品正在限时优惠当中:'Penawaran waktu terbatas untuk produk berikut',
    以下商品正在限时拼团当中:'Pembelian grup dalam waktu terbatas untuk produk-produk berikut',
    再买X可减X:'Beli lagi {currencyUnit}{difPrice} dan dapatkan diskon {currencyUnit}{reducePrice}',
    已领完:"Digunakan",
    活动未开始:'Acara belum dimulai',

    已满X可减X:'Dapatkan diskon {currencyUnit}{reducePrice} bila Anda membelanjakan {currencyUnit}{difPrice} atau lebih ',
    已满X可减XX:'Dapatkan diskon {reducePrice}% bila Anda membelanjakan {currencyUnit}{difPrice} atau lebih',

    再买X可减XX:'Beli lagi {currencyUnit}{difPrice} dan dapatkan diskon {reducePrice}%',
    再买X送赠品:'Beli {currencyUnit}{difPrice} lagi dan dapatkan hadiah gratis',
    已满X送赠品:'Hadiah telah diberikan',
    缺少库存: 'Kurangnya stok',
    限X件:'Batas pembelian {num} buah',
    房间号已失效请联系工作人员:'Nomor kamar sudah habis masa berlakunya, harap hubungi staf!',


    退货积分:'Poin pengembalian',
    积分:'integral',
    用户注册:'Pendaftaran pengguna',
    手动增加:'Tingkatkan secara manual',
    积分兑换退款:'Pengembalian dana poin',
    下单购物:'Lakukan pemesanan untuk berbelanja',
    过期积分:'Poin kadaluwarsa',
    兑换商品:'Tebus barang',
    手动减少:'Pengurangan manual',
    订单退款:'Pengembalian dana pesanan',
    积分明细:'Detail poin',
    已兑:'Sudah ditebus',
    积分商城:'Poin Mall',
    规则:'aturan',
    可用积分:'Poin Tersedia',
    冻结积分:'titik beku',
    我的兑换:'Pertukaran saya',
    售后:'Setelah terjual',
    分销中心:'Pusat distribusi',
    立即兑换:'Tukarkan sekarang',
    已兑完:'Sudah ditebus',
    "积分不足，无法兑换":'Poin yang tidak mencukupi tidak dapat ditukarkan',
    兑换数量:'Kuantitas pertukaran',
'限兑*件':'Penebusan terbatas {num} item',
    积分抵扣:'Pengurangan poin',
'共*分':'Jumlah {num} poin',
    全部订单:'Semua perintah',

    兑换须知:'Instruksi penebusan',
    已达兑换上限:'Batas penukaran telah tercapai',
    我的积分:'Skor saya',
    积分规则:'Aturan poin',
    兑换时间:'Waktu penebusan',
    我的佣金:'komisi saya',
    佣金数据:'Data komisi',
    分销申请协议:'Perjanjian Aplikasi Distribusi',
    申请成为分销员:'Lamar menjadi distributor',
    "已申请，请等待审核":'Sudah diterapkan, harap tunggu peninjauannya',
    "审核拒绝，继续申请":'Ditolak melalui peninjauan, lanjutkan melamar',
    可提佣金:'Komisi penarikan',
    冻结佣金:'Membekukan komisi',
    分销订单:'Urutan distribusi',
    佣金明细:'Detail komisi',
    我的下级:'Bawahan saya',
    推广商品:'Mempromosikan produk',
    推广店铺:'Promosikan toko',
    暂无记录:'Tidak ada catatan',
    一级佣金收入:'Pendapatan komisi tingkat pertama',
    一级佣金退款:'Pengembalian komisi tingkat pertama',
    二级佣金收入:'Pendapatan komisi sekunder',
    二级佣金退款:'Pengembalian komisi tingkat kedua',
    下单用户:'Pengguna penempatan pesanan',
    订单总价:'Harga total pesanan',
    分销利润层级:'Tingkat distribusi',
    结算状态:'status penyelesaian',
    佣金:'Komisi',
    一级:'Level 1',
    二级:'Level 2',
    已结算:'Mapan',
    请输入用户昵称或手机号:'Silakan masukkan nama panggilan pengguna atau nomor ponsel',
    二级团队:'Tim tingkat kedua',
    分享链接:'membagikan tautan',
    可推广至:'Dapat diperluas ke',
    分享最高可赚x:'Hasilkan hingga {icon}{num} dengan berbagi',
    分享推广:'Bagikan dan promosikan',
    用户昵称或手机号:'Nama panggilan pengguna atau nomor ponsel',
    商品名称:'nama Produk',
    最高可得:'Tertinggi yang tersedia',
    可获得积分:'poin yang diperoleh',
    总获佣金:'Total komisi yang diperoleh',

    佣金详情:'Detail komisi',
    已退款:'dikembalikan',
    请输入商品名称:'Silakan masukkan nama produk',
    请选择银行卡: 'Silakan pilih kartu bank',
    请选择收款码: 'Silakan pilih kode pembayaran',
    不能超过最小提现额度:'umlah penarikan minimum tidak dapat dilampaui!',



    货柜商品:'Barang kontainer',
    该浏览器不支持蓝牙:'Browser ini tidak mendukung Bluetooth, salin tautan ke Chrome, browser Internet Samsung untuk membukanya',
    iOS系统暂不支持售货机取货请使用Android系统扫码购物:'Silakan gunakan sistem Android untuk memindai kode QR untuk berbelanja. Untuk sistem iOS, silakan klik "Pergi ke beranda mal" untuk berbelanja, dan orang yang berdedikasi akan mengantarkannya ke rumah Anda.',
    去商城首页:'Kunjungi beranda mal',
    已售完:'Terjual habis',
    蓝牙未连接:'Bluetooth tidak terhubung',
    蓝牙已连接:'Bluetooth terhubung',
    重试:'Coba lagi',
    蓝牙未授权请打开蓝牙后重试:'Bluetooth tidak diotorisasi, harap aktifkan Bluetooth dan coba lagi!',
    蓝牙未连接请选择蓝牙并配对:'Bluetooth tidak terhubung, silakan pilih Bluetooth dan pasangkan!',
    连接:'menghubungkan',
    蓝牙已断开请重新连接:'Bluetooth telah terputus, harap sambungkan kembali',
    蓝牙连接中:'koneksi bluetooth...',
    购买成功请取出商品若柜门未打开请尝试再次打开:'Pembelian berhasil, silakan keluarkan produknya! Jika pintu lemari tidak terbuka, coba buka lagi!',
    再次打开:'Buka lagi',
    普通订单:'Pesanan biasa',
    售货机:'mesin penjual otomatis',
    售货机已无货无法支付:'Mesin penjual otomatis kehabisan stok dan pembayaran tidak dapat dilakukan.',
    订单来源:'Sumber pesanan',
    连接失败请重新连接:'Sambungan gagal, harap sambungkan kembali',
    售罄:'terjual habis',
    '房间号/桌号':'Nomor kamar/nomor meja',
    "柜门开启中···": "Pintu lemari terbuka...",
    "微信不支持蓝牙，请点击":'Browser WeChat tidak mendukung Bluetooth, silakan klik',
    "选择在Google或Samsung Internet浏览器中打开":'Pilih untuk membuka di browser Internet Google<img src="https://saasimage.bawanli.com/users/2024-11-01/485b6a31-fe3e-4b07-b42a-71f3e2462ce2.png" style="width: 30px;height: 30px;margin:0 4px;"> atau Samsung<img src="https://saasimage.bawanli.com/users/2024-11-01/805ed567-6a5b-47fc-a053-e1c2f50b67f6.png" style="width: 30px;height: 30px;margin:0 4px;">，<span style="color: red;">Jika Anda tidak memiliki browser di atas, silakan klik tautan di bawah untuk mengunduh: <a href="https://saasimage.bawanli.com/users/2024-11-01/Chrome.apk">【Chrome】</a><a href="https://saasimage.bawanli.com/users/2024-11-04/Samsung.apk">【Samsung Internet】</a></span>',
    "复制链接":'Salin tautan',
    "通过在线商城购买的订单，哇呗小店将配送上门，配送时间：06:00-23:00":'Pesanan yang dibeli melalui mall online akan diantar ke depan pintu Anda oleh Wabei Store. Waktu pengiriman: 06:00-23:00',
    "我已知晓":'Saya sudah tahu'
}
//印尼
